import { autoinject } from "aurelia-framework";
import { DefaultService } from "shared-service/default-service";
import { SearchService } from "shared-service/search-service";
import { SearchRequest } from "shared-models/search-request";
import { SearchResult } from "shared-models/search-result";
import { GetRecordService } from "shared-service/get-record-service";
import { GetRecordRequest } from "shared-models/get-record-request";
import { GetRecordsService } from "shared-service/get-records-service";
import { GetRecordsRequest } from "shared-models/get-records-request";
import { CreateRecordService } from "shared-service/create-record-service";
import { UpdateRecordService } from "shared-service/update-record-service";
import { CreateRecordRequest } from "shared-models/create-record-request";
import { UpdateRecordRequest } from "shared-models/update-record-request";
import { RecordReference } from "shared-models/record-reference";
import { ChangeOrder } from "change-orders/models/change-order";
import { ChangeOrderType, ChangeOrderRequest, ChangeOrderGiftMsgRequest, ChangeOrderShipInstructionsRequest, ChangeOrderShipDateRequest, ChangeOrderItemsRequest, ChangeOrderShipMethodRequest, ChangeOrderEmailRequest, ChangeOrderAddressRequest } from "change-orders/models/change-order-request";
import { Address, Order, convertResponseToOrder } from "orders/models/order";

@autoinject
export class ChangeOrderService extends DefaultService {
    private static readonly CHANGE_ORDER_RECORD_TYPE = "vf_change_order";
    private static readonly CHANGE_ORDER_TYPE_RECORD_TYPE = "vf_change_order_type";

    constructor(
        private searchService: SearchService,
        private getRecordService: GetRecordService,
        private getRecordsService: GetRecordsService,
        private updateRecordService: UpdateRecordService,
        private createRecordService: CreateRecordService) {
        super(ChangeOrderService.name);
    }

    async getChangeOrdersByOrder(orderId: number): Promise<ChangeOrder[]> {
        let getRecordsRequest = new GetRecordsRequest({
            recordType: ChangeOrderService.CHANGE_ORDER_RECORD_TYPE,
            fieldReference: "winery_order",
            fieldReferenceValue: orderId
        });
        let getRecordsResponse = await this.getRecordsService.getRecords(getRecordsRequest, this._convertResponseToChangeOrder, this);
        getRecordsResponse.records = getRecordsResponse.records.sort((changeOrder1, changeOrder2)=> changeOrder2.createDate.getTime() - changeOrder1.createDate.getTime());
        return getRecordsResponse.records;
    }

    async searchRecords(page: number, filter: string): Promise<SearchResult> {
        page = page || 1;
        filter = filter || "";
        let searchRequest = new SearchRequest({
            recordType: ChangeOrderService.CHANGE_ORDER_RECORD_TYPE,
            pageSize: 50,
            page: page,
            filter: filter
        });
        let searchResponse = await this.searchService.searchRecords(searchRequest, this._convertResponseToChangeOrder, this) as {recordType: string, totalRecords: number, pageSize: number, pageCount: number, filter?: string, records?: ChangeOrder[]};
        searchResponse.records = searchResponse.records.sort((changeOrder1, changeOrder2)=> changeOrder2.createDate.getTime() - changeOrder1.createDate.getTime());
        return searchResponse;
    }

    async getAllRecords(): Promise<ChangeOrder[]> {
        let getRecordsRequest = new GetRecordsRequest({
            recordType: ChangeOrderService.CHANGE_ORDER_RECORD_TYPE
        });
        let getRecordsResponse = await this.getRecordsService.getRecords(getRecordsRequest, this._convertResponseToChangeOrder, this);
        getRecordsResponse.records = getRecordsResponse.records.sort((changeOrder1, changeOrder2)=> changeOrder2.createDate.getTime() - changeOrder1.createDate.getTime());
        return getRecordsResponse.records;
    }

    async getRecord(changeOrderId: number): Promise<ChangeOrder> {
        let getRecordRequest = new GetRecordRequest({
            recordType: ChangeOrderService.CHANGE_ORDER_RECORD_TYPE,
            recordId: changeOrderId
        });
        let getRecordResponse = await this.getRecordService.getRecord(getRecordRequest, this._convertResponseToChangeOrder, this);
        return getRecordResponse.record;
    }

    async createRecord(changeOrderRecord: ChangeOrder): Promise<number> {      
        let createRecordRequest = new CreateRecordRequest({
            recordType: ChangeOrderService.CHANGE_ORDER_RECORD_TYPE,
            recordData: changeOrderRecord.convertToData()
        });
        return await this.createRecordService.createRecord(createRecordRequest);
    }

    async cancelChangeOrder(changeOrderRecord: ChangeOrder): Promise<void> {      
        let changeOrderData = changeOrderRecord.convertToData();
        changeOrderData["status"] = "Cancelled";
        let updateRecordRequest = new UpdateRecordRequest({
            recordId: changeOrderRecord.id,
            recordType: ChangeOrderService.CHANGE_ORDER_RECORD_TYPE,
            recordData: changeOrderData
        });
        await this.updateRecordService.updateRecord(updateRecordRequest);
    }

    async getChangeOrderTypes(): Promise<ChangeOrderType[]> {
        let getRecordsRequest = new GetRecordsRequest({
            recordType: ChangeOrderService.CHANGE_ORDER_TYPE_RECORD_TYPE
        });
        let getRecordsResponse = await this.getRecordsService.getRecords(getRecordsRequest, this._convertResponseToChangeOrderType, this);
        getRecordsResponse.records = getRecordsResponse.records.sort((type1, type2)=> (type1.name || "").toLowerCase().localeCompare((type2.name || "").toLowerCase()));
        return getRecordsResponse.records;       
    }

    _convertResponseToChangeOrder(response): ChangeOrder {
        let changeOrder = new ChangeOrder({
            id: response["id"],
            name: response["name"],
            createdBy: response["created_by"],
            lastUpdatedByUser: response["last_updated_by_user"],
            createDate: this._convertValueToDateTime(response["create_date"]),
            status: response["status"],
            wineryOrderNumber: response["winery_order_number"],
            order: response["winery_order"] ? convertResponseToOrder(response["winery_order"]) : null,
            vinfillmentNotes: response["notes_for_vinfillment"],
            deniedReasons: response["denied_reasons"],
            changeRequestsTypes: response["change_requests_types"],
            changeRequests: []
        });
        changeOrder.changeRequests = (response["change_requests"] || []).map((changeRequest)=> this._convertResponseToChangeOrderRequest(changeRequest));
        return changeOrder;
    }

    _convertResponseToChangeOrderRequest(response): ChangeOrderRequest {
        let changeOrderTypeData = response["change_order_type"];
        let changeOrderType = new ChangeOrderType({
            id: changeOrderTypeData["id"],
            name: changeOrderTypeData["name"],
            description: changeOrderTypeData["description"],
            doNotAllowOtherChanges: changeOrderTypeData["do_not_allow_other_changes"],
            statusesNotAllowed: changeOrderTypeData["statuses_not_allowed"]
        });
        if(changeOrderType._isChangeGiftMessage()){
            return this._convertToGiftMessageRequest(changeOrderType, response);
        } else if(changeOrderType._isChangeShipInstructions()){
            return this._convertToShipInstRequest(changeOrderType, response);
        } else if(changeOrderType._isChangeShipMethod()){
            return this._convertToShipMethodRequest(changeOrderType, response);
        } else if(changeOrderType._isChangeShipDate()){
            return this._convertToShipDateRequest(changeOrderType, response);
        } else if(changeOrderType._isChangeEmail()){
            return this._convertToEmailRequest(changeOrderType, response);
        } else if(changeOrderType._isChangeAddress()){
            return this._convertToAddressRequest(changeOrderType, response);
        } else if(changeOrderType._isChangeItems()){
            return this._convertToItemRequest(changeOrderType, response);
        } else{
            return new ChangeOrderRequest({
                id: response["id"],
                name: response["name"],
                changeOrderType: changeOrderType
            });
        }
    }

    _convertResponseToChangeOrderType(response): ChangeOrderType {
        return new ChangeOrderType({
            id: response["id"],
            name: response["name"],
            description: response["description"],
            statusesNotAllowed: response["statuses_not_allowed"],
            doNotAllowOtherChanges: response["do_not_allow_other_changes"]
        });
    }

    _convertToGiftMessageRequest(changeOrderType, response): ChangeOrderGiftMsgRequest {
        return new ChangeOrderGiftMsgRequest({
            id: response["id"],
            name: response["name"],
            changeOrderType: changeOrderType,
            currentGiftMessage: response["current_gift_message"],
            newGiftMessage: response["new_gift_message"]
        });
    }

    _convertToShipInstRequest(changeOrderType, response): ChangeOrderShipInstructionsRequest {
        return new ChangeOrderShipInstructionsRequest({
            id: response["id"],
            name: response["name"],
            changeOrderType: changeOrderType,
            currentInstructions: response["current_instructions"],
            newInstructions: response["new_instructions"]
        });
    }

    _convertToShipMethodRequest(changeOrderType, response): ChangeOrderShipMethodRequest {
        return new ChangeOrderShipMethodRequest({
            id: response["id"],
            name: response["name"],
            changeOrderType: changeOrderType,
            currentCarrier: response["current_carrier"],
            currentShipMethod: response["current_ship_method"],
            newCarrier: response["new_carrier"],
            newShipMethod: response["new_ship_method"]
        });
    }

    _convertToShipDateRequest(changeOrderType, response): ChangeOrderShipDateRequest {
        return new ChangeOrderShipDateRequest({
            id: response["id"],
            name: response["name"],
            changeOrderType: changeOrderType,
            currentShipDate: this._convertValueToDate(response["current_ship_date"]),
            newShipDate: this._convertValueToDate(response["new_ship_date"])
        });
    }

    _convertToEmailRequest(changeOrderType, response): ChangeOrderEmailRequest {
        return new ChangeOrderEmailRequest({
            id: response["id"],
            name: response["name"],
            changeOrderType: changeOrderType,
            currentEmail: response["current_email"],
            newEmail: response["new_email"]
        });
    }

    _convertToAddressRequest(changeOrderType, response): ChangeOrderAddressRequest {
        return new ChangeOrderAddressRequest({
            id: response["id"],
            name: response["name"],
            changeOrderType: changeOrderType,
            currentAddress: this._convertResponseToAddress(response["current_address"]),
            newAddress: this._convertResponseToAddress(response["new_address"])
        });
    }

    _convertToItemRequest(changeOrderType, response): ChangeOrderItemsRequest {
        return new ChangeOrderItemsRequest({
            id: response["id"],
            name: response["name"],
            changeOrderType: changeOrderType,
            items: (response["current_items"] || []).map((currentItem)=>{
                return {
                    orderItem: currentItem["item"],
                    newQuantity: currentItem["new_quantity"],
                    removeItem: currentItem["remove_item"]
                }
            }),
            newItems: (response["new_items"] || []).map((currentItem)=>{
                return {
                    item: currentItem["item"],
                    quantity: currentItem["new_quantity"]
                }
            })          
        });
    }

    _convertResponseToAddress(response): Address {
        return new Address({
            addressee: response["addressee"],            
            attention: response["attention"],            
            address1: response["street"],            
            address2: response["street2"],            
            city: response["city"],            
            state: response["state"],            
            zip: response["zipcode"],            
            country: response["country"],            
            phone: response["phone"]            
        });
    }
}