import { StandardView } from "shared-ui/views/standard-view";
import { autoinject } from "aurelia-framework";
import { ChangeOrderType } from "change-orders/models/change-order-request";

@autoinject
export class ChangeOrderStandardTaskView extends StandardView {
 
    changeRequest: ChangeOrderType;
    
    constructor() {
        super(ChangeOrderStandardTaskView.name);
    }

    async activate(params, routeData) {
        await super.activate(params, routeData);
    }

    async attached() {        
        await super.attached();
    }
}