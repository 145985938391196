import { computedFrom } from "aurelia-framework";
import { CarrierReference, ICarrierReference, IShippingMethodReference, ShippingMethodReference } from "orders/models/carrier-record";
import { IRecordReference, RecordReference } from "shared-models/record-reference";

var STATUSES = {
    "pending": "Pending",
    "processed": "Processed"
}
export interface IReturnReference extends IRecordReference{
    status?: string,
    orderNumber?: string,
    order?: IRecordReference,
    recipientName?: string,
    shipDate?: Date,
    returnDate?: Date,
    shippingCarrier?: ICarrierReference,
    shippingMethod?: IShippingMethodReference,
    returnShippingCarrier?: ICarrierReference,
    returnShippingMethod?: IShippingMethodReference,
    returnReason?: IRecordReference,
    isWillCall?: boolean,
    returnCharge?: number
    notes?: string
};
export class ReturnReference extends RecordReference{   
    status: string;
    statusName: string;
    orderNumber: string;
    order: RecordReference;
    recipientName: string;
    shipDate: Date;
    returnDate: Date;
    shippingCarrier: CarrierReference;
    shippingMethod: ShippingMethodReference;
    returnShippingCarrier: CarrierReference;
    returnShippingMethod: ShippingMethodReference;
    returnReason: RecordReference;
    isWillCall: boolean;
    returnCharge: number;
    notes: string;
    constructor(data: IReturnReference){
        super(data);   
        this.status = data.status || "";                 
        this.statusName = data.status ? STATUSES[data.status] : "";                 
        this.orderNumber = data.orderNumber || "";                 
        this.order = data.order ? new RecordReference(data.order): null;                     
        this.recipientName = data.recipientName || "";                 
        this.shipDate = data.shipDate ? new Date(data.shipDate) : null;                 
        this.returnDate = data.returnDate ? new Date(data.returnDate) : null;
        this.shippingCarrier = data.shippingCarrier ? new CarrierReference(data.shippingCarrier): null;
        this.shippingMethod = data.shippingMethod ? new ShippingMethodReference(data.shippingMethod): null; 
        this.returnShippingCarrier = data.returnShippingCarrier ? new CarrierReference(data.returnShippingCarrier): null;
        this.returnShippingMethod = data.returnShippingMethod ? new ShippingMethodReference(data.returnShippingMethod): null;                        
        this.returnReason = data.returnReason ? new RecordReference(data.returnReason): null;             
        this.isWillCall = data.isWillCall || false;                 
        this.returnCharge = data.returnCharge || 0;                 
        this.notes = data.notes || "";                 
    }

    @computedFrom('order.status')
    get statusColor(): string {
        if (this.status === "processed") {
            return "blue";
        }
        return "red";
    }

    @computedFrom('order.status')
    get isPending(): boolean {
        if (this.status === "pending") {
            return true;
        }
        return false;
    }
}

export interface IReturnRecord extends IReturnReference{items?: IReturnItemRecord[]}
export class ReturnRecord extends ReturnReference{
    items: ReturnItemRecord[];
    constructor(data: IReturnRecord){
        super(data);
        this.items = (data.items || []).map((item) => new ReturnItemRecord(item));
    }
}

export interface IReturnItemRecord extends IRecordReference{
    item?: IRecordReference, 
    shippedQuantity?: number, 
    returnedQuantity?: number, 
    toInventoryQuantity?: number, 
    reshipQuantity?: number,
    willCallQuantity?: number,
    remainingQuantity?: number
}
export class ReturnItemRecord extends RecordReference{
    item: RecordReference;
    shippedQuantity: number;
    returnedQuantity: number;
    toInventoryQuantity: number;
    reshipQuantity: number;
    willCallQuantity: number;
    remainingQuantity: number;
    constructor(data: IReturnItemRecord){
        super(data);
        this.item = data.item ? new RecordReference(data.item): null;
        this.shippedQuantity = data.shippedQuantity || 0;
        this.returnedQuantity = data.returnedQuantity || 0;
        this.toInventoryQuantity = data.toInventoryQuantity || 0;
        this.reshipQuantity = data.reshipQuantity || 0;
        this.willCallQuantity = data.willCallQuantity || 0;
        this.remainingQuantity = data.remainingQuantity || 0;
    }
}