import { autoinject } from "aurelia-framework";
import { ErrorMessageUtility } from "./error-message-utility";
import { StandardView } from "shared-ui/views/standard-view";
import { computedFrom } from "aurelia-binding";

@autoinject
export class ErrorMessageView extends StandardView{

    $errorModal: HTMLElement;

    errorHeaderMessage: string = "";

    errorMessage: string = "";

    constructor(public errorMessageUtility: ErrorMessageUtility){
        super(ErrorMessageView.name);
    }

    async activate(params, routeData) {    
        await super.activate(params, routeData);        
    }

    async attached() {
        await super.attached();
        this.errorMessageUtility.errorMessageView = this;
    }

    showError(errorMessage, errorheader){
        this.errorMessage = errorMessage;
        this.errorHeaderMessage = errorheader;
        $(this.$errorModal).modal('show');     
    }

}