import { StandardView } from "shared-ui/views/standard-view";
import { bindable, autoinject, computedFrom } from "aurelia-framework";
import { OrderReference, Order } from "orders/models/order";
import { OrderService } from "orders/services/order_service";
import { ROUTES } from "routes";
import { ChangeOrderService } from "change-orders/services/change_order_service";
import { ChangeOrder } from "change-orders/models/change-order";

@autoinject
export class OrderView extends StandardView {
  @bindable
  order: Order;

  changeOrders: ChangeOrder[];

  constructor(private orderService: OrderService, private changeOrderService: ChangeOrderService) {
    super(OrderView.name);
    this.order = null;
  }

  async activate(params, routeData) {
    try {
      let orderId = params["id"] ? parseInt(params["id"]): null;
      this.order = await this.orderService.getRecord(orderId);
      this.changeOrders = await this.changeOrderService.getChangeOrdersByOrder(orderId);
      this.logger.info(`this.order :: ${JSON.stringify(this.order)}`);
    } catch (err) {
      this.errorMessageUtil.showError(err);
      this.routeToView(ROUTES.DASHBOARD.name);
    }
    await super.activate(params, routeData);
  }

  async attached() {
    await super.attached();
  }

  @computedFrom('order.wineryOrderNumber')
  get wineryOrderNumber(): string {
    if (this.order) {
      return this.order.wineryOrderNumber;
    }
    return "";
  }

  _addNewLine(data) {
    return data ? data + "\r\n" : "";
  }

  _addBankSpaceAfter(data) {
    return data ? data + " " : ""
  }

  async viewChangeOrder(changeOrder: ChangeOrder) {
    this.disableView();
    if (changeOrder && changeOrder.id) {
      this.routeToView(ROUTES.VIEW_CHANGE_ORDER.name, { id: changeOrder.id });
    }
    this.enableView();
  }

  @computedFrom('order.status')
  get statusColor(): string {
    if (this.order.isVoided()) {
      return "red";
    }   
    return "blue";
  }

  @computedFrom('changeOrders.length')
  get pendingChangeOrder(): ChangeOrder {
    let pendingChangeOrder = null;
    this.changeOrders.forEach((changeOrder) => {
      if (changeOrder.status == "Pending Approval") {
        pendingChangeOrder = changeOrder;
      }
    });
    return pendingChangeOrder;
  }

  @computedFrom('order.shippingAddress')
  get shippingAddress(): string {
    let address = this.order.shippingAddress;
    let state = address.state ? address.state.name : "";
    let country = address.country ? address.country.name : "";
    return this._addNewLine(address.attention) +
      this._addNewLine(address.addressee) +
      this._addNewLine(address.address1) +
      this._addNewLine(address.address2) +
      this._addBankSpaceAfter(address.city) + this._addBankSpaceAfter(state) + this._addNewLine(address.zip) +
      country;
  }

  async clickChangeOrder() {
    if (this.pendingChangeOrder) {
      this.routeToView(ROUTES.VIEW_CHANGE_ORDER.name, { id: this.pendingChangeOrder.id });
    } else {
      this.routeToView(ROUTES.CREATE_CHANGE_ORDER.name, { orderid: this.order.id });
    }

  }

}