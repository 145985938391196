export interface IGetRecordRequest {recordType: string, recordId?: number, additionalFields?: any;};
export class GetRecordRequest{
    recordType: string;
    recordId?: number;   
    additionalFields: any;
    
    constructor(data: IGetRecordRequest){
        this.recordType = data.recordType;
        this.recordId = data.recordId;
        this.additionalFields = data.additionalFields;           
    }
}