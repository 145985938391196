import { IItemReference, ItemReference } from "items/models/item-record";
import { IRecordReference, RecordReference } from "shared-models/record-reference";
import { BillingRate } from "./billing-rate";

export interface IRateCalculatorRequest {
    shipmethodid?: number,
    zoneid?: number,
    items?: RateCalculatorRequestItem[]};

export class RateCalculatorRequest{    
    shipmethodid: number;
    zoneid: number;
    items: RateCalculatorRequestItem[];    
    constructor(data: IRateCalculatorRequest){
        data = data || {}
        this.shipmethodid = data.shipmethodid || null;
        this.zoneid = data.zoneid || null;
        this.items = (data.items || []).map((item) => new RateCalculatorRequestItem(item));   
    }
}

export interface IRateCalculatorRequestItem {id?: number, addedLine?: boolean, itemReference?: IItemReference, quantity?: number};
export class RateCalculatorRequestItem {
    id: number;
    addedLine: boolean;
    itemReference: ItemReference;
    quantity: number;
    constructor(data: IRateCalculatorRequestItem){
        data = data || {}
        this.id = data.id;
        this.addedLine = data.addedLine || false;
        this.itemReference = data.itemReference ? new ItemReference(data.itemReference): null;
        this.quantity = typeof data.quantity === "string" ? parseInt(data.quantity, 10) : data.quantity;      
    }
}

export interface IRateCalculatorRequest {matchedRates?: MatchedRate[], shippingMethods?: String[], totalCharge?: number};
export class RateCalculatorResponse{    
    matchedRates: MatchedRate[];
    shippingMethods: String[];
    totalCharge: number;
    constructor(data: IRateCalculatorRequest){
        data = data || {}
        this.matchedRates = (this.matchedRates || []).map((matchedRate) => new MatchedRate(matchedRate));
        this.shippingMethods = data.shippingMethods || [];        
        this.totalCharge = data.totalCharge || 0;
    }

    isColdChain(): boolean{
        if(this.shippingMethods.indexOf("Cold Chain") > -1){
            return true;
        }
        return false;
    }
}

export interface IMatchedRate {billingRate?: BillingRate, quantity?: number};
export class MatchedRate{    
    billingRate: BillingRate;
    quantity: number;    
    constructor(data: IMatchedRate){
        data = data || {}
        this.billingRate = data.billingRate ? new BillingRate(data.billingRate): null;
        this.quantity = data.quantity || 0;
    }
}