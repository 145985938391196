import { autoinject } from "aurelia-framework";
import { DefaultService } from "shared-service/default-service";
import { SearchResult } from "shared-models/search-result";
import { SearchRequest } from "shared-models/search-request";

@autoinject
export class SearchService extends DefaultService{
    private static readonly URI = "search";

    constructor(){
        super(SearchService.name);
    }

    async searchRecords(searchRequest: SearchRequest, recordConverter: (record: any) => any, self:any=this): Promise<SearchResult>{
        let searchResult = await this.post(SearchService.URI, searchRequest);
        
        let records = (searchResult["records"] || []).map(recordConverter.bind(self));
        return new SearchResult({
            recordType: searchRequest.recordType,
            filter: searchRequest.filter,
            pageCount: searchResult["pageCount"],
            pageSize: searchResult["pageSize"],
            totalRecords: searchResult["totalRecords"],
            records: records
        });
    }
}