import { autoinject } from "aurelia-framework";
import { DefaultService } from "shared-service/default-service";
import { ShippingAddress } from "shipping-addresses/models/shipping-address-record";
import { SearchService } from "shared-service/search-service";
import { SearchRequest } from "shared-models/search-request";
import { SearchResult } from "shared-models/search-result";
import { GetRecordService } from "shared-service/get-record-service";
import { GetRecordRequest } from "shared-models/get-record-request";
import { CreateRecordService } from "shared-service/create-record-service";
import { UpdateRecordService } from "shared-service/update-record-service";
import { CreateRecordRequest } from "shared-models/create-record-request";
import { UpdateRecordRequest } from "shared-models/update-record-request";

@autoinject
export class ShippingAddressService extends DefaultService{
    private static readonly RECORD_TYPE = "vf_winery_shipping_address";

    constructor(
        private searchService: SearchService, 
        private getRecordService: GetRecordService, 
        private updateRecordService: UpdateRecordService, 
        private createRecordService: CreateRecordService){
        super(ShippingAddressService.name);
    }

    sortAddress(address1, address2){
        return (address1.name || "").toLowerCase().localeCompare((address2.name || "").toLowerCase());
    }

    async searchRecords(searchRequestData: {page?: number, pageSize?: number, filter?: string, fieldFilters?: any[]}): Promise<SearchResult>{
        let searchRequest = new SearchRequest({
            recordType: ShippingAddressService.RECORD_TYPE,
            pageSize: searchRequestData.pageSize || 50,
            page: searchRequestData.page || 1,
            filter: searchRequestData.filter || "",
            fieldFilters: searchRequestData.fieldFilters || []
        });
        let searchResponse =  await this.searchService.searchRecords(searchRequest, this._convertResponseToAddress);
        searchResponse.records = searchResponse.records.sort(this.sortAddress);
        return searchResponse;
    }

    async getAllRecords(fieldFilters: any[]=[]): Promise<ShippingAddress[]>{
        let allItems = [] as ShippingAddress[];
        let page = 1;
        let pageSize = 5000;
        let initialSearch = await this.searchRecords({page: page, pageSize: pageSize, fieldFilters: fieldFilters});
        allItems = allItems.concat(initialSearch.records as ShippingAddress[]);
        var totalPageCount = initialSearch.pageCount;
        if(totalPageCount > 1){
            while(page < totalPageCount){
                page++;
                let nextSearch = await this.searchRecords({page: page, pageSize: pageSize, fieldFilters: fieldFilters});
                allItems = allItems.concat(nextSearch.records as ShippingAddress[]);
            }           
        }
        allItems = allItems.sort(this.sortAddress);   
        return allItems;
    }

    async getRecord(addressId: number): Promise<ShippingAddress>{
        let getRecordRequest = new GetRecordRequest({
            recordType: ShippingAddressService.RECORD_TYPE,
            recordId: addressId
        });
        let getRecordResponse = await this.getRecordService.getRecord(getRecordRequest, this._convertResponseToAddress);
        return getRecordResponse.record;
    }

    async createRecord(addressRecord: ShippingAddress): Promise<number>{
        let createRecordRequest = new CreateRecordRequest({
            recordType: ShippingAddressService.RECORD_TYPE,
            recordData: addressRecord
        });
        return await this.createRecordService.createRecord(createRecordRequest);
    }

    async updateRecord(addressId: number, addressRecord: ShippingAddress): Promise<number>{
        let updateRecordRequest = new UpdateRecordRequest({
            recordType: ShippingAddressService.RECORD_TYPE,
            recordId: addressId,
            recordData: addressRecord
        });
        return await this.updateRecordService.updateRecord(updateRecordRequest);
    }

    _convertResponseToAddress(shippingAddressResponse): ShippingAddress{
        return new ShippingAddress({
            id: shippingAddressResponse["id"],
            name: shippingAddressResponse["name"],
            createdByUser: shippingAddressResponse["created_by_user"],
            lastUpdatedByUser: shippingAddressResponse["last_updated_by_user"],
            isResidential: shippingAddressResponse["is_residential"],
            attention: shippingAddressResponse["attention"],
            addressee: shippingAddressResponse["addressee"],
            street: shippingAddressResponse["street"],
            street2: shippingAddressResponse["street2"],
            zipcode: shippingAddressResponse["zipcode"],
            city: shippingAddressResponse["city"],
            state: shippingAddressResponse["state"],
            country: shippingAddressResponse["country"],
            phone: shippingAddressResponse["phone"],
            emails: shippingAddressResponse["emails"]
        });
    }
}