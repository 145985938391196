import { autoinject } from "aurelia-framework";
import { DefaultService } from "shared-service/default-service";
import { ItemReference, ItemRecord } from "items/models/item-record";
import { SearchService } from "shared-service/search-service";
import { GetRecordService } from "shared-service/get-record-service";
import { SearchResult } from "shared-models/search-result";
import { SearchRequest } from "shared-models/search-request";
import { GetRecordRequest } from "shared-models/get-record-request";
import { ActivityDetailRequest, ActivityDetailResponse } from "items/models/activity-detail-report";

@autoinject
export class ItemService extends DefaultService{
    private static readonly RECORD_TYPE = "product.template";
    private static readonly ACTIVITY_DETAIL_RECORD_TYPE = "vf_activity_detail";

    constructor(
        private searchService: SearchService, 
        private getRecordService: GetRecordService ){
        super(ItemService.name);
    }

    async getActivityDetailResponse(request: ActivityDetailRequest): Promise<ActivityDetailResponse>{
        let getRecordsRequest = new GetRecordRequest({
            recordType: ItemService.ACTIVITY_DETAIL_RECORD_TYPE,
            additionalFields: request
        });
        let getRecordResponse = await this.getRecordService.getRecord(getRecordsRequest, this._convertResponseToActivityDetailReport);
        return getRecordResponse.record;
    }

    async searchRecords(searchRequestData: {page?: number, pageSize?: number, filter?: string, fieldFilters?: any[]}): Promise<SearchResult>{
        let searchRequest = new SearchRequest({
            recordType: ItemService.RECORD_TYPE,
            pageSize: searchRequestData.pageSize || 50,
            page: searchRequestData.page || 1,
            filter: searchRequestData.filter || "",
            fieldFilters: searchRequestData.fieldFilters || []
        });
        return await this.searchService.searchRecords(searchRequest, this._convertResponseToItemReference)
    }

    async getAllRecords(fieldFilters: any[]=[]): Promise<ItemReference[]>{
        let allItems = [] as ItemReference[];
        let page = 1;
        let pageSize = 5000;
        let initialSearch = await this.searchRecords({page: page, pageSize: pageSize, fieldFilters: fieldFilters});
        allItems = allItems.concat(initialSearch.records as ItemReference[]);
        var totalPageCount = initialSearch.pageCount;
        if(totalPageCount > 1){
            while(page < totalPageCount){
                page++;
                let nextSearch = await this.searchRecords({page: page, pageSize: pageSize, fieldFilters: fieldFilters});
                allItems = allItems.concat(nextSearch.records as ItemReference[]);
            }           
        }        
        return allItems;
    }

    async getRecord(itemId: number): Promise<ItemRecord>{
        let getRecordRequest = new GetRecordRequest({
            recordType: ItemService.RECORD_TYPE,
            recordId: itemId
        });
        let getRecordResponse = await this.getRecordService.getRecord(getRecordRequest, this._convertResponseToItem);
        return getRecordResponse.record;
    }

    _convertResponseToActivityDetailReport(response: any): ActivityDetailResponse{
        return new ActivityDetailResponse({           
           fileDataBase64: response["excel_file"]
        });
    }

    _convertResponseToItem(itemResponse): ItemRecord{
        return new ItemRecord({
            id: itemResponse["id"],
            productId: itemResponse["product_variant_id"][0],
            name: itemResponse["name"],
            winerySku: itemResponse["winery_sku"],
            isInventoryItem: itemResponse["is_inventory_item"],
            isKitItem: itemResponse["is_kit_item"],
            onHand: itemResponse["on_hand"],
            available: itemResponse["available"],
            committed: itemResponse["committed"],
            backordered: itemResponse["backordered"]
        });
    }

    _convertResponseToItemReference(itemRefResponse): ItemReference{
        return new ItemReference({
            id: itemRefResponse["id"],
            productId: itemRefResponse["product_variant_id"][0],
            name: itemRefResponse["name"],
            winerySku: itemRefResponse["winery_sku"],
            isInventoryItem: itemRefResponse["is_inventory_item"],
            isKitItem: itemRefResponse["is_kit_item"],
            onHand: itemRefResponse["on_hand"],
            available: itemRefResponse["available"],
            committed: itemRefResponse["committed"],
            backordered: itemRefResponse["backordered"]
        });
    }
}