import { autoinject } from "aurelia-framework";
import { DefaultService } from "shared-service/default-service";
import { UpdateRecordRequest } from "shared-models/update-record-request";

@autoinject
export class UpdateRecordService extends DefaultService{
    private static readonly URI = "update-record";

    constructor(){
        super(UpdateRecordService.name);
    }

    async updateRecord(getRecordRequest: UpdateRecordRequest): Promise<number>{
        return await this.post(UpdateRecordService.URI, getRecordRequest);
    }
}