import { autoinject } from "aurelia-framework";
import { DefaultService } from "shared-service/default-service";
import { AlertNotification } from "../models/alert-notification";

@autoinject
export class NotificationService extends DefaultService{
    private static readonly URI = "alert-notification";

    constructor(){
        super(NotificationService.name);        
    }

    async getAlertNotifications(): Promise<AlertNotification[]>{
        try{
            let response = await this.get(NotificationService.URI);
            response = response || [];
            this.logger.info("Alert Notifications Response :: {}", response);
            return response.map(alertNotification => new AlertNotification(alertNotification));
        } catch(err){
            this.logger.error("error getting Alert Notifications :: {}", err);
            return [];
        }
    }
}