import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, bindable } from "aurelia-framework";
import { ChangeOrderEmailRequest } from "change-orders/models/change-order-request";

@autoinject
export class ChangeOrderEmailView extends StandardView {

  @bindable
  changeRequest: ChangeOrderEmailRequest;
  
  $requestForm: HTMLElement;


  constructor() {
    super(ChangeOrderEmailView.name);
  }

  async activate(params, routeData) {
    this.changeRequest = params;
    await super.activate(params, routeData);
  }

  async attached() {    
    await this._setupFields();
    await this._setupForms();
    await super.attached();
  }

  async _setupFields() {   
  }

  async _setupForms() {

  }

}