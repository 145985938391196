import { StandardView } from "shared-ui/views/standard-view";
import { bindable, autoinject, observable, computedFrom } from "aurelia-framework";
import { ROUTES } from "routes";
import { ItemReference } from "items/models/item-record";
import { ItemService } from "items/services/item_service";
import { ActivityDetailRequest, ItemLine } from "items/models/activity-detail-report";


@autoinject
export class ActivityDetailReport extends StandardView {

    @observable
    searchInput: string;

    formDisabled: boolean = false;

    allTimeInventory: boolean = false;

    dateFrom: Date;

    dateTo: Date;

    includeAllItems: boolean = false;

    currentItemReference: ItemReference;

    itemLines: ItemLine[] = [];

    allItems: ItemReference[] = [];

    currentLineNumber: number = 0;

    $dateFromCalendar: HTMLElement;

    $dateToCalendar: HTMLElement;

    $exportLink: HTMLElement;

    $asOfCalendar: HTMLElement;

    $itemDropdown: HTMLElement;

    constructor(private itemService: ItemService) {
        super(ActivityDetailReport.name);
    }

    async activate(params, routeData) {
        try {
            let promiseResponse = await Promise.all([               
                this.itemService.getAllRecords()
              ]);
              this.allItems = promiseResponse[0].filter((itemRec)=> itemRec.isInventoryItem === true && itemRec.isKitItem === false);
            this.addNewItemLine();
        } catch (err) {
            this.errorMessageUtil.showError(err);
            this.router.navigateToRoute(ROUTES.DASHBOARD.name, {}, { trigger: true, replace: true });
        }
        await super.activate(params, routeData);
    }

    async attached() {
        await this._setupFields();
        await super.attached();
    }

    disableForm() {
        this.formDisabled = true;
    }

    enableForm() {
        this.formDisabled = false;
    }

    async _setupFields() {
        await Promise.all([
            $('.ui.checkbox').checkbox(),

            $(this.$dateFromCalendar).calendar({
                type: 'date',
                onChange: (date, text, mode) => {
                    this.dateFrom = date;
                }
            }),

            $(this.$dateToCalendar).calendar({
                type: 'date',
                onChange: (date, text, mode) => {
                    this.dateTo = date;
                }
            })           
        ]);
    }

    async addNewItemLine(itemLine?: ItemLine) {
        if(itemLine){      
          if(!itemLine.item){
            this.errorMessageUtil.showErrorMessage("Need To Provide an Item.");
            return;
          }         
          itemLine.addedLine = true;
        }
        this.itemLines.push(new ItemLine({lineNumber: this.currentLineNumber}));
        this.currentLineNumber++;
      }

    async removeItemLine(lineToRemove) {
        this.itemLines = this.itemLines.filter((itemLine) => itemLine.lineNumber != lineToRemove.lineNumber);
    }

    async getReport() {
        this.disableView();
        let activityDetailRequest = new ActivityDetailRequest({
            allTimeInventory: this.allTimeInventory,
            dateFrom: this.allTimeInventory ? null : this.dateFrom,
            dateTo: this.allTimeInventory ? null : this.dateTo,
            itemIds: this.itemLines.filter((item)=> item.addedLine).map((item) => item.item.id)
        });
        this.logger.info(`activityDetailRequest :: ${JSON.stringify(activityDetailRequest)}`);
        let activityDetailResponse = await this.itemService.getActivityDetailResponse(activityDetailRequest);
        this.logger.info(`activityDetailResponse :: ${activityDetailResponse}`);
        $(this.$exportLink).attr('href', `data:application/vnd.ms-excel;base64, ${escape(activityDetailResponse.fileDataBase64)}`);
        this.$exportLink.click();
        this.enableView();
    }  

}