import { autoinject } from "aurelia-framework";
import { DefaultService } from "shared-service/default-service";
import { CountryReference, StateReference } from "orders/models/country-record";
import { SearchService } from "shared-service/search-service";
import { SearchRequest } from "shared-models/search-request";
import { SearchResult } from "shared-models/search-result";
import { GetRecordsRequest } from "shared-models/get-records-request";
import { GetRecordsService } from "shared-service/get-records-service";
import { GetRecordRequest } from "shared-models/get-record-request";
import { GetRecordService } from "shared-service/get-record-service";

@autoinject
export class CountryService extends DefaultService{
    private static readonly COUNTRY_RECORD_TYPE = "res.country";

    constructor(private searchService: SearchService, private getRecordService: GetRecordService, private getRecordsService: GetRecordsService){
        super(CountryService.name);        
    }

    async searchCountryRecords(page: number, filter: string): Promise<SearchResult>{
        page = page || 1;
        filter = filter || "";
        let searchRequest = new SearchRequest({
            recordType: CountryService.COUNTRY_RECORD_TYPE,
            pageSize: 50,
            page: page,
            filter: filter
        });
        return await this.searchService.searchRecords(searchRequest, this._convertResponseToCountry)
    }

    async getCountryRecord(countryId: number): Promise<CountryReference>{
        let getRecordRequest = new GetRecordRequest({
            recordType: CountryService.COUNTRY_RECORD_TYPE,
            recordId: countryId
        });
        let getRecordResponse = await this.getRecordService.getRecord(getRecordRequest, this._convertResponseToCountry);
        return getRecordResponse.record;
    }

    async getAllCountryRecords(): Promise<CountryReference[]>{
        let getRecordsRequest = new GetRecordsRequest({
            recordType: CountryService.COUNTRY_RECORD_TYPE
        });
        let getRecordsResponse = await this.getRecordsService.getRecords(getRecordsRequest, this._convertResponseToCountry);       
        return getRecordsResponse.records;
    }

    _convertResponseToCountry(countryResponse: any): CountryReference{
        return new CountryReference({
            id: countryResponse["id"],
            name: countryResponse["name"],
            doNotShip: countryResponse["cannot_ship_to"],
            states: countryResponse["states"]
        });
    }

    _convertResponseToState(stateResponse: any): StateReference{
        return new StateReference({
            id: stateResponse["id"],
            name: stateResponse["name"],
            country: stateResponse["country_id"],
            doNotShip: stateResponse["cannot_ship_to"]
        });
    }
}