import { StandardView } from "shared-ui/views/standard-view";
import { bindable, autoinject, observable } from "aurelia-framework";
import { OrderReference } from "orders/models/order";
import { OrderService } from "orders/services/order_service";
import { ROUTES } from "routes";
import { SearchResult } from "shared-models/search-result";
import { SimpleDateFormatValueConverter } from "shared-ui/converters/simple-date-format";
import { BooleanFormatValueConverter } from "shared-ui/converters/boolean-format";
import parse from "papaparse";

export enum VIEW_TYPES {
    ALL = "all",
    OPEN = "open",
    WEATHER_HOLDS = "weather-holds",
    BACKORDERS = "backorders",
    COLD_CHAIN_COMP = "cold-chain-comp",
    COLD_CHAIN_ORDERS = "cold-chain",
}

@autoinject
export class OrderListView extends StandardView {
    @bindable
    orders: OrderReference[];

    currentSearchResult: SearchResult;

    currentPage: number = 1;

    orderListDisabled: boolean = false;

    $downloadCSVLink: HTMLElement;

    $exportLink: HTMLElement;

    listType: string;

    @observable
    searchInput: string = "";


    constructor(
        private orderService: OrderService, 
        private simpleDateFormat: SimpleDateFormatValueConverter, 
        private booleanFormat: BooleanFormatValueConverter) {
        super(OrderListView.name);
        this.orders = [];
    }

    async activate(params, routeData) {
        try {            
            this.listType = params["listType"];
            await this._getOrders();
            this.logger.info(`this.orders :: ${JSON.stringify(this.orders)}`);            
        } catch (err) {
            this.errorMessageUtil.showError(err);
            this.routeToView(ROUTES.DASHBOARD.name);
        }
        await super.activate(params, routeData);
    }

    async attached() {
        await super.attached();
    }

    async _getOrders() {
        let fieldFilters = [];
        if(this.listType === VIEW_TYPES.OPEN){
            fieldFilters = this._getOpenFilters();
        } else if(this.listType === VIEW_TYPES.WEATHER_HOLDS){
            fieldFilters = this._getWhFilters();
        } else if(this.listType === VIEW_TYPES.BACKORDERS){
            fieldFilters = this._getBackorderedFilters();
        } else if(this.listType === VIEW_TYPES.COLD_CHAIN_COMP){
            fieldFilters = this._getColdChainCompFilters();
        } else if(this.listType === VIEW_TYPES.COLD_CHAIN_ORDERS){
            fieldFilters = this._getColdChainFilters();
        }
        this.logger.info(`fieldFilters :: ${JSON.stringify(fieldFilters)}`);
        this.currentSearchResult = await this.orderService.searchRecords({page: this.currentPage, filter: this.searchInput, fieldFilters: fieldFilters});
        this.orders = this.currentSearchResult.records as OrderReference[];
    }

    _getOpenFilters(){
        return [['winery_order_status', '=', ['pending_approval','pending_fulfill']]];
    }

    _getWhFilters(){      
        let allFilters = this._getOpenFilters();  
        allFilters.push(['weather_hold', '=', true] as string[]);      
        return allFilters;
    }

    _getBackorderedFilters(){
        let allFilters = this._getOpenFilters();  
        allFilters.push(['order_is_backordered', '=', true] as string[]);      
        return allFilters;
    }

    _getColdChainCompFilters(){
        let allFilters = this._getOpenFilters();  
        allFilters.push(['is_cold_chain_compatible', '=', true] as string[]);      
        allFilters.push(['is_cold_chain', '=', false] as string[]);      
        return allFilters;
    }

    _getColdChainFilters(){
        let allFilters = this._getOpenFilters();  
        allFilters.push(['is_cold_chain', '=', true] as string[]);      
        return allFilters;
    }

    async searchInputChanged(newValue: string, oldValue: string) {
        this.disableAddressList();
        this.currentPage = 1;
        await this._getOrders();
        this.enableAddressList();
    }

    async clickPrevious() {
        this.disableAddressList();
        if (this.currentPage > 1) {
            this.currentPage--;
        }
        await this._getOrders();
        this.enableAddressList();
    }

    async clickNext() {
        this.disableAddressList();
        if (this.currentPage < this.currentSearchResult.pageCount) {
            this.currentPage++;
        }
        await this._getOrders();
        this.enableAddressList();
    }


    disableAddressList() {
        this.orderListDisabled = true;
    }

    enableAddressList() {
        this.orderListDisabled = false;
    }

    async exportOrders() {
        this.disableView();
        let ordersToExport = [];
        if (this.searchInput) {
            ordersToExport = this.orders
        } else {
            ordersToExport = await this.orderService.getAllRecords();
        }
        let ordersCSV = this.convertOrdersToCsv(ordersToExport);
        $(this.$exportLink).attr('href', `data:text/csv;charset=utf-8, ${escape(ordersCSV)}`);       
        this.$exportLink.click();        
        this.enableView();
    }

    convertOrdersToCsv(ordersToExport: OrderReference[]) {
        return parse.unparse(ordersToExport.map((orderToExport) => {
            return {
                "VinFillment Order Number": orderToExport.vinfillmentOrderNumber,
                "Order Number": orderToExport.wineryOrderNumber,
                "Status": orderToExport.status,
                "Future Ship Date": this.simpleDateFormat.toView(orderToExport.futureShipDate),
                "Ship Date": orderToExport.shipDate,
                "Carrier": orderToExport.carrierName,
                "Shipping Method": orderToExport.shippingMethodName,
                "Shipping Instructions": orderToExport.shippingInstructions,
                "Gift Message": orderToExport.giftMessage,
                "Include Ice": this.booleanFormat.toView(orderToExport.includeIce),
                "email": orderToExport.email,
                "declaredValue": orderToExport.declaredValue,
                "Addressee": orderToExport.shippingAddress.addressee,                
                "Attention": orderToExport.shippingAddress.attention,                
                "Address1": orderToExport.shippingAddress.address1,                
                "Address2": orderToExport.shippingAddress.address2,                
                "City": orderToExport.shippingAddress.city,                
                "State": orderToExport.shippingAddress.stateName,                
                "Zip": orderToExport.shippingAddress.zip,                
                "Country": orderToExport.shippingAddress.countryName,                
                "Phone": orderToExport.shippingAddress.phone          
            };
        }));
    }

    newOrder(){
        this.routeToView(ROUTES.CREATE_ORDER.name);
    }

    viewOrder(currentOrder){
        this.routeToView(ROUTES.VIEW_ORDER.name, {id: currentOrder.id});
    }

    changeOrder(currentOrder){
        this.routeToView(ROUTES.CREATE_CHANGE_ORDER.name, {orderid: currentOrder.id});
    }


}