/// <reference types="aurelia-loader-webpack/src/webpack-hot-interface"/>
// we want font-awesome to load as soon as possible to show the fa-spinner
import './styles.css';
import 'fomantic-ui-css/semantic.css';
import 'font-awesome/css/font-awesome.css';
import { Aurelia, LogManager } from 'aurelia-framework';
import { ConsoleAppender } from 'aurelia-logging-console';
import { PLATFORM } from 'aurelia-pal';
import Bluebird from 'bluebird';
import '../extra_modules/semantic.js';

LogManager.addAppender(new ConsoleAppender());
LogManager.setLevel(LogManager.logLevel.debug);

// remove out if you don't want a Promise polyfill (remove also from webpack.config.js)
Bluebird.config({ warnings: { wForgottenReturn: false } });

const delay = ms => new Promise(res => setTimeout(res, ms));

export async function configure(aurelia: Aurelia) {  
  aurelia.use
    .standardConfiguration()
    .globalResources(PLATFORM.moduleName('shared-ui/converters/lowercase-format'))
    .globalResources(PLATFORM.moduleName('shared-ui/converters/currency-format'))
    .globalResources(PLATFORM.moduleName('shared-ui/converters/simple-datetime-format'))
    .globalResources(PLATFORM.moduleName('shared-ui/converters/simple-date-format'))
    .globalResources(PLATFORM.moduleName('shared-ui/converters/boolean-format'))
    .developmentLogging();

  aurelia.use.plugin(PLATFORM.moduleName("aurelia-configuration"), config => {
    config.setEnvironments({
      local: ['localhost:8080', 'localhost:8084', 'localhost'],
      staging: ['d3daxp27ztlrk9.cloudfront.net', 'staging.vinfillment.portal.stravinski.io'],
      live: ['deunxv7xshi0b.cloudfront.net', 'vinfillment.portal.stravinski.io']
    });
  });
  
  // Uncomment the line below to enable animation.
  // aurelia.use.plugin(PLATFORM.moduleName('aurelia-animator-css'));
  // if the css animator is enabled, add swap-order="after" to all router-view elements

  // Anyone wanting to use HTMLImports to load views, will need to install the following plugin.
  // aurelia.use.plugin(PLATFORM.moduleName('aurelia-html-import-template-loader'));
  $('#splash-loader').show();
  await aurelia.start();
  aurelia.setRoot(PLATFORM.moduleName('loader'));  
}

