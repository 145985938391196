import { autoinject } from "aurelia-framework";
import { DefaultService } from "shared-service/default-service";
import { CarrierReference } from "orders/models/carrier-record";
import { GetRecordsRequest } from "shared-models/get-records-request";
import { GetRecordsService } from "shared-service/get-records-service";

@autoinject
export class CarrierService extends DefaultService{
    private static readonly RECORD_TYPE = "vf_shipping_carrier";

    constructor(private getRecordsService: GetRecordsService){
        super(CarrierService.name);
    }

    async getRecords(): Promise<CarrierReference[]>{
        let getRecordsRequest = new GetRecordsRequest({
            recordType: CarrierService.RECORD_TYPE
        });
        let getRecordsResponse = await this.getRecordsService.getRecords(getRecordsRequest, this._convertResponseToCarrier);       
        return getRecordsResponse.records;
    }

    _convertResponseToCarrier(carrierResponse: any): CarrierReference{
        return new CarrierReference({
            id: carrierResponse["id"],
            name: carrierResponse["name"],
            shippingMethods: carrierResponse["shippingMethods"]
        });
    }
}