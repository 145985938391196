import { IRecordReference, RecordReference } from "shared-models/record-reference";

export interface ICountryReference extends IRecordReference{states?: IStateReference[], doNotShip?: boolean};
export class CountryReference extends RecordReference{
    doNotShip?: boolean;
    states?: StateReference[];
    constructor(data: ICountryReference){
        data = data || {}
        super(data)
        this.doNotShip = data.doNotShip || false;        
        this.states = (data.states || []).map((state) => new StateReference(state));
    }
}

export interface IStateReference extends IRecordReference{doNotShip?: boolean, country?: ICountryReference};
export class StateReference extends RecordReference{   
    doNotShip?: boolean;
    country?: CountryReference;
    constructor(data: IStateReference){
        data = data || {}
        super(data)  
        this.doNotShip = data.doNotShip || false;      
        this.country = data.country ? new CountryReference(data.country) : null;      
    }
}