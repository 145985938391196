import { autoinject } from "aurelia-framework";
import { DefaultService } from "shared-service/default-service";
import { GetRecordsResponse } from "shared-models/get-records-response";
import { GetRecordsRequest } from "shared-models/get-records-request";

@autoinject
export class GetRecordsService extends DefaultService{
    private static readonly URI = "get-records";

    constructor(){
        super(GetRecordsService.name);
    }

    async getRecords(getRecordsRequest: GetRecordsRequest, recordConverter: (record: any) => any, self:any=this): Promise<GetRecordsResponse>{
        let getRecordsResult = await this.post(GetRecordsService.URI, getRecordsRequest);   
        let records = (getRecordsResult["records"] || []).map(recordConverter.bind(self));
        return new GetRecordsResponse({
            recordType: getRecordsRequest.recordType,
            records: records
        });
    }
}