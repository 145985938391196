import {autoinject} from "aurelia-dependency-injection";
import { Logger } from "aurelia-logging";
import { LogManager, Container } from "aurelia-framework";
import { API, Auth } from "aws-amplify";
import moment from 'moment';
import { UserSession } from "user/models/user-session";

@autoinject
export class DefaultService {
    static readonly API_NAME = "CustomerPortalBackend";
    logger: Logger;
    protected userSession: UserSession
    constructor(className:string) {
        this.logger = LogManager.getLogger(className);
        this.userSession = Container.instance.get(UserSession);
    }

    private async getBearerToken(): Promise<string>{
        return `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
    }

    async get(uri: String): Promise<any> {
        let response =  await API.get(DefaultService.API_NAME, `${uri}`, {
            headers: {
                Authorization: await this.getBearerToken(),
                "Winery-Id": this.userSession.currentWinery ? this.userSession.currentWinery.id : ""
            }
        });
        return response;
    }

    async post(uri: String, requestData: any): Promise<any> {
        requestData = requestData || {};
        return await API.post(DefaultService.API_NAME, `${uri}`, {
            headers: {
                Authorization: await this.getBearerToken(),
                "Winery-Id": this.userSession.currentWinery ? this.userSession.currentWinery.id : ""                
            },
            body: requestData
        });
    }

    async put(uri: String, requestData: any): Promise<any> {
        requestData = requestData || {};
        return await API.put(DefaultService.API_NAME, `${uri}`, {
            headers: {
                Authorization: await this.getBearerToken(),
                "Winery-Id": this.userSession.currentWinery ? this.userSession.currentWinery.id : ""
            },
            body: requestData
        });
    }

    getRecordArrayFieldValue(value): any{
        return value && value.length === 2 ? { id: value[0], name: value[1] } : null;
    }

    _convertValueToDate(dateValue): Date{
        return moment(dateValue, "YYYY-MM-DD").toDate();
    }

    _convertValueToDateTime(dateTimeValue): Date{
        return moment.utc(dateTimeValue, "YYYY-MM-DD hh:mm:ss").toDate();
    }
}