import { PLATFORM } from "aurelia-pal";

export const ROUTES = {
    DASHBOARD: {
        routes: ["", "dashboard"],
        name: "dashboard",
        moduleId: PLATFORM.moduleName("dashboard/dashboard"),
        title: "Dashboard"
    }, 
    NAVIGATION: {
        routes: ["notifications"],
        name: "notifications",
        moduleId: PLATFORM.moduleName("notifications/views/alert-notifications-book-view"),
        title: "Notifications"
    },
    CREATE_ORDER: {
        routes: ["create-order"],
        name: "create-order",
        moduleId: PLATFORM.moduleName("orders/views/order-create-view"),
        title: "Create Order"
    },
    VIEW_ORDER: {
        routes: ["order"],
        name: "order",
        moduleId: PLATFORM.moduleName("orders/views/order-view"),
        title: "View Order"
    },
    LIST_ORDERS: {
        routes: ["orders"],
        name: "list-orders",
        moduleId: PLATFORM.moduleName("orders/views/order-list-view"),
        title: "Orders"
    },
    LIST_SHIPPING_ADDRESSES: {
        routes: ["shipping-addresses"],
        name: "list-shipping-addresses",
        moduleId: PLATFORM.moduleName("shipping-addresses/views/shipping-address-list-view"),
        title: "Shipping Addresses"
    },
    VIEW_SHIPPING_ADDRESS: {
        routes: ["shipping-address"],
        name: "shipping-address",
        moduleId: PLATFORM.moduleName("shipping-addresses/views/shipping-address-view"),
        title: "View Shipping Address"
    },
    VIEW_IMPORT_ORDERS: {
        routes: ["import-orders"],
        name: "import-orders",
        moduleId: PLATFORM.moduleName("orders/views/import-orders-view"),
        title: "Import Orders"
    },
    ORDER_LIST_DASHBOARD: {
        routes: ["order-list-dashboard"],
        name: "order-list-dashboard",
        moduleId: PLATFORM.moduleName("orders/views/order-list-dashboard"),
        title: "Order List Dashboard"
    },
    LIST_ORDER_BATCHES: {
        routes: ["order-batches"],
        name: "list-batches",
        moduleId: PLATFORM.moduleName("orders/views/order-batch-list-view"),
        title: "Order Batches"
    },
    VIEW_ORDER_BATCH: {
        routes: ["order-batch"],
        name: "order-batch",
        moduleId: PLATFORM.moduleName("orders/views/order-batch-view"),
        title: "View Order Batch"
    },
    LIST_CHANGE_ORDER: {
        routes: ["change-orders"],
        name: "change-orders",
        moduleId: PLATFORM.moduleName("change-orders/views/change-order-list-view"),
        title: "Change Orders"
    },
    VIEW_CHANGE_ORDER: {
        routes: ["change-order"],
        name: "change-order",
        moduleId: PLATFORM.moduleName("change-orders/views/change-order-view"),
        title: "Change Order"
    },
    CREATE_CHANGE_ORDER: {
        routes: ["create-change-order"],
        name: "create-change-order",
        moduleId: PLATFORM.moduleName("change-orders/views/change-order-view"),
        title: "Create Change Order"
    },
    ITEMS_DASHBOARD: {
        routes: ["items-dashboard"],
        name: "items-dashboard",
        moduleId: PLATFORM.moduleName("items/views/items-dashboard"),
        title: "Items Dashboard"
    },
    LIST_ITEMS: {
        routes: ["items"],
        name: "list-items",
        moduleId: PLATFORM.moduleName("items/views/list-items-view"),
        title: "Items"
    },
    VIEW_ITEM: {
        routes: ["item"],
        name: "view-item",
        moduleId: PLATFORM.moduleName("items/views/item-view"),
        title: "Item"
    }, 
    COLD_CHAIN_DASHBOARD: {
        routes: ["cold-chain-dashboard"],
        name: "cold-chain-dashboard",
        moduleId: PLATFORM.moduleName("cold-chain/views/cold-chain-dashboard"),
        title: "Cold Chain Dashboard"
    },  
    COLD_CHAIN_ZIPS: {
        routes: ["cold-chain-zips"],
        name: "cold-chain-zips",
        moduleId: PLATFORM.moduleName("cold-chain/views/cold-chain-zips-view"),
        title: "Cold Chain Zips"
    },
    BILLING_DASHBOARD: {
        routes: ["billing-dashboard"],
        name: "billing-dashboard",
        moduleId: PLATFORM.moduleName("billing/views/billing-dashboard"),
        title: "Billing Dashboard"
    },
    RATE_CALCULATOR: {
        routes: ["rate-calculator"],
        name: "rate-calculator",
        moduleId: PLATFORM.moduleName("billing/views/rate-calculator"),
        title: "Rate Calculator"
    },
    SHIPPING_DASHBOARD: {
        routes: ["shipping-dashboard"],
        name: "shipping-dashboard",
        moduleId: PLATFORM.moduleName("shipping/views/shipping-dashboard"),
        title: "Shipping Dashboard"
    },  
    SHIPPING_LIST: {
        routes: ["shipping-list"],
        name: "shipping-list",
        moduleId: PLATFORM.moduleName("shipping/views/shipping-list-view"),
        title: "Shipping List"
    },
    ACTIVITY_DETAIL_REPORT: {
        routes: ["activity-detail-report"],
        name: "activity-detail-report",
        moduleId: PLATFORM.moduleName("items/views/activity-detail-report"),
        title: "Activity Detail Report"
    },
    LIST_WINERY_TRANSFERS: {
        routes: ["winery-transfers"],
        name: "list-winery-transfers",
        moduleId: PLATFORM.moduleName("items/views/winery-transfer-list-view"),
        title: "Winery Transfers"
    },
    VIEW_WINERY_TRANSFER: {
        routes: ["winery-transfer"],
        name: "view-winery-transfer",
        moduleId: PLATFORM.moduleName("items/views/winery-transfer-view"),
        title: "Winery Transfer"
    },
    LIST_INVOICES: {
        routes: ["invoices"],
        name: "list-invoices",
        moduleId: PLATFORM.moduleName("billing/views/invoice-list-view"),
        title: "Invoices"
    },
    VIEW_INVOICE: {
        routes: ["invoice"],
        name: "view-invoice",
        moduleId: PLATFORM.moduleName("billing/views/invoice-view"),
        title: "Invoice"
    },
    LIST_CREDIT_MEMOS: {
        routes: ["credit-memos"],
        name: "list-credit-memos",
        moduleId: PLATFORM.moduleName("billing/views/credit-memo-list-view"),
        title: "Credit Memos"
    },
    VIEW_CREDIT_MEMO: {
        routes: ["credit-memo"],
        name: "view-credit-memo",
        moduleId: PLATFORM.moduleName("billing/views/credit-memo-view"),
        title: "Credit Memo"
    },
    RETURNS_DASHBOARD: {
        routes: ["returns-dashboard"],
        name: "returns-dashboard",
        moduleId: PLATFORM.moduleName("returns/views/returns-dashboard"),
        title: "Returns Dashboard"
    },
    LIST_RETURNS: {
        routes: ["returns"],
        name: "returns",
        moduleId: PLATFORM.moduleName("returns/views/list-returns-view"),
        title: "Returns"
    },
    VIEW_RETURN: {
        routes: ["return"],
        name: "return",
        moduleId: PLATFORM.moduleName("returns/views/return-view"),
        title: "Return"
    }
};