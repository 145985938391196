import { IRecordReference, RecordReference } from "shared-models/record-reference";
import { ChangeOrderRequest } from "./change-order-request";
import { Order } from "orders/models/order";

export interface IChangeOrder extends IRecordReference { 
    createdBy?: IRecordReference,
    lastUpdatedByUser?: IRecordReference,
    createDate?: Date, 
    status?: string, 
    order?: RecordReference, 
    wineryOrderNumber?: string, 
    vinfillmentNotes?: string, 
    deniedReasons?: string, 
    changeRequestsTypes?: string, 
    changeRequests?: ChangeOrderRequest[]};
export class ChangeOrder extends RecordReference {
    createdBy?: RecordReference;
    lastUpdatedByUser?: RecordReference;
    createDate?: Date;
    status?: string;  
    order?: RecordReference;
    wineryOrderNumber?: string;
    vinfillmentNotes?: string;
    deniedReasons?: string;
    changeRequestsTypes?: string;
    changeRequests?: ChangeOrderRequest[];

    constructor(data: IChangeOrder) {
        super(data)
        this.createdBy = data.createdBy ? new RecordReference(data.createdBy) : null;
        this.lastUpdatedByUser = data.lastUpdatedByUser ? new RecordReference(data.lastUpdatedByUser) : null;
        this.createDate = data.createDate;
        this.status = data.status || "";
        this.order = data.order ? new Order(data.order) : null;       
        this.wineryOrderNumber = data.wineryOrderNumber;       
        this.vinfillmentNotes = data.vinfillmentNotes || "";
        this.deniedReasons = data.deniedReasons || "";
        this.changeRequestsTypes = data.changeRequestsTypes || "";
        this.changeRequests = (data.changeRequests || []).map((changeRequest)=>new ChangeOrderRequest(changeRequest));
    }

    isPendingApproval(): boolean{
        if(this.status === "Pending Approval"){
            return true;
        }
        return false;
    }

    isDenied(): boolean{
        if(this.status === "Denied"){
            return true;
        }
        return false;
    }

    isApproved(): boolean{
        if(this.status === "Approved"){
            return true;
        }
        return false;
    }

    isCancelled(): boolean{
        if(this.status === "Cancelled"){
            return true;
        }
        return false;
    }

    convertToData(): any{
        let changeRequestsData = this.changeRequests.map((changeRequest) => {
            return changeRequest.convertToData();
        });
        return {
            "winery_order": this.order.id,
            "notes_for_vinfillment": this.vinfillmentNotes,
            "status": this.status,
            "change_requests": changeRequestsData
        }
    }
}