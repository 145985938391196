import { IRecordReference, RecordReference } from "shared-models/record-reference";

export interface ICarrierReference extends IRecordReference{shippingMethods: IShippingMethodReference[]};
export class CarrierReference extends RecordReference{   
    shippingMethods: ShippingMethodReference[]
    constructor(data: ICarrierReference){
        super(data)  
        this.shippingMethods = (data.shippingMethods || []).map((shippingMethod) => new ShippingMethodReference(shippingMethod));      
    }

    hasColdChainShipMethod(): boolean {
        let foundShipMethod = this.getColdChainShipMethod();
        if(foundShipMethod){
            return true;
        }
        return false;
    }

    getColdChainShipMethod(): ShippingMethodReference {
        return this.shippingMethods.find((shipMethod) => shipMethod.isColdChain());
    }
}

export interface IShippingMethodReference extends IRecordReference{};
export class ShippingMethodReference extends RecordReference{   
    constructor(data: IShippingMethodReference){
        super(data)        
    }

    isColdChain(): boolean{
        if(this.name.indexOf("Cold Chain") > -1){
            return true;
        }
        return false;
    }
}