export interface IGetRecordResponse {recordType: string, recordId: number, record: any};
export class GetRecordResponse{
    recordType: string;
    recordId: number;
    record: any;
    
    constructor(data: IGetRecordResponse){
        this.recordType = data.recordType;
        this.recordId = data.recordId;
        this.record = data.record;
    }
}