import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, computedFrom } from "aurelia-framework";
import { ROUTES } from "routes";
import { VIEW_TYPES } from "./shipping-list-view";

@autoinject
export class ShippingDashboard extends StandardView {
    alertNotificationsLoading: boolean;
    kpisLoading: boolean;
    constructor() {
        super(ShippingDashboard.name); 
        this.alertNotificationsLoading = true;
        this.kpisLoading = true;
        this.screenViewUtility.viewName = "";
    }

    async attached() {
        await super.attached();        
    }

    async activate(params, routeData) {
        await super.activate(params, routeData);
        this.screenViewUtility.viewName = "";               
    }

    @computedFrom()
    get listShipmentsRoute(){
        return ROUTES.SHIPPING_LIST.name;
    }

    @computedFrom()
    get inTransitType(){
        return VIEW_TYPES.IN_TRANSIT;
    }

    @computedFrom()
    get exceptionsType(){
        return VIEW_TYPES.EXCEPTIONS;
    }

    @computedFrom()
    get returnedType(){
        return VIEW_TYPES.RETURNS;
    }

    @computedFrom()
    get deliveredType(){
        return VIEW_TYPES.DELIVERED;
    }
}