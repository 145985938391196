import { IRecordReference, RecordReference } from "shared-models/record-reference";

export interface IItemReference extends IRecordReference{productId?: string, winerySku?: string, isInventoryItem?: boolean, isKitItem?: boolean, onHand?: number, available?: number, backordered?: number, committed?: number};
export class ItemReference extends RecordReference{   
    productId?: string;
    winerySku?: string;
    isInventoryItem?: boolean;
    isKitItem?: boolean;
    onHand?: number;
    available?: number;
    backordered?: number;
    committed?: number;
    constructor(data: IItemReference){
        super(data);
        this.productId = data.productId || "";        
        this.winerySku = data.winerySku || "";        
        this.isInventoryItem = data.isInventoryItem || false;        
        this.isKitItem = data.isKitItem || false;        
        this.onHand = data.onHand || 0;        
        this.available = data.available || 0;        
        this.backordered = data.backordered || 0;        
        this.committed = data.committed || 0;        
    }
}

export interface IItemRecord extends IItemReference{}
export class ItemRecord extends ItemReference{
    constructor(data: IItemRecord){
        super(data);        
    }
}