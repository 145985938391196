import { StandardView } from "shared-ui/views/standard-view";
import { bindable, autoinject, computedFrom } from "aurelia-framework";
import { ROUTES } from "routes";
import { ReturnRecord } from "returns/models/return-record";
import { ReturnService } from "returns/services/return-service";

@autoinject
export class ReturnView extends StandardView {
  @bindable
  return: ReturnRecord;

  $viewReturnForm: HTMLElement;

  constructor(private returnService: ReturnService) {
    super(ReturnView.name);
    this.return = null;
  }

  async activate(params, routeData) {
    try {
      let returnId = params["id"];
      if (!returnId) {
        throw "No Return Id Provided.";
      }
      returnId = parseInt(returnId, 10)
      let requestResponses = await Promise.all([
        this.returnService.getRecord(returnId)
      ]);
      this.return = requestResponses[0];
    } catch (err) {
      this.errorMessageUtil.showError(err);
      this.router.navigateToRoute(ROUTES.DASHBOARD.name, {}, { trigger: true, replace: true });
    }
    await super.activate(params, routeData);
  }

  async attached() {
    await super.attached();
  }

  @computedFrom()
  get viewOrderRoute() {
    return ROUTES.VIEW_ORDER.name;
  }

  @computedFrom()
  get viewItemRoute(){
      return ROUTES.VIEW_ITEM.name;
  }

  async clickHandleReturn() {
    this.errorMessageUtil.showErrorMessage("Not Yet Implemented.");    
  }
}