import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, observable } from "aurelia-framework";
import { ROUTES } from "routes";
import { ShippingAddress } from "shipping-addresses/models/shipping-address-record";
import { ShippingAddressService } from "shipping-addresses/services/shipping_address_service";
import { SearchResult } from "shared-models/search-result";
import parse from "papaparse";

@autoinject
export class ShippingAddressListView extends StandardView {

    filteredAddresses: ShippingAddress[];

    currentSearchResult: SearchResult;

    currentPage: number = 1;

    addressListDisabled: boolean = false;

    $downloadCSVLink: HTMLElement;

    $exportLink: HTMLElement;

    @observable
    searchInput: string = "";

    constructor(private shippingAddressService: ShippingAddressService) {
        super(ShippingAddressListView.name);
    }

    async activate(params, routeData) {
        try {
            await this._getAddresses();           
        } catch (err) {
            this.errorMessageUtil.showError(err);
            this.routeToView(ROUTES.DASHBOARD.name);
        }
        await super.activate(params, routeData);
    }

    async attached() {
        await super.attached();
    }

    async _getAddresses() {
        this.currentSearchResult = await this.shippingAddressService.searchRecords({page: this.currentPage, filter: this.searchInput});
        this.filteredAddresses = this.currentSearchResult.records as ShippingAddress[];
    }

    async searchInputChanged(newValue: string, oldValue: string) {
        this.disableAddressList();
        this.currentPage = 1;
        await this._getAddresses();
        this.enableAddressList();
    }

    async clickPrevious() {
        this.disableAddressList();
        if (this.currentPage > 1) {
            this.currentPage--;
        }
        await this._getAddresses();
        this.enableAddressList();
    }

    async clickNext() {
        this.disableAddressList();
        if (this.currentPage < this.currentSearchResult.pageCount) {
            this.currentPage++;
        }
        await this._getAddresses();
        this.enableAddressList();
    }

    disableAddressList() {
        this.addressListDisabled = true;
    }

    enableAddressList() {
        this.addressListDisabled = false;
    }

    async exportAddresses() {
        this.disableView();
        let addresses = [];
        if (this.searchInput) {
            addresses = this.filteredAddresses
        } else {
            addresses = await this.shippingAddressService.getAllRecords();
        }
        
        let addressCSV = this.convertAddressesToCsv(addresses);
        $(this.$exportLink).attr('href', `data:text/csv;charset=utf-8, ${escape(addressCSV)}`);       
        this.$exportLink.click();
        this.enableView();
    }

    convertAddressesToCsv(addresses) {
        return parse.unparse(addresses.map((address) => {
            return {
                "Label Name": address.name,
                "Is Residential": address.isResidential,
                "Attention": address.attention,
                "Addressee": address.addressee,
                "Address1": address.street,
                "Address2": address.street2,
                "City": address.city,
                "State": address.stateName,
                "Country": address.countryName,
                "Zipcode": address.zipcode,
                "Phone": address.phone,
                "Emails": address.emails
            };
        }));
    }

    newAddress(){
        this.routeToView(ROUTES.VIEW_SHIPPING_ADDRESS.name);
    }

    editAddress(shippingAddress){
        this.routeToView(ROUTES.VIEW_SHIPPING_ADDRESS.name, {id: shippingAddress.id, e: "T"});
    }

    newOrder(shippingAddress){
        this.routeToView(ROUTES.CREATE_ORDER.name, { addressid: shippingAddress.id });
    }
}