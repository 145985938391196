import { Logger } from "aurelia-logging";
import { LogManager, Container, computedFrom, autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { ScreenViewUtility } from "shared-ui/utility/screen-view-utility";
import { ErrorMessageUtility } from "shared-ui/error-message/error-message-utility";
import { UserSession } from "user/models/user-session";

@autoinject
export class StandardView {
  protected router: Router;
  protected screenViewUtility?: ScreenViewUtility
  protected errorMessageUtil?: ErrorMessageUtility
  protected userSession?: UserSession
  logger: Logger;

  constructor(className: string) {
    this.logger = LogManager.getLogger(className);
    this.router = Container.instance.get(Router);
    this.screenViewUtility = Container.instance.get(ScreenViewUtility);
    this.errorMessageUtil = Container.instance.get(ErrorMessageUtility);
    this.userSession = Container.instance.get(UserSession);
    this.logger.info('constructed');
  }

  async attached() {
    this.logger.info('attached');
  }

  async activate(params, routeData) {
    this.scrollToTop();
    this.logger.info('activated');
  }

  async routeToView(routeName: string, properties?: any) {
    properties = properties || {};
    this.router.navigateToRoute(
      routeName,
      properties,
      { replace: false, trigger: true }
    );
  }


  disableView() {
    this.screenViewUtility.disableView = true;
  }

  enableView() {
    this.screenViewUtility.disableView = false;
  }

  scrollToTop() {
    window.scrollTo(0,0);
  }

  scrollToBottom() {
    window.scrollTo(0,document.body.scrollHeight);
  }

}