import { autoinject } from "aurelia-framework";
import { ErrorMessageView } from "./error-message-view";
import { Logger } from "aurelia-logging";
import { LogManager } from "aurelia-framework";

@autoinject
export class ErrorMessageUtility {
    
    errorMessageView: ErrorMessageView;
    logger: Logger;

    constructor(){
        this.logger = LogManager.getLogger(ErrorMessageUtility.name);
    }
    
    showErrorMessage(errorMessage: string, errorHeader: string="There seems to be some technical difficulties."){
        this.logger.error(`error :: ${errorMessage}`);
        this.errorMessageView.showError(errorMessage, errorHeader);
    }

    showError(error: Error, errorHeader: string="There seems to be some technical difficulties."){
        let errorToDisplay = error['response'] && error['response']['data'] && error['response']['data']['message'] ? error['response']['data'] : error;
        this.showErrorMessage(errorToDisplay.message, errorHeader);
    }
}