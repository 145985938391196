import { autoinject, bindable, computedFrom } from "aurelia-framework";
import { StandardView } from "shared-ui/views/standard-view";
import { NotificationService } from "notifications/services/notification-service";
import { AlertNotification } from "notifications/models/alert-notification";
import { ScreenViewUtility } from "shared-ui/utility/screen-view-utility";
import { UserSession } from "user/models/user-session";
import { ROUTES } from "routes";

@autoinject
export class SideNavBar extends StandardView {
    @bindable
    showSideBar: boolean;
    $sideBar: any;
    $notificationIcon: any;
    alertNotifications: AlertNotification[];

    constructor(private notificationService: NotificationService) {
        super(SideNavBar.name);
        this.screenViewUtility.disableView = false;
        this.alertNotifications = [];
    }

    async activate(params, routeData) {
        await super.activate(params, routeData);
    }

    async attached() {
        // let promiseResponse = await Promise.all([
        //     this.notificationService.getAlertNotifications(),
        //     super.attached()
        // ]);
        // this.alertNotifications = promiseResponse[0];

        $(this.$sideBar)
            .sidebar({
                context: $('.bottom.segment'),
                returnScroll: true,
                onShow: () => {
                    if (this.hasActiveNotifications) {
                        $(this.$notificationIcon).transition('jiggle');
                    }
                },
                onHidden: () => {
                    this.scrollToTop();
                    this.showSideBar = false;
                },
                onVisible: () => {
                    this.scrollToTop();
                    this.showSideBar = true;
                }
            })
            .sidebar('attach events', '.sidebarpusher');
    }

    async routeToView(routeName: string) {
        $(this.$sideBar).sidebar('hide');
        await super.routeToView(routeName);
      }
    
    async onClickHome() {
        await this.routeToView(ROUTES.DASHBOARD.name);
    }

    async onClickNotifications() {
        await this.routeToView(ROUTES.NAVIGATION.name);        
    }

    async onClickNewOrder() {
        await this.routeToView(ROUTES.CREATE_ORDER.name);        
    }

    async onClickImportOrders() {
        await this.routeToView(ROUTES.VIEW_IMPORT_ORDERS.name);        
    }

    async onClickViewOrders() {
        await this.routeToView(ROUTES.ORDER_LIST_DASHBOARD.name);        
    }

    async onClickOrderBatches() {
        await this.routeToView(ROUTES.LIST_ORDER_BATCHES.name);
    }

    async onClickChangeOrders() {
        await this.routeToView(ROUTES.LIST_CHANGE_ORDER.name);
    }

    async onClickShippingStatus() {
        this.errorMessageUtil.showErrorMessage("Not Yet Implemented.");    
    }

    async onClickRates() {
        this.errorMessageUtil.showErrorMessage("Not Yet Implemented.");    
    }

    async onClickItems() {
        await this.routeToView(ROUTES.ITEMS_DASHBOARD.name);
    }

    async onClickReports() {
        this.errorMessageUtil.showErrorMessage("Not Yet Implemented.");  
    }

    async onClickColdChain() {
        await this.routeToView(ROUTES.COLD_CHAIN_DASHBOARD.name);    
    }

    async onClickReturns() {
        return this.routeToView(ROUTES.RETURNS_DASHBOARD.name);    
    }

    async onClickAddressBook() {
        await this.routeToView(ROUTES.LIST_SHIPPING_ADDRESSES.name);
    }

    async onClickLogout() {
        await this.userSession.logout();
    }



    @computedFrom('alertNotifications')
    get hasActiveNotifications(): boolean {
        return this.alertNotifications.length > 0;
    }

    @computedFrom('userSession.user.name')
    get userName(): string {
        if(!this.userSession.user){
            return "";
        }
        return this.userSession.user.name;
    }
}