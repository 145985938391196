import { StandardView } from "shared-ui/views/standard-view";
import { bindable, autoinject, computedFrom, observable, PLATFORM } from "aurelia-framework";
import { ROUTES } from "routes";
import { ChangeOrderService } from "change-orders/services/change_order_service";
import { OrderService } from "orders/services/order_service";
import { Order } from "orders/models/order";
import { ChangeOrder } from "change-orders/models/change-order";
import { ChangeOrderType, ChangeOrderRequest } from "change-orders/models/change-order-request";

// Need to add for each change order request view type
PLATFORM.moduleName("change-orders/views/change-order-standard-task");
PLATFORM.moduleName("change-orders/views/change-order-ship-method");
PLATFORM.moduleName("change-orders/views/change-order-ship-instructions");
PLATFORM.moduleName("change-orders/views/change-order-ship-date");
PLATFORM.moduleName("change-orders/views/change-order-items");
PLATFORM.moduleName("change-orders/views/change-order-address");
PLATFORM.moduleName("change-orders/views/change-order-email");
PLATFORM.moduleName("change-orders/views/change-order-gift-message");

@autoinject
export class ChangeOrderView extends StandardView {
  @bindable
  changeOrder: ChangeOrder = null;

  $viewForm: HTMLElement;

  $createForm: HTMLElement;

  $orderDropdown: HTMLElement;

  $changesDropdown: HTMLElement;

  changeOrderId: number;

  orderId: number;

  order: Order;

  formDisabled: boolean = false;

  changeTypes: ChangeOrderType[] = [];

  pendingChangeOrder: ChangeOrder = null;

  @observable
  changeRequests: ChangeOrderRequest[] = [];

  @observable
  isCreating: boolean = false;

  constructor(private changeOrderService: ChangeOrderService, private orderService: OrderService) {
    super(ChangeOrderView.name);
  }

  async activate(params, routeData) {
    try {
      this.changeOrderId = params["id"] ? parseInt(params["id"]) : null;
      this.orderId = params["orderid"] ? parseInt(params["orderid"]) : null;     
      this.formDisabled = false;
      this.changeRequests = [];
      if(this.changeOrderId){
        this.isCreating = false;
        this.changeOrder = await this.changeOrderService.getRecord(this.changeOrderId);
        this.changeRequests = this.changeOrder.changeRequests;
      } else{
        this.changeOrder = new ChangeOrder({});
        this.changeTypes = await this.changeOrderService.getChangeOrderTypes();
        this.isCreating = true;
      } 
      if(this.orderId){          
        this.order = await this.orderService.getRecord(this.orderId);
        this.changeOrder.order = this.order;
      }     
      this._setupForms();
    } catch (err) {
      this.errorMessageUtil.showError(err);
      this.routeToView(ROUTES.DASHBOARD.name);
    }
    await super.activate(params, routeData);
  }

  async attached() {
    if(this.isCreating){
      this._setupFields();
    }    
    this._setupForms();
    await super.attached();
  }

  _setupFields() {
    $(this.$orderDropdown).dropdown({
      apiSettings: {
        responseAsync: async (settings, callback) => {
          let searchResponse = await this.orderService.searchRecords({page: 1, filter: settings["urlData"]["query"]});
          let orders = searchResponse.records.map((orderRecord) => {
            return {
              "name": orderRecord.wineryOrderNumber,
              "value": orderRecord.id,
              "text": orderRecord.wineryOrderNumber
            };
          });
          callback({
            "success": true,
            "results": orders
          });
        },
      },
      onChange: async (value, text, $selectedItem) => {
        this._disableForm();
        try{
          this.changeRequests = [];
          this.pendingChangeOrder = null;
          $(this.$changesDropdown).dropdown('clear');
          if (value) {
            let orderChangeOrders = await this.changeOrderService.getChangeOrdersByOrder(value);
            this.pendingChangeOrder = this._getPendingChangeOrder(orderChangeOrders);
            this.order = await this.orderService.getRecord(value);            
            this.changeOrder.order = this.order;
          } else {
            this.order = null;
          }
        } catch(err){
          this.errorMessageUtil.showError(err);
        }     
        this._enableForm();
      }
    });
    if(this.order){
      $(this.$orderDropdown).dropdown('set value', this.order.id);
      $(this.$orderDropdown).dropdown('set text', this.order.wineryOrderNumber);
    }

    $(this.$changesDropdown).dropdown({
      onChange: async (value, text, $selectedItem) => {
        this._disableForm();
        if(value){
          let newChangeTypeIds = value.split(",");
          if(newChangeTypeIds.length > this.changeRequests.length){ //ADDED
            var newChangeType = newChangeTypeIds
              .filter(newChangeTypeId => !this.changeRequests.find((changeRequest)=> changeRequest.changeOrderType.id == newChangeTypeId))
              .map((changeTypeId) => this.changeTypes.find((changeType)=>changeType.id == changeTypeId))[0] as ChangeOrderType;              
            this.changeRequests.push(newChangeType.getNewRequest(this.order));
          } else if(newChangeTypeIds.length < this.changeRequests.length){ //REMOVED
            this.changeRequests = this.changeRequests.filter(changeType => newChangeTypeIds.includes(changeType.changeOrderType.id.toString()));
          }          
        } else{
          this.changeRequests = [];
        }                
        this._enableForm();
      }
    });
  }

  _getPendingChangeOrder(changeOrders: ChangeOrder[]): ChangeOrder {
    let pendingChangeOrder = null;
    changeOrders.forEach((changeOrder) => {
      if (changeOrder.status == "Pending Approval") {
        pendingChangeOrder = changeOrder;
      }
    });
    return pendingChangeOrder;
  }

  _setupForms(){
    if(this.isCreating){
      this._showCreateForm();
    } else{
      this._showViewForm();
    }
  }

  _showCreateForm(){
    $(this.$viewForm).hide();
    $(this.$createForm).show();
  }

  _showViewForm(){
    $(this.$createForm).hide();
    $(this.$viewForm).show();
  }

  _disableForm() {
    this.formDisabled = true;
  }

  _enableForm() {
    this.formDisabled = false;
  }

  _getValidationErrors(): string[] {
    let validationErrors = [];
    if(this.changeRequests.length === 0){
      validationErrors.push("There needs to be at least 1 change to the order.")
    }
    this.changeRequests.forEach((changeRequest) => {
      validationErrors = validationErrors.concat(changeRequest.getValidationErrors());
    });
    return validationErrors;
  }

  @computedFrom('isCreating')
  get formTitle(): string{
    return this.isCreating ? `New Change Order` : `Change Order`;
  }

  @computedFrom('pendingChangeOrder', 'order')
  get disableSave(): boolean{
    return !!this.pendingChangeOrder || !this.order;
  }

  @computedFrom('changeOrder.status')
  get statusColor(): string{
    if(this.changeOrder.isDenied()){
      return "red";
    }
    if(this.changeOrder.isApproved()){
      return "green";
    }
    if(this.changeOrder.isCancelled()){
      return "orange";
    }
    return "blue";
  }

  @computedFrom('changeOrder.status')
  get isDenied(): boolean{
    return this.changeOrder.status === "Denied";
  }

  @computedFrom('changeTypes.length', 'changeRequests.length', 'order.status')
  get filteredChangeTypes(): ChangeOrderType[]{
    return this.changeTypes.filter((changeType)=> this.changeTypeAllowed(changeType));
  }

  @computedFrom('order', 'isCreating', 'pendingChangeOrder')
  get showChangeDetails(): boolean{
    return !!this.order && this.isCreating && !this.pendingChangeOrder;
  }

  get canCancel(): boolean{
    return !this.isCreating && this.changeOrder.isPendingApproval();
  }

  changeTypeAllowed(changeType: ChangeOrderType): boolean{
    if(!this.order){
      return false;
    }
    let singleChangeItem = this.getSingleChangeItem();
    return changeType.isAllowedForOrder(this.order) && (!singleChangeItem || (singleChangeItem.id === changeType.id)) && (!changeType.doNotAllowOtherChanges || this.changeRequests.length === 0);
  }

  getSingleChangeItem(): ChangeOrderType{
    let singleChargeRequest = this.changeRequests.find((changeRequest)=> changeRequest.changeOrderType.doNotAllowOtherChanges);
    if(singleChargeRequest){
      return singleChargeRequest.changeOrderType;
    }
    return null;
  }  
  
  async createChangeOrder() {
    this.scrollToTop();
    this.disableView();
    try{   
      let validationErrors = this._getValidationErrors();
      if(validationErrors.length > 0){
        this.errorMessageUtil.showErrorMessage(validationErrors.join("\r\n"), "There are issues with the Change Order Request");
      } else{
        this.changeOrder.changeRequests = this.changeRequests;
        let changeOrderId = await this.changeOrderService.createRecord(this.changeOrder);
        this.routeToView(ROUTES.VIEW_CHANGE_ORDER.name, { id: changeOrderId });
      }      
    } catch(err){
      this.errorMessageUtil.showError(err, "Unable to create Change order Request");
    }    
    this.enableView();
  }

  async viewOrder(){
    this.scrollToTop();
    this.disableView();
    try{   
      this.routeToView(ROUTES.VIEW_ORDER.name, { id: this.changeOrder.order.id });  
    } catch(err){
      this.errorMessageUtil.showErrorMessage("Unable to open the order.");
    }    
    this.enableView();
  }

  async cancelChangeOrder(){
    this.scrollToTop();
    this.disableView();
    try{         
      await this.changeOrderService.cancelChangeOrder(this.changeOrder);
      this.routeToView(ROUTES.VIEW_CHANGE_ORDER.name, { id: this.changeOrder.id }); 
    } catch(err){
      this.errorMessageUtil.showError(err, "Unable to cancel the Change Order.");
    }    
    this.enableView();
  }

}