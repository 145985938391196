import { StandardView } from "shared-ui/views/standard-view";
import { autoinject } from "aurelia-framework";
import { ChangeOrderItemsRequest, ChangeOrderNewItem } from "change-orders/models/change-order-request";
import { ItemReference } from "items/models/item-record";
import { ItemService } from "items/services/item_service";

@autoinject
export class ChangeOrderAddItemsView extends StandardView {

    changeRequest: ChangeOrderItemsRequest;

    $itemForm: HTMLElement;

    $addItemModal: HTMLElement;

    $itemDropdown: HTMLElement;

    currentItemReference: ItemReference;

    currentItemQuantity: number;
  
    currentOrderLineNumber: number = 0;

    constructor(private itemService: ItemService) {
        super(ChangeOrderAddItemsView.name);
    }

    async activate(params, routeData) {
        this.changeRequest = params;
        await super.activate(params, routeData);
    }

    async attached() {
        if(!this.changeRequest.id){
            await this._setupFields();
            await this._setupForms();
        }
        await super.attached();
    }

    async _setupFields() {
        await $('.ui.checkbox').checkbox();

        await $(this.$itemDropdown).dropdown({
            apiSettings: {
              responseAsync: async (settings, callback) => {
                let searchResponse = await this.itemService.searchRecords({page: 1, filter: settings["urlData"]["query"]});
                let items = searchResponse.records.map((itemRecord) => {
                  return {
                    "name": itemRecord.name,
                    "value": itemRecord.id,
                    "text": itemRecord.name
                  };
                })
                callback({
                  "success": true,
                  "results": items
                });
              },
            },
            onChange: async (value, text, $selectedItem) => {
              this.disableView();
              if (value) {
                this.currentItemReference = await this.itemService.getRecord(value);
              } else {
                this.currentItemReference = null;
              }
              this.enableView();
            }
          })
    }

    async _setupForms() {
        await $(this.$itemForm).form({
            on: 'blur',
            inline: true,
            dateHandling: 'date',
            onSuccess: (event: JQuery.TriggeredEvent<HTMLElement>, fields: any) => {
                this.addNewOrderItem();
            },
            onFailure: (formErrors: string[], fields: any) => {
                $(this.$itemForm).form('add errors', formErrors);
                this.enableView();
            },
            fields: {
                "item": {
                    identifier: 'item',
                    rules: [
                        {
                            type: 'empty',
                            prompt: 'Need To Provide an Item'
                        }
                    ]
                },
                "quantity": {
                    identifier: 'quantity',
                    rules: [
                        {
                            type: 'integer[1..1000000]',
                            prompt: 'Need To Provide a positive quantity'
                        }
                    ]
                }
            }
        });
    }

    async addItem() {
        this.currentItemReference = null;
        this.currentItemQuantity = null;
        await $(this.$itemDropdown).dropdown('clear');
        $(this.$addItemModal).modal('show');
      }

    async clickAddNewOrderItem() {
        this.disableView();
        $(this.$itemForm).form('validate form');
    }

    async addNewOrderItem() {
        this.disableView();
        this.changeRequest.newItems.push(new ChangeOrderNewItem({
            item: this.currentItemReference,
            quantity: this.currentItemQuantity,
            lineNumber: this.currentOrderLineNumber
        }));
        this.currentOrderLineNumber++;
        this.currentItemReference = null;
        this.currentItemQuantity = null;
        $(this.$addItemModal).modal('hide');
        this.enableView();
    }

    async removeOrderItem(orderLineToRemove) {
        this.changeRequest.newItems = this.changeRequest.newItems.filter((orderLine) => orderLine.lineNumber != orderLineToRemove.lineNumber);
    }
}