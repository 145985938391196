import { StandardView } from "shared-ui/views/standard-view";
import { bindable, autoinject, observable, computedFrom } from "aurelia-framework";
import { ROUTES } from "routes";
import { ItemReference } from "items/models/item-record";
import { ItemService } from "items/services/item_service";
import parse from 'papaparse';
import { SearchResult } from "shared-models/search-result";

export enum VIEW_TYPES {
    ALL = "all",
    BACKORDERED = "backordered",
    ON_HAND = "on-hand",
    COMMITTED = "committed"
}

@autoinject
export class ListItemsView extends StandardView {
    
    filteredItems: ItemReference[] = [];

    itemListDisabled: boolean = false;

    @observable
    searchInput: string;

    currentSearchResult: SearchResult;
    
    currentPage: number = 1;

    $exportLink: HTMLElement;

    listType: string;

    constructor(private itemService: ItemService) {
        super(ListItemsView.name);
    }

    async activate(params, routeData) {
        try {          
            this.listType = params["listType"];
            await this._getItems();
        } catch (err) {
            this.errorMessageUtil.showError(err);
            this.router.navigateToRoute(ROUTES.DASHBOARD.name, {}, { trigger: true, replace: true });
        }
        await super.activate(params, routeData);
    }

    async attached() {
        await super.attached();
    }

    async _getItems() {
        let fieldFilters = this._getFilters();        
        this.logger.info(`fieldFilters :: ${JSON.stringify(fieldFilters)}`);
        this.currentSearchResult = await this.itemService.searchRecords({page: this.currentPage, filter: this.searchInput, fieldFilters: fieldFilters});
        this.filteredItems = this.currentSearchResult.records as ItemReference[];
    }

    _getFilters() {
        if(this.listType === VIEW_TYPES.BACKORDERED){
            return this._getBackorderedFilters();
        } 
        if(this.listType === VIEW_TYPES.ON_HAND){
            return this._getOnHandFilters();
        } 
        if(this.listType === VIEW_TYPES.COMMITTED){
            return this._getCommittedFilters();
        }
        return [];
    }

    _getBackorderedFilters(){
        return [['backordered', '>', 0]];
    }

    _getOnHandFilters(){
        return [['on_hand', '>', 0]];
    }

    _getCommittedFilters(){
        return [['committed', '>', 0]];
    }

    disableItemList() {
        this.itemListDisabled = true;
    }

    enableItemList() {
        this.itemListDisabled = false;
    }

    async searchInputChanged(newValue: string, oldValue: string) {
        this.disableItemList()
        this.currentPage = 1;
        await this._getItems(); 
        this.enableItemList()       
    }

    async clickPrevious() {
        this.disableItemList();
        if (this.currentPage > 1) {
            this.currentPage--;
        }
        await this._getItems();
        this.enableItemList();
    }

    async clickNext() {
        this.disableItemList();
        if (this.currentPage < this.currentSearchResult.pageCount) {
            this.currentPage++;
        }
        await this._getItems();
        this.enableItemList();
    }
    
    @computedFrom()
    get viewItemRoute(){
        return ROUTES.VIEW_ITEM.name;
    }

    async exportItems() {
        this.disableView();
        let fieldFilters = this._getFilters();
        let items = [];
        if (this.searchInput) {
            items = this.filteredItems
        } else {
            items = await this.itemService.getAllRecords(fieldFilters);
        }
        let itemsCSV = this.convertItemsToCsv(items);
        $(this.$exportLink).attr('href', `data:text/csv;charset=utf-8, ${escape(itemsCSV)}`);       
        this.$exportLink.click(); 
        this.enableView();
    }

    convertItemsToCsv(items) {
        return parse.unparse(items.map((item) => {
            return {
                "Name" : item.name,
                "Sku" : item.winerySku,
                "On Hand" : item.onHand,
                "Available" : item.available,
                "Committed" : item.committed,
                "Backordered" : item.backordered
            };
        }));
    }


}