export interface IUpdateRecordRequest {recordType: string, recordId: number, recordData: any};
export class UpdateRecordRequest{
    recordType: string;
    recordId: number;
    recordData: any;
    
    constructor(data: IUpdateRecordRequest){
        this.recordType = data.recordType;
        this.recordId = data.recordId;
        this.recordData = data.recordData;
    }
}