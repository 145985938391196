import { ShippingMethodReference } from "orders/models/carrier-record";
import { IRecordReference, RecordReference } from "shared-models/record-reference";

export interface IBillingRate extends IRecordReference{
    isDefaultRate?: boolean,
    startDate?: Date,
    endDate?: Date,
    bottleSizeIds?: number[],
    bottleSizeNames?: string[],
    wineryIds?: number[],
    wineryNames?: string[],
    zone?: RecordReference,
    shippingMethodIds?: number[],
    shippingMethodNames?: string[],
    quantity?: number,
    charge?: number};

export class BillingRate extends RecordReference{    
    isDefaultRate: boolean;
    startDate: Date;
    endDate: Date;
    bottleSizeIds: number[];
    bottleSizeNames?: string[];
    wineryIds: number[];
    wineryNames?: string[];
    zone: RecordReference;
    shippingMethodIds: number[];
    shippingMethodNames?: string[];
    quantity: number;
    charge: number;
    constructor(data: IBillingRate){
        data = data || {}
        super(data);
        this.isDefaultRate = data.isDefaultRate || false;        
        this.startDate = data.startDate;        
        this.endDate = data.endDate;     
        this.bottleSizeIds = (data.bottleSizeIds || []).map((bottleSizeId) => typeof bottleSizeId === "string" ? parseInt(bottleSizeId, 10): bottleSizeId);  
        this.bottleSizeNames = data.bottleSizeNames || []; 
        this.wineryIds = (data.wineryIds || []).map((wineryId) => typeof wineryId === "string" ? parseInt(wineryId, 10): wineryId);   
        this.wineryNames = data.wineryNames || [];
        this.zone = data.zone ? new RecordReference(data.zone): null;
        this.shippingMethodIds = (data.shippingMethodIds || []).map((shippingMethodId) => typeof shippingMethodId === "string" ? parseInt(shippingMethodId, 10): shippingMethodId);
        this.shippingMethodNames = data.shippingMethodNames || [];
        this.quantity = data.quantity || 0;   
        this.charge = data.charge || 0;   
    }    
}

export function getMatchingRates(billingRates: BillingRate[], zone: RecordReference, shippingMethod: ShippingMethodReference): BillingRate[]{
    if(!zone){
        return billingRates.filter((rate)=> true);
    }
    return billingRates.filter((rate)=> rate.zone.id == zone.id && (!shippingMethod || rate.shippingMethodIds.indexOf(shippingMethod.id) > -1));
}