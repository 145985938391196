import { autoinject } from "aurelia-framework";
import { DefaultService } from "shared-service/default-service";
import { SearchService } from "shared-service/search-service";
import { SearchRequest } from "shared-models/search-request";
import { SearchResult } from "shared-models/search-result";
import { GetRecordsService } from "shared-service/get-records-service";
import { GetRecordService } from "shared-service/get-record-service";
import { BillingRate } from "billing/models/billing-rate";
import { ZoneRange } from "billing/models/zones";
import { GetRecordsRequest } from "shared-models/get-records-request";
import { MatchedRate, RateCalculatorRequest, RateCalculatorResponse } from "billing/models/rate-calculator";
import { Invoice, InvoiceItem } from "billing/models/invoice";
import { GetRecordRequest } from "shared-models/get-record-request";
import { CreditMemo } from "billing/models/credit-memo";

@autoinject
export class BillingService extends DefaultService{
    private static readonly INVOICE_RECORD_TYPE = "account.move";
    private static readonly CREDIT_MEMO_RECORD_TYPE = "vf_credit_memo";
    private static readonly BILLING_RATE_TYPE = "vf_billing_rate";
    private static readonly ZONE_RANGE_TYPE = "vf_zone_range";
    private static readonly RATE_CALCULATOR_TYPE = "vf_rate_calculator";

    constructor(private searchService: SearchService, private getRecordService: GetRecordService, private getRecordsService: GetRecordsService){
        super(BillingService.name);        
    }

    async searchInvoices(searchRequestData: {page?: number, pageSize?: number, filter?: string, fieldFilters?: any[]}): Promise<SearchResult>{
        let searchRequest = new SearchRequest({
            recordType: BillingService.INVOICE_RECORD_TYPE,
            pageSize: searchRequestData.pageSize || 50,
            page: searchRequestData.page || 1,
            filter: searchRequestData.filter || "",
            fieldFilters: searchRequestData.fieldFilters || []
        });
        return await this.searchService.searchRecords(searchRequest, this._convertResponseToInvoice)
    }

    async getAllInvoices(fieldFilters: any[]=[]): Promise<Invoice[]>{
        let allRecords = [] as Invoice[];
        let page = 1;
        let pageSize = 5000;
        let initialSearch = await this.searchInvoices({page: page, pageSize: pageSize, fieldFilters: fieldFilters});
        allRecords = allRecords.concat(initialSearch.records as Invoice[]);
        var totalPageCount = initialSearch.pageCount;
        if(totalPageCount > 1){
            while(page < totalPageCount){
                page++;
                let nextSearch = await this.searchInvoices({page: page, pageSize: pageSize, fieldFilters: fieldFilters});
                allRecords = allRecords.concat(nextSearch.records as Invoice[]);
            }           
        }        
        return allRecords;
    }

    async getInvoice(id: number): Promise<Invoice>{
        let getRecordRequest = new GetRecordRequest({
            recordType: BillingService.INVOICE_RECORD_TYPE,
            recordId: id
        });
        let getRecordResponse = await this.getRecordService.getRecord(getRecordRequest, this._convertResponseToInvoice, this);
        return getRecordResponse.record;
    }

    async searchCreditMemos(searchRequestData: {page?: number, pageSize?: number, filter?: string, fieldFilters?: any[]}): Promise<SearchResult>{
        let searchRequest = new SearchRequest({
            recordType: BillingService.CREDIT_MEMO_RECORD_TYPE,
            pageSize: searchRequestData.pageSize || 50,
            page: searchRequestData.page || 1,
            filter: searchRequestData.filter || "",
            fieldFilters: searchRequestData.fieldFilters || []
        });
        return await this.searchService.searchRecords(searchRequest, this._convertResponseToCreditmemo)
    }

    async getAllCreditMemos(fieldFilters: any[]=[]): Promise<CreditMemo[]>{
        let allRecords = [] as CreditMemo[];
        let page = 1;
        let pageSize = 5000;
        let initialSearch = await this.searchInvoices({page: page, pageSize: pageSize, fieldFilters: fieldFilters});
        allRecords = allRecords.concat(initialSearch.records as CreditMemo[]);
        var totalPageCount = initialSearch.pageCount;
        if(totalPageCount > 1){
            while(page < totalPageCount){
                page++;
                let nextSearch = await this.searchInvoices({page: page, pageSize: pageSize, fieldFilters: fieldFilters});
                allRecords = allRecords.concat(nextSearch.records as CreditMemo[]);
            }           
        }        
        return allRecords;
    }

    async getCreditMemo(id: number): Promise<CreditMemo>{
        let getRecordRequest = new GetRecordRequest({
            recordType: BillingService.CREDIT_MEMO_RECORD_TYPE,
            recordId: id
        });
        let getRecordResponse = await this.getRecordService.getRecord(getRecordRequest, this._convertResponseToCreditmemo, this);
        return getRecordResponse.record;
    }

    async getRateCalculatorResponse(request: RateCalculatorRequest): Promise<RateCalculatorResponse[]>{
        let getRecordsRequest = new GetRecordsRequest({
            recordType: BillingService.RATE_CALCULATOR_TYPE,
            additionalFields: request
        });
        let getRecordsResponse = await this.getRecordsService.getRecords(getRecordsRequest, this._convertResponseToRateCalculator);
        return getRecordsResponse.records;
    }

    async searchBillingRates(page: number, filter: string, pageSize:number = 50): Promise<SearchResult>{
        page = page || 1;
        filter = filter || "";
        let searchRequest = new SearchRequest({
            recordType: BillingService.BILLING_RATE_TYPE,
            pageSize: pageSize,
            page: page,
            filter: filter
        });
        return await this.searchService.searchRecords(searchRequest, this._convertResponseToBillingRate);
    }

    async searchZoneRanges(page: number, filter: string, pageSize:number = 50): Promise<SearchResult>{
        page = page || 1;
        filter = filter || "";
        let searchRequest = new SearchRequest({
            recordType: BillingService.ZONE_RANGE_TYPE,
            pageSize: pageSize,
            page: page,
            filter: filter
        });
        return await this.searchService.searchRecords(searchRequest, this._convertResponseToZoneRange);
    }

    async getAllBillingRates(): Promise<BillingRate[]>{
        let allBillingRates = [];
        let page = 1;
        let pageSize = 5000;
        let initialSearch = await this.searchBillingRates(page, "", pageSize);
        allBillingRates = allBillingRates.concat(initialSearch.records as BillingRate[]);
        var totalPageCount = initialSearch.pageCount;
        if(totalPageCount > 1){
            while(page < totalPageCount){                
                page++;
                let nextSearch = await this.searchBillingRates(page, "", pageSize);
                allBillingRates = allBillingRates.concat(nextSearch.records as BillingRate[]);             
            }           
        }        
        return allBillingRates;
    }

    async getAllZoneRanges(): Promise<ZoneRange[]>{
        let allBillingRates = [];
        let page = 1;
        let pageSize = 5000;
        let initialSearch = await this.searchZoneRanges(page, "", pageSize);
        allBillingRates = allBillingRates.concat(initialSearch.records as ZoneRange[]);
        var totalPageCount = initialSearch.pageCount;
        if(totalPageCount > 1){
            while(page < totalPageCount){                
                page++;
                let nextSearch = await this.searchZoneRanges(page, "", pageSize);
                allBillingRates = allBillingRates.concat(nextSearch.records as ZoneRange[]);             
            }           
        }        
        return allBillingRates;
    }

    _convertResponseToRateCalculator(response: any): RateCalculatorResponse{
        return new RateCalculatorResponse({           
            matchedRates: (response["matched_rates"] || []).map((matchedRate) =>{
                return new MatchedRate({
                    billingRate: matchedRate["billing_rate"],
                    quantity: matchedRate["quantity"]
                });
            }),
            shippingMethods: (response["shipping_methods"] || "").split(","),
            totalCharge: response["total_charge"]            
        });
    }

    _convertResponseToBillingRate(response: any): BillingRate{
        return new BillingRate({
            id: response["id"],
            name: response["name"],
            isDefaultRate: response["is_default_rate"],
            startDate: response["start_date"],
            endDate: response["end_date"],
            bottleSizeIds: response["bottle_sizes"],
            bottleSizeNames: (response["bottle_size_names"] || "").split(","),
            wineryIds: response["wineries"],
            wineryNames: (response["winery_names"] || "").split(","),
            zone: response["zone"],
            shippingMethodIds: response["shipping_methods"],
            shippingMethodNames: (response["shipping_method_names"] || "").split(","),
            quantity: response["quantity"],
            charge: response["charge"]
            
        });
    }

    _convertResponseToZoneRange(response: any): ZoneRange{
        return new ZoneRange({
            id: response["id"],
            name: response["name"],
            billingZone: response["billing_zone"],
            carriers: response["carriers"],
            country: response["country"],
            state: response["state"],
            zipStart: response["zip_start"],
            zipEnd: response["zip_end"]
            
        });
    }

    _convertResponseToInvoice(response): Invoice{
        let invoice = new Invoice({
            id: response["id"],
            name: response["name"],
            date: response["invoice_date"],
            dueDate: response["invoice_date_due"],
            amountTotal: response["amount_total"],
            amountDue: response["amount_residual"],
            reference: response["ref"],
            paymentState: response["invoice_payment_state"],
            term: response["invoice_payment_term_id"],
            inboundFileName: response["inbound_export_name"],
            inboundFile: atob(response["inbound_export"]),
            outboundFileName: response["outbound_export_name"],
            outboundFile: atob(response["outbound_export"]),
            storageFileName: response["storage_export_name"],
            storageFile: atob(response["storage_export"]),
            addressCorrFileName: response["address_corr_export_name"],
            addressCorrFile: atob(response["address_corr_export"]),
            returnsFileName: response["returns_export_name"],
            returnsFile: atob(response["returns_export"]),       
        });
        invoice.items = (response["items"] || []).map((item) => new InvoiceItem({
            id: item["id"],
            name: item["id"],
            itemId: item["product_id"]["id"],
            itemName: item["product_id"]["name"],
            quantity: item["quantity"],
            totalCharges: item["price_unit"]
        }));
        return invoice;
    }

    _convertResponseToCreditmemo(response): CreditMemo {
        let creditMemo = new CreditMemo({
            id: response["id"],
            name: response["name"],
            createdDate: response["created_date"],
            approvedDate: response["approved_date"],
            deniedDate: response["denied_date"],
            status: response["status"],
            refundType: response["refund_type"],
            wineryOrder: response["winery_order"],
            recipientName: response["recipient_name"],
            wineryOrderNumber: response["winery_order_number"],               
            vfOrderNumber: response["vf_order_number"],               
            trackingNumbers: response["tracking_numbers"],               
            checkOrInvoiceNumber: response["check_or_invoice_num"],               
            vfInvoiceNumber: response["vinfillment_invoice_num"],               
            notes: response["notes"],               
            creditAmount: response["credit_amount"],
        });
        return creditMemo;
    }
}