import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, computedFrom } from "aurelia-framework";
import { ROUTES } from "routes";
import {VIEW_TYPES} from "items/views/list-items-view"

@autoinject
export class ItemsDashboard extends StandardView {
    alertNotificationsLoading: boolean;
    kpisLoading: boolean;
    constructor() {
        super(ItemsDashboard.name); 
        this.alertNotificationsLoading = true;
        this.kpisLoading = true;
        this.screenViewUtility.viewName = "";
    }

    async attached() {
        await super.attached();        
    }

    async activate(params, routeData) {
        await super.activate(params, routeData);
        this.screenViewUtility.viewName = "";               
    }   

    @computedFrom()
    get allListType(){
        return  VIEW_TYPES.ALL;
    }

    @computedFrom()
    get backorderedType(){
        return  VIEW_TYPES.BACKORDERED;
    }

    @computedFrom()
    get committedType(){
        return  VIEW_TYPES.COMMITTED;
    }

    @computedFrom()
    get onHandType(){
        return  VIEW_TYPES.ON_HAND;
    }

    @computedFrom()
    get allItemsRoute(){
        return ROUTES.LIST_ITEMS.name;
    }

    @computedFrom()
    get activityDetailRoute(){
        return ROUTES.ACTIVITY_DETAIL_REPORT.name;
    }

    @computedFrom()
    get transfersRoute(){
        return ROUTES.LIST_WINERY_TRANSFERS.name;
    }
}