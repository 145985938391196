import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, computedFrom } from "aurelia-framework";
import { ROUTES } from "routes";
import { VIEW_TYPES } from "returns/views/list-returns-view";

@autoinject
export class ReturnsDashboard extends StandardView {
    alertNotificationsLoading: boolean;
    kpisLoading: boolean;
    constructor() {
        super(ReturnsDashboard.name);
        this.alertNotificationsLoading = true;
        this.kpisLoading = true;
        this.screenViewUtility.viewName = "";
    }

    async attached() {
        await super.attached();        
    }

    async activate(params, routeData) {
        await super.activate(params, routeData);
        this.screenViewUtility.viewName = "";               
    }

    @computedFrom()
    get pendingReturnsViewType(){
        return VIEW_TYPES.PENDING;
    }

    @computedFrom()
    get allReturnsViewType(){
        return VIEW_TYPES.ALL;
    }

    @computedFrom()
    get listReturnsRoute(){
        return ROUTES.LIST_RETURNS.name;
    }
}