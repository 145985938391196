import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, observable } from "aurelia-framework";
import { ROUTES } from "routes";
import { SearchResult } from "shared-models/search-result";
import { ColdChainZip } from "cold-chain/models/cold-chain-zip";
import { ColdChainService } from "cold-chain/services/cold-chain-service";
import parse from "papaparse";

@autoinject
export class ColdChainZipsView extends StandardView {

    coldChainZips: ColdChainZip[];

    currentSearchResult: SearchResult;

    currentPage: number = 1;

    listDisabled: boolean = false;    

    $downloadCSVLink: HTMLElement;

    $exportLink: HTMLElement;

    @observable
    searchInput: string = "";

    constructor(private coldChainService: ColdChainService) {
        super(ColdChainZipsView.name);
    }

    async activate(params, routeData) {
        try {
            await this._getResults();           
        } catch (err) {
            this.errorMessageUtil.showError(err);
            this.routeToView(ROUTES.DASHBOARD.name);
        }
        await super.activate(params, routeData);
    }

    async attached() {
        await super.attached();
    }

    async _getResults() {
        this.currentSearchResult = await this.coldChainService.searchColdChainZips(this.currentPage, this.searchInput);
        this.coldChainZips = this.currentSearchResult.records as ColdChainZip[];
    }

    async searchInputChanged(newValue: string, oldValue: string) {
        this.disableList();
        this.currentPage = 1;
        await this._getResults();
        this.enableList();
    }

    async clickPrevious() {
        this.disableList();
        if (this.currentPage > 1) {
            this.currentPage--;
        }
        await this._getResults();
        this.enableList();
    }

    async clickNext() {
        this.disableList();
        if (this.currentPage < this.currentSearchResult.pageCount) {
            this.currentPage++;
        }
        await this._getResults();
        this.enableList();
    }

    async exportZips() {
        this.disableView();
        let coldChainZips = [];
        if (this.searchInput) {
            coldChainZips = this.coldChainZips
        } else {
            coldChainZips = await this.coldChainService.getAllColdChainZips();
        }
        
        let coldChainZipsCSV = this.convertColdChainZipsToCsv(coldChainZips);
        $(this.$exportLink).attr('href', `data:text/csv;charset=utf-8, ${escape(coldChainZipsCSV)}`);       
        this.$exportLink.click();
        this.enableView();
    }

    convertColdChainZipsToCsv(coldChainZips) {
        return parse.unparse(coldChainZips.map((coldChainZip) => {
            return {
                "Zipcode": coldChainZip.zipcode,
                "Zone": coldChainZip.zone.name,
                "City": coldChainZip.city,
                "State": coldChainZip.state.name,
                "Delivery Day": coldChainZip.deliveryDay.name,
                "Delivery Time": coldChainZip.deliveryTime,
                "Hub": coldChainZip.hub,
                "Route": coldChainZip.route
            };
        }));
    }

    disableList() {
        this.listDisabled = true;
    }

    enableList() {
        this.listDisabled = false;
    }
}