import { autoinject } from "aurelia-framework";
import { DefaultService } from "shared-service/default-service";
import { CreateRecordRequest } from "shared-models/create-record-request";

@autoinject
export class CreateRecordService extends DefaultService{
    private static readonly URI = "create-record";

    constructor(){
        super(CreateRecordService.name);
    }

    async createRecord(getRecordRequest: CreateRecordRequest): Promise<number>{
        return await this.post(CreateRecordService.URI, getRecordRequest);
    }
}