import { StandardView } from "shared-ui/views/standard-view";
import { autoinject } from "aurelia-framework";
import { NotificationService } from "notifications/services/notification-service";
import { AlertNotification, AlertNotificationSelection, AlertNotificationRead } from "notifications/models/alert-notification";
import { computedFrom } from "aurelia-binding";
import { UserSession } from "user/models/user-session";

enum VIEW_TYPE {
    CURRENT_ALERTS,
    READ_ALERTS
}

@autoinject
export class AlertNotificationsBookView extends StandardView {
    currentAlertNotifications: AlertNotificationSelection[];
    readAlertNotifications: AlertNotificationSelection[];
    viewType: VIEW_TYPE;
    selectAll: boolean;
    $currentItem: any;
    $readItem: any;

    constructor(private notificationService: NotificationService) {
        super(AlertNotificationsBookView.name);
        this.currentAlertNotifications = [];
        this.readAlertNotifications = [];
        this.selectAll = false;
    }

    async attached() {
        await super.attached();
        this.viewType = VIEW_TYPE.CURRENT_ALERTS;
        this.screenViewUtility.viewName = "Notifications";
        $('.ui.dropdown').dropdown();
        let allNotifications = await this.notificationService.getAlertNotifications();
        this.currentAlertNotifications = AlertNotificationSelection.buildFromNotifications(allNotifications);
    }

    async activate(params, routeData) {
        await super.activate(params, routeData);
    }

    async currentItemClicked() {
        this.viewType = VIEW_TYPE.CURRENT_ALERTS;
        $(this.$currentItem)
            .addClass('active')
            .siblings()
            .removeClass('active');
    }

    async readItemClicked() {
        this.viewType = VIEW_TYPE.READ_ALERTS;
        $(this.$readItem)
            .addClass('active')
            .siblings()
            .removeClass('active');
    }

    async checkboxClicked() {
        this.selectAll = this.selectAll ? false : true;
        let notificationsToCheck = this.isCurrentItems ? this.currentAlertNotifications : this.readAlertNotifications;
        notificationsToCheck.forEach(notification => notification.isChecked = this.selectAll ? true : false);
    }

    async markAsRead() {
        this.readAlertNotifications = this.readAlertNotifications.concat(this.currentAlertNotifications
            .filter(notification => notification.isChecked)
            .map(notification => new AlertNotificationSelection(notification)));
        this.readAlertNotifications.forEach(notification => {
            notification.isChecked = false;
            notification.alertNotification.contactsRead.push( new AlertNotificationRead({
                contact: this.userSession.user.name,
                dateRead: new Date()
            }))
        });
        this.currentAlertNotifications = this.currentAlertNotifications
            .filter(notification => !notification.isChecked)
        this.selectAll = false;
    }

    async markAsUnRead() {
        this.currentAlertNotifications = this.currentAlertNotifications.concat(this.readAlertNotifications
            .filter(notification => notification.isChecked)
            .map(notification => new AlertNotificationSelection(notification)));
        this.currentAlertNotifications.forEach(notification => {
            notification.isChecked = false;
            notification.alertNotification.contactsRead.filter(
                readContact => readContact.contact !== this.userSession.user.name
            );
        })
        this.readAlertNotifications = this.readAlertNotifications
            .filter(notification => !notification.isChecked)
        this.selectAll = false;
    }

    @computedFrom('viewType')
    get isCurrentItems(): boolean {
        return this.viewType === VIEW_TYPE.CURRENT_ALERTS;
    }

    @computedFrom('viewType')
    get isReadItems(): boolean {
        return this.viewType === VIEW_TYPE.READ_ALERTS;
    }


}