import { autoinject } from "aurelia-framework";
import { DefaultService } from "shared-service/default-service";
import { ColdChainZip } from "cold-chain/models/cold-chain-zip";
import { SearchService } from "shared-service/search-service";
import { SearchRequest } from "shared-models/search-request";
import { SearchResult } from "shared-models/search-result";
import { GetRecordsRequest } from "shared-models/get-records-request";
import { GetRecordsService } from "shared-service/get-records-service";
import { GetRecordRequest } from "shared-models/get-record-request";
import { GetRecordService } from "shared-service/get-record-service";

@autoinject
export class ColdChainService extends DefaultService{
    private static readonly COLD_CHAIN_ZIP_RECORD_TYPE = "vf_cc_comp_zip";

    constructor(private searchService: SearchService, private getRecordService: GetRecordService, private getRecordsService: GetRecordsService){
        super(ColdChainService.name);        
    }

    async searchColdChainZips(page: number, filter: string, pageSize:number = 50): Promise<SearchResult>{
        page = page || 1;
        filter = filter || "";
        let searchRequest = new SearchRequest({
            recordType: ColdChainService.COLD_CHAIN_ZIP_RECORD_TYPE,
            pageSize: pageSize,
            page: page,
            filter: filter
        });
        return await this.searchService.searchRecords(searchRequest, this._convertResponseToColdChainZip)
    }

    async getColdChainZip(coldChainZipId: number): Promise<ColdChainZip>{
        let getRecordRequest = new GetRecordRequest({
            recordType: ColdChainService.COLD_CHAIN_ZIP_RECORD_TYPE,
            recordId: coldChainZipId
        });
        let getRecordResponse = await this.getRecordService.getRecord(getRecordRequest, this._convertResponseToColdChainZip);
        return getRecordResponse.record;
    }

    async getAllColdChainZips(): Promise<ColdChainZip[]>{
        let allColdChainZips = [];
        let page = 1;
        let pageSize = 5000;
        let initialSearch = await this.searchColdChainZips(page, "", pageSize);
        allColdChainZips = allColdChainZips.concat(initialSearch.records as ColdChainZip[]);
        var totalPageCount = initialSearch.pageCount;
        if(totalPageCount > 1){
            while(page < totalPageCount){                
                page++;
                let nextSearch = await this.searchColdChainZips(page, "", pageSize);
                allColdChainZips = allColdChainZips.concat(nextSearch.records as ColdChainZip[]);             
            }           
        }        
        return allColdChainZips;
    }

    _convertResponseToColdChainZip(coldChainResponse: any): ColdChainZip{
        return new ColdChainZip({
            id: coldChainResponse["id"],
            name: coldChainResponse["name"],
            zipcode: coldChainResponse["name"],
            city: coldChainResponse["city"],
            state: coldChainResponse["state"],
            deliveryDay: coldChainResponse["delivery_day"],
            deliveryTime: coldChainResponse["delivery_time"],
            hub: coldChainResponse["hub"],
            route: coldChainResponse["route"],
            zone: coldChainResponse["zone"]
        });
    }
}