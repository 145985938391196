import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, bindable, computedFrom } from "aurelia-framework";
import { ChangeOrderShipDateRequest } from "change-orders/models/change-order-request";

@autoinject
export class ChangeOrderShipDateView extends StandardView {

  @bindable
  changeRequest: ChangeOrderShipDateRequest;
  
  $requestForm: HTMLElement;

  $futureShipDateCalendar: HTMLElement;


  constructor() {
    super(ChangeOrderShipDateView.name);
  }

  async activate(params, routeData) {
    this.changeRequest = params;
    await super.activate(params, routeData);
  }

  async attached() {    
    await this._setupFields();
    await this._setupForms();
    await super.attached();
  }

  async _setupFields() {   
    await $(this.$futureShipDateCalendar).calendar({
      type: 'date',
      disabledDaysOfWeek: [0, 6],
      minDate: new Date(),
      onChange: (date, text, mode) => {
        this.changeRequest.newShipDate = date;
      }
    });
  }

  async _setupForms() {

  }

  @computedFrom('changeRequest.id')
  get isCreating(): boolean{
    return !this.changeRequest.id;
  }

}