import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, computedFrom, bindable } from "aurelia-framework";
import { ChangeOrderAddressRequest } from "change-orders/models/change-order-request";
import { CountryService } from "orders/services/country_service";
import { CountryReference, StateReference } from "orders/models/country-record";

@autoinject
export class ChangeOrderAddress extends StandardView {

  @bindable
  changeRequest: ChangeOrderAddressRequest;

  $requestForm: HTMLElement;

  $carrierDropdown: HTMLElement;

  $shipMethodDropdown: HTMLElement;

  $countryDropdown: HTMLElement;

  $stateDropdown: HTMLElement;

  countries: CountryReference[] = [];


  constructor(private countryService: CountryService) {
    super(ChangeOrderAddress.name);
  }

  async activate(params, routeData) {
    this.disableView();
    this.changeRequest = params;
    if(!this.changeRequest.id){
      this.countries = await this.countryService.getAllCountryRecords();
    }    
    await super.activate(params, routeData);
    this.enableView();
  }

  async attached() {
    if(!this.changeRequest.id){
      await this._setupFields();
      await this._setupForms();
    }    
    await super.attached();
  }

  async _setupFields() {
    await Promise.all([
      $(this.$countryDropdown).dropdown(
        {
          onChange: async (value, text, $selectedItem) => {
            if (value) {
              this.changeRequest.newAddress.country = this.countries.find((country) => country.id == value);
            } else {
              this.changeRequest.newAddress.country = null;
            }
            await $(this.$stateDropdown).dropdown('clear');
          }
        }),

      $(this.$stateDropdown).dropdown(
        {
          onChange: async (value, text, $selectedItem) => {
            if (value) {
              this.changeRequest.newAddress.state = this.currentCountry ? this.currentCountry.states.find((state) => state.id == value) : null;
            } else {
              this.changeRequest.newAddress.state = null;
            }
          }
        })
    ]);
    if(this.changeRequest.currentAddress.country){
      await $(this.$countryDropdown).dropdown('set selected', this.changeRequest.currentAddress.country.id);
    } else{
      await $(this.$countryDropdown).dropdown('set selected', this.unitedStates.id);
    }

    if(this.changeRequest.currentAddress.state){
      await $(this.$stateDropdown).dropdown('set selected', this.changeRequest.currentAddress.state.id);
    }
    
  }

  async _setupForms() {

  }

  @computedFrom('changeRequest.newAddress.country', 'countries.length')
  get currentCountry(): CountryReference {
    if (!this.changeRequest.newAddress.country) {
      return null;
    }
    return this.countries.find((country) => country.id === this.changeRequest.newAddress.country.id)
  }


  @computedFrom('currentCountry')
  get states(): StateReference[] {
    if (this.currentCountry) {
      return this.currentCountry.states;
    }
    return [];
  }

  @computedFrom('countries.length')
  get unitedStates(): CountryReference {
    if (this.countries && this.countries.length > 0) {
      return this.countries.find((country) => country.name === "United States");
    }
    return null;
  }

}