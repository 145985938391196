import { IRecordReference, RecordReference } from "shared-models/record-reference";
import { computedFrom } from "aurelia-binding";

export enum ORDER_COLUMN_TYPES { 
    ORDER_NUMBER = 'Order Number',
    SHIP_DATE = 'Ship Date',
    FIRST_NAME = 'First Name',
    LAST_NAME = 'Last Name',
    COMPANY = 'Company',
    ADDRESS1 = 'Address1',
    ADDRESS2 = 'Address2',
    CITY = 'City',    
    STATE = 'State',
    ZIP = 'Zip',    
    COUNTRY = 'Country',
    PHONE = 'Phone',
    EMAIL = 'Email',
    SHIPPING_INSTRUCTIONS = 'ShippingInstructions',
    GIFT_MESSAGE = 'GiftMessage',
    SHIP_METHOD = 'ShipMethod',
    ITEM_SKU = 'Item', 
    ITEM_QUANTITY = 'Item Quantity'
}



export interface IImportOrderSetup extends IRecordReference{isDefault?: boolean, columns: IImportOrderColumn[], csvFileData?: string, integrationType?: IRecordReference};
export class ImportOrderSetup extends RecordReference{
    isDefault?: boolean = false;
    columns: ImportOrderColumn[];
    csvFileData?: string;
    integrationType: RecordReference;
    
    constructor(data: IImportOrderSetup){
        super(data)
        this.isDefault = data.isDefault || false;        
        this.columns = (data.columns || []).map((column)=> new ImportOrderColumn(column));
        this.csvFileData = data.csvFileData
        this.integrationType = data.integrationType ? new RecordReference(data.integrationType) : null;
    }
}

export interface IImportOrderColumn extends IRecordReference{type: IRecordReference, isMandatory?: boolean, columnNameRegExpString?: string, dataRegExpString?: string};
export class ImportOrderColumn extends RecordReference{
    type: RecordReference;
    isMandatory?: boolean = false;
    columnNameRegExpString?: string;
    dataRegExpString?: string;
    columnNameRegExp: RegExp;
    dataRegExp: RegExp;
    
    constructor(data: IImportOrderColumn){
        super(data)
        this.type = data.type ? new RecordReference(data.type) : null;        
        this.isMandatory = data.isMandatory || false;        
        this.columnNameRegExpString = data.columnNameRegExpString || `^${this.name}$`;    
        this.dataRegExpString = data.dataRegExpString || "";    
        this.columnNameRegExp = new RegExp(this.columnNameRegExpString, 'i');   
        this.dataRegExp = this.dataRegExpString ? new RegExp(this.dataRegExpString, 'i') : null;   
    }

    isColumnMatched(columnName): boolean{
        return this.columnNameRegExp.test(columnName);
    }

    isDataMatched(data): boolean{        
        if(!data){
            return true;
        }
        if(!this.dataRegExp){
            return true;
        }
        return this.dataRegExp.test(data);
    }

    @computedFrom('type')
    get isOrderNumber(): boolean{
        return this.type.name === ORDER_COLUMN_TYPES.ORDER_NUMBER;
    }    

    @computedFrom('type')
    get isState(): boolean{
        return this.type.name === ORDER_COLUMN_TYPES.STATE;
    }

    @computedFrom('type')
    get isCountry(): boolean{
        return this.type.name === ORDER_COLUMN_TYPES.COUNTRY;
    }

    @computedFrom('type')
    get isShipMethod(): boolean{
        return this.type.name === ORDER_COLUMN_TYPES.SHIP_METHOD;
    }

    @computedFrom('type')
    get isEmail(): boolean{
        return this.type.name === ORDER_COLUMN_TYPES.EMAIL;
    }

    @computedFrom('type')
    get isShipDate(): boolean{
        return this.type.name === ORDER_COLUMN_TYPES.SHIP_DATE;
    }

    @computedFrom('type')
    get isItemSku(): boolean{
        return this.type.name === ORDER_COLUMN_TYPES.ITEM_SKU;
    }

    @computedFrom('type')
    get isItemQuantity(): boolean{
        return this.type.name === ORDER_COLUMN_TYPES.ITEM_QUANTITY;
    }
}
