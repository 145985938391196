import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, computedFrom } from "aurelia-framework";
import { ROUTES } from "routes";
import {VIEW_TYPES} from "orders/views/order-list-view"

@autoinject
export class ColdChainDashboard extends StandardView {
    alertNotificationsLoading: boolean;
    kpisLoading: boolean;
    constructor() {
        super(ColdChainDashboard.name); 
        this.alertNotificationsLoading = true;
        this.kpisLoading = true;
        this.screenViewUtility.viewName = "";
    }

    async attached() {
        await super.attached();        
    }

    async activate(params, routeData) {
        await super.activate(params, routeData);
        this.screenViewUtility.viewName = "";               
    }

    @computedFrom()
    get coldChainCompatibleViewType(){
        return VIEW_TYPES.COLD_CHAIN_COMP;
    }

    @computedFrom()
    get coldChainOrdersViewType(){
        return VIEW_TYPES.COLD_CHAIN_ORDERS;
    }

    @computedFrom()
    get listOrdersRoute(){
        return ROUTES.LIST_ORDERS.name;
    }

    @computedFrom()
    get checkZipsRoute(){
        return ROUTES.COLD_CHAIN_ZIPS.name;
    }
}