import { IRecordReference, RecordReference } from "shared-models/record-reference";
import { Order, OrderItem, IAddress, Address } from "orders/models/order";
import { CarrierReference, ShippingMethodReference } from "orders/models/carrier-record";
import { ItemReference } from "items/models/item-record";
import { observable } from "aurelia-framework";

enum CHANGE_ORDER_TYPES {
    ADD_ICE = "Add Ice",
    REMOVE_ICE = "Remove Ice",
    PLACE_WEATHER_HOLD = "Place On Weather Hold",
    REMOVE_WEATHER_HOLD = "Remove From Weather Hold",
    CHANGE_SHIP_METHOD = "Change Shipping Method",
    CHANGE_ADDRESS = "Change Address",
    CHANGE_SHIP_INST = "Change Shipping Instructions",
    CHANGE_SHIP_DATE = "Change Ship Date",
    CHANGE_EMAIL = "Change Email",
    CHANGE_GIFT_MESSAGE = "Change Gift Message",
    VOID_ORDER = "Void Order",
    CHANGE_ITEMS = "Change Items",
    RETURN_TO_SHIPPER = "Return To Shipper"
}

export interface IChangeOrderType extends IRecordReference { description?: string, statusesNotAllowed?: string[], doNotAllowOtherChanges?: boolean};
export class ChangeOrderType extends RecordReference {
    description?: string;
    statusesNotAllowed?: string[];
    doNotAllowOtherChanges?: boolean;
    constructor(data: IChangeOrderType) {
        super(data); 
        this.description = data.description || "";
        this.statusesNotAllowed = data.statusesNotAllowed || [];
        this.doNotAllowOtherChanges = data.doNotAllowOtherChanges || false;
    }   

    isAllowedForOrder(order: Order): boolean{
        return this._isStatusAllowed(order.status) && this._isWeatherHoldValid(order) && this._isIceValid(order);
    }

    _isIceValid(order: Order): boolean{
        if(this._isAddIce()){
            return !order.includeIce;
        } else if(this._isRemoveIce()){
            return order.includeIce;
        }
        return true;
    }

    _isWeatherHoldValid(order: Order): boolean{
        if(this._isPlaceWeatherHold()){
            return !order.weatherHold;
        } else if(this._isRemoveWeatherHold()){
            return order.weatherHold;
        }
        return true;
    }

    _isStatusAllowed(status: string){
        return this.statusesNotAllowed.indexOf(status) === -1;
    }

    _isAddIce(){
        return this.name === CHANGE_ORDER_TYPES.ADD_ICE;
    }

    _isRemoveIce(){
        return this.name === CHANGE_ORDER_TYPES.REMOVE_ICE;
    }

    _isPlaceWeatherHold(){
        return this.name === CHANGE_ORDER_TYPES.PLACE_WEATHER_HOLD;
    }

    _isRemoveWeatherHold(){
        return this.name === CHANGE_ORDER_TYPES.REMOVE_WEATHER_HOLD;
    }

    _isChangeAddress(){
        return this.name === CHANGE_ORDER_TYPES.CHANGE_ADDRESS;
    }

    _isChangeShipMethod(){
        return this.name === CHANGE_ORDER_TYPES.CHANGE_SHIP_METHOD;
    }

    _isChangeShipInstructions(){
        return this.name === CHANGE_ORDER_TYPES.CHANGE_SHIP_INST;
    }

    _isChangeShipDate(){
        return this.name === CHANGE_ORDER_TYPES.CHANGE_SHIP_DATE;
    }

    _isChangeEmail(){
        return this.name === CHANGE_ORDER_TYPES.CHANGE_EMAIL;
    }

    _isChangeGiftMessage(){
        return this.name === CHANGE_ORDER_TYPES.CHANGE_GIFT_MESSAGE;
    }

    _isChangeItems(){
        return this.name === CHANGE_ORDER_TYPES.CHANGE_ITEMS;
    }

    getNewRequest(order: Order): ChangeOrderRequest{
        if(this._isChangeAddress()){
            return new ChangeOrderAddressRequest({
                changeOrderType: this,
                currentAddress: order.shippingAddress
            });
        }
        if(this._isChangeShipMethod()){
            return new ChangeOrderShipMethodRequest({
                changeOrderType: this,
                currentCarrier: order.carrier,
                currentShipMethod: order.shippingMethod
            });
        }
        if(this._isChangeShipInstructions()){
            return new ChangeOrderShipInstructionsRequest({
                changeOrderType: this,
                currentInstructions: order.shippingInstructions
            });
        }
        if(this._isChangeShipDate()){
            return new ChangeOrderShipDateRequest({
                changeOrderType: this,
                currentShipDate: order.futureShipDate
            });
        }
        if(this._isChangeEmail()){
            return new ChangeOrderEmailRequest({
                changeOrderType: this,
                currentEmail: order.email
            });
        }
        if(this._isChangeGiftMessage()){
            return new ChangeOrderGiftMsgRequest({
                changeOrderType: this,
                currentGiftMessage: order.giftMessage
            });
        }
        if(this._isChangeItems()){
            return new ChangeOrderItemsRequest({
                changeOrderType: this,
                items: order.items.map((orderItem)=> {
                    return new ChangeOrderItemLine({
                        orderItem: orderItem,
                        newQuantity: orderItem.quantity
                    })
                })
            });
        }
        return new ChangeOrderRequest({
            changeOrderType: this
        });
    }
}

export interface IChangeOrderRequest extends IRecordReference { changeOrderType?: ChangeOrderType};
export class ChangeOrderRequest extends RecordReference {    
    changeOrderType?: ChangeOrderType;   
    viewModelPath: string = "change-orders/views/change-order-standard-task";
    constructor(data: IChangeOrderRequest) {
        super(data);
        this.changeOrderType = data.changeOrderType? new ChangeOrderType(data.changeOrderType) : null;
    }
    
    convertToData(): any{
        return {
            "change_order_type": this.changeOrderType.id
        }
    }
    
    getValidationErrors(): string[]{
        return [];
    }    
}

export interface IChangeOrderItemsRequest extends IChangeOrderRequest {items?: ChangeOrderItemLine[], newItems?: ChangeOrderNewItem[]};
export class ChangeOrderItemsRequest extends ChangeOrderRequest { 
    items?: ChangeOrderItemLine[];
    newItems?: ChangeOrderNewItem[];
    constructor(data: IChangeOrderItemsRequest) {
        super(data);
        this.items = (data.items || []).map((item)=> new ChangeOrderItemLine(item));
        this.newItems = (data.newItems || []).map((item)=> new ChangeOrderNewItem(item));
        this.viewModelPath = "change-orders/views/change-order-items";
    }
    
    convertToData(): any{
        return {
            "change_order_type": this.changeOrderType.id,
            "current_items": this.items.map((currentItem)=>{
                return {
                    "item": currentItem.orderItem.itemId,
                    "old_quantity": currentItem.orderItem.quantity,
                    "new_quantity": currentItem.newQuantity,
                    "remove_item": currentItem.removeItem
                };
            }),
            "new_items": this.newItems.map((newItem)=>{
                return {
                    "item": newItem.item.id,
                    "old_quantity": 0,
                    "new_quantity": newItem.quantity,
                    "remove_item": false
                };
            })
        };
    }

    getValidationErrors(): string[]{
        return [];
    }
}

export interface IChangeOrderNewItem { item: ItemReference, quantity: number, lineNumber: number };
export class ChangeOrderNewItem {
  item: ItemReference;
  quantity: number;
  lineNumber: number
  constructor(data: IChangeOrderNewItem) {
    this.item = new ItemReference(data.item);
    this.lineNumber = data.lineNumber;
    this.quantity = data.quantity || 0;
  }
}

export interface IChangeOrderItemLine {orderItem?: OrderItem, removeItem?: boolean, newQuantity?: number};
export class ChangeOrderItemLine { 
    orderItem?: OrderItem;
    @observable removeItem?: boolean;
    @observable newQuantity?: number;
    constructor(data: IChangeOrderItemLine) {
        this.orderItem = data.orderItem ? new OrderItem(data.orderItem) : null;
        this.removeItem = data.removeItem || false;
        this.newQuantity = data.newQuantity;
    } 

    removeItemChanged(newValue, oldValue) {
        if(newValue){
            this.newQuantity = 0;
        } else{
            this.newQuantity = this.orderItem.quantity;
        }        
    }

    newQuantityChanged(newValue, oldValue) {
        if(newValue == 0 && !this.removeItem){
            this.removeItem = true;
        }     
    }
}

export interface IChangeOrderShipDateRequest extends IChangeOrderRequest { currentShipDate?: Date, newShipDate?: Date};
export class ChangeOrderShipDateRequest extends ChangeOrderRequest {    
    currentShipDate?: Date;
    newShipDate?: Date;
    constructor(data: IChangeOrderShipDateRequest) {
        super(data); 
        this.currentShipDate = data.currentShipDate;
        this.newShipDate = data.newShipDate;
        this.viewModelPath = "change-orders/views/change-order-ship-date";
    } 

    convertToData(): any{
        return {
            "change_order_type": this.changeOrderType.id,           
            "current_ship_date": this.currentShipDate,           
            "new_ship_date": this.newShipDate        
        }
    }
    
    getValidationErrors(): string[]{
        let errors = [];
        if(!this.newShipDate){
            errors.push("Need to provide a new Ship Date.")
        }
        if(this.newShipDate === this.currentShipDate){
            errors.push("The New Ship Date must be different from the Current Ship Date.")
        }
        return errors;
    }
}

export interface IChangeOrderShipInstructionsRequest extends IChangeOrderRequest { currentInstructions?: string, newInstructions?: string};
export class ChangeOrderShipInstructionsRequest extends ChangeOrderRequest {    
    currentInstructions?: string;
    newInstructions?: string;
    constructor(data: IChangeOrderShipInstructionsRequest) {
        super(data); 
        this.currentInstructions = data.currentInstructions || "";
        this.newInstructions = data.newInstructions || "";
        this.viewModelPath = "change-orders/views/change-order-ship-instructions";
    } 

    convertToData(): any{
        return {
            "change_order_type": this.changeOrderType.id,
            "current_instructions": this.currentInstructions,
            "new_instructions": this.newInstructions       
        }
    }

    getValidationErrors(): string[]{
        let errors = [];       
        if(this.newInstructions === this.currentInstructions){
            errors.push("The New Shipping Instruction must be different from the Current Shipping Instructions.")
        }
        return errors;
    }
}

export interface IChangeOrderShipMethodRequest extends IChangeOrderRequest { currentCarrier?: CarrierReference,currentShipMethod?: ShippingMethodReference, newCarrier?: CarrierReference, newShipMethod?: ShippingMethodReference};
export class ChangeOrderShipMethodRequest extends ChangeOrderRequest {    
    currentCarrier?: CarrierReference;
    currentShipMethod?: ShippingMethodReference;
    newCarrier?: CarrierReference;
    newShipMethod?: ShippingMethodReference;
    constructor(data: IChangeOrderShipMethodRequest) {
        super(data); 
        this.currentCarrier = data.currentCarrier ? new CarrierReference(data.currentCarrier) : null;   
        this.currentShipMethod = data.currentShipMethod ? new ShippingMethodReference(data.currentShipMethod) : null;   
        this.newCarrier = data.newCarrier ? new CarrierReference(data.newCarrier) : null;   
        this.newShipMethod = data.newShipMethod ? new ShippingMethodReference(data.newShipMethod) : null;   
        this.viewModelPath = "change-orders/views/change-order-ship-method";
    } 

    convertToData(): any{
        return {
            "change_order_type": this.changeOrderType.id,           
            "current_carrier": this.currentCarrier.id,           
            "new_carrier": this.newCarrier.id,           
            "current_ship_method": this.currentShipMethod.id,           
            "new_ship_method": this.newShipMethod.id         
        }
    }
    
    getValidationErrors(): string[]{
        let errors = [];
        if(!this.newCarrier){
            errors.push("Need to provide a shipping Carrier.")
        }
        if(!this.newShipMethod){
            errors.push("Need to provide a shipping Method.")
        }
        if(this.newShipMethod.id === this.currentShipMethod.id){
            errors.push("The New Ship Method must be different from the Current Ship Method.")
        }
        return errors;
    }
}

export interface IChangeOrderAddressRequest extends IChangeOrderRequest { currentAddress?: IAddress, newAddress?: IAddress};
export class ChangeOrderAddressRequest extends ChangeOrderRequest {    
    currentAddress?: Address;
    newAddress?: Address;
    constructor(data: IChangeOrderAddressRequest) {
        super(data); 
        this.currentAddress = data.currentAddress ? new Address(data.currentAddress) : new Address({});
        this.newAddress = data.newAddress ? new Address(data.newAddress) : new Address(data.currentAddress);
        this.viewModelPath = "change-orders/views/change-order-address";
    }

    convertToData(): any{
        return {
            "change_order_type": this.changeOrderType.id,
            "current_address": {
                "attention": this.currentAddress.attention,
                "addressee": this.currentAddress.addressee,
                "street": this.currentAddress.address1,
                "street2": this.currentAddress.address2,
                "zipcode": this.currentAddress.zip,
                "city": this.currentAddress.city,
                "state": this.currentAddress.state ? this.currentAddress.state.id : null,
                "country": this.currentAddress.country ? this.currentAddress.country.id : null,
                "phone": this.currentAddress.phone
            },
            "new_address": {
                "attention": this.newAddress.attention,
                "addressee": this.newAddress.addressee,
                "street": this.newAddress.address1,
                "street2": this.newAddress.address2,
                "zipcode": this.newAddress.zip,
                "city": this.newAddress.city,
                "state": this.newAddress.state ? this.newAddress.state.id : null,
                "country": this.newAddress.country ? this.newAddress.country.id : null,
                "phone": this.newAddress.phone
            }
                  
        }
    }
    
    getValidationErrors(): string[]{
        let errors = [];
        if(!this.newAddress.addressee){
            errors.push("Need to provide an addressee value on the address.")
        }
        return errors;
    }
}

export interface IChangeOrderEmailRequest extends IChangeOrderRequest { currentEmail?: string, newEmail?: string };
export class ChangeOrderEmailRequest extends ChangeOrderRequest {    
    currentEmail?: string;
    newEmail?: string;
    constructor(data: IChangeOrderEmailRequest) {
        super(data); 
        this.currentEmail = data.currentEmail || "";
        this.newEmail = data.newEmail || "";
        this.viewModelPath = "change-orders/views/change-order-email";
    }

    convertToData(): any{
        return {
            "change_order_type": this.changeOrderType.id,
            "current_email": this.currentEmail,
            "new_email": this.newEmail      
        }
    }
    
    getValidationErrors(): string[]{
        let errors = [];
        if(!this.newEmail){
            errors.push("Need to provide a New Email value.")
        }
        if(this.newEmail === this.currentEmail){
            errors.push("The New Email must be different from the Current Email.")
        }
        return errors;
    }
}

export interface IChangeOrderGiftMsgRequest extends IChangeOrderRequest { currentGiftMessage?: string, newGiftMessage?: string };
export class ChangeOrderGiftMsgRequest extends ChangeOrderRequest {    
    currentGiftMessage?: string;
    newGiftMessage?: string;
    constructor(data: IChangeOrderGiftMsgRequest) {
        super(data); 
        this.currentGiftMessage = data.currentGiftMessage || "";
        this.newGiftMessage = data.newGiftMessage || "";
        this.viewModelPath = "change-orders/views/change-order-gift-message";
    }   

    convertToData(): any{
        return {
            "change_order_type": this.changeOrderType.id,
            "current_gift_message": this.currentGiftMessage,        
            "new_gift_message": this.newGiftMessage        
        }
    }
    
    getValidationErrors(): string[]{
        let errors = [];       
        if(this.newGiftMessage === this.currentGiftMessage){
            errors.push("The New Gift Message must be different from the Current Gift Message.")
        }
        return errors;
    }
}