import { CognitoUser } from "@aws-amplify/auth";

export enum LOGIN_STATUS{
    NONE = "",
    SUCCESS = "SUCCESS",
    FAILED = "FAILED",
    NEWPASSWORD = "NEW_PASSWORD_REQUIRED"
}

export class LoginTokens{
    accessToken: string;
    idToken: string;
    refreshToken: string;

    constructor(data?: {accessToken?: string, idToken?: string, refreshToken?: string}){
        data = data || {accessToken: "", idToken: "", refreshToken: ""};  
        this.accessToken = data.accessToken || "";
        this.idToken = data.idToken || "";
        this.refreshToken = data.refreshToken || "";  
    }
}

export class LoginResponse{
    status: LOGIN_STATUS;
    message: string;
    email: string;
    user: CognitoUser | any;
    tokens: LoginTokens;

    constructor(data?: {status?: LOGIN_STATUS, message?: string, email?: string, user?: any, tokens?: LoginTokens}){
        data = data || {status: LOGIN_STATUS.NONE, message: "", email: "", tokens: null};
        this.status = data.status || LOGIN_STATUS.NONE;
        this.message = data.message || "";
        this.email = data.email || "";
        this.user = data.user || "";
        this.tokens = data.tokens || null;
    }

    get isSuccessful(): boolean{
        return this.status === LOGIN_STATUS.SUCCESS;
    }

    get needNewPassword(): boolean{
        return this.status === LOGIN_STATUS.NEWPASSWORD;
    }
}