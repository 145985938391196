import { StandardView } from "shared-ui/views/standard-view";
import { CognitoUser } from "@aws-amplify/auth";
import { computedFrom } from "aurelia-binding";
import { autoinject } from "aurelia-framework";
import { ScreenViewUtility } from "shared-ui/utility/screen-view-utility";

export enum LOGIN_FORM_TYPE {
  LOGIN,
  NEW_PASSWORD,
  FORGOT_PASSWORD_SEND_CODE,
  FORGOT_PASSWORD_ENTER_CODE,
}

@autoinject
export class LoginView extends StandardView {
  formType: LOGIN_FORM_TYPE;
  email: string;
  user: CognitoUser | any;

  constructor(private screenViewUtil: ScreenViewUtility) {
    super(LoginView.name);
    this.screenViewUtil.disableView = false;    
  }

  async activate(params, routeData) {
    await super.activate(params, routeData);
  }

  async attached() {  
    this.formType = LOGIN_FORM_TYPE.LOGIN; 
    await super.attached();
  }

  @computedFrom('formType')
  get isLoginForm(): boolean{
    return this.formType === LOGIN_FORM_TYPE.LOGIN;
  }

  @computedFrom('formType')
  get isNewPasswordForm(): boolean{
    return this.formType === LOGIN_FORM_TYPE.NEW_PASSWORD;
  }

  @computedFrom('formType')
  get isForgotPasswordSendCodeForm(): boolean{
    return this.formType === LOGIN_FORM_TYPE.FORGOT_PASSWORD_SEND_CODE;
  }

  @computedFrom('formType')
  get isForgotPasswordEnterCodeForm(): boolean{
    return this.formType === LOGIN_FORM_TYPE.FORGOT_PASSWORD_ENTER_CODE;
  }

}