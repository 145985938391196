import { StandardView } from "shared-ui/views/standard-view";
import { bindable, autoinject } from "aurelia-framework";
import { LOGIN_FORM_TYPE } from "../login-view";
import { LoginService } from "login/services/login-service";
import { ScreenViewUtility } from "shared-ui/utility/screen-view-utility";

@autoinject
export class SendCodeView extends StandardView{
    @bindable
    formType: LOGIN_FORM_TYPE;
    @bindable
    email: string;    
    $form: any;   

    constructor(private loginService: LoginService, private screenViewUtil: ScreenViewUtility){
        super(SendCodeView.name);
        this.enableView();
    }

    async attached() {
        await super.attached();
        $(this.$form).form({
            on: 'blur',
            onSuccess: (event, fields) => {
                event.preventDefault(); //Keep from submitting the page
                this.emailCode();
            },
            fields: {
                email: {
                    identifier: 'email',
                    rules: [
                        {
                            type: 'email',
                            prompt: 'Please enter a valid e-mail'
                        }
                    ]
                }
            }
        });
    }

    clickGoBack(){
        this.formType = LOGIN_FORM_TYPE.LOGIN;
    }

    async emailCode(){
        this.disableView();
        try{
            await this.loginService.sendForgotPasswordCode(this.email);
            this.formType = LOGIN_FORM_TYPE.FORGOT_PASSWORD_ENTER_CODE;
        } catch(err){
            $(this.$form).form('add errors', [err.message]);
        }
        this.enableView();
    }
}