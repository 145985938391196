import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, computedFrom, bindable } from "aurelia-framework";
import { CarrierService } from "orders/services/carrier_service";
import { CarrierReference, ShippingMethodReference } from "orders/models/carrier-record";
import { ChangeOrderShipMethodRequest } from "change-orders/models/change-order-request";

@autoinject
export class ChangeOrderShipMethodView extends StandardView {

  @bindable
  changeRequest: ChangeOrderShipMethodRequest;

  $requestForm: HTMLElement;

  $carrierDropdown: HTMLElement;

  $shipMethodDropdown: HTMLElement;

  carriers: CarrierReference[] = []; 

  constructor(private carrierService: CarrierService) {
    super(ChangeOrderShipMethodView.name);
  }

  async activate(params, routeData) {
    this.disableView();
    this.changeRequest = params;
    if(!this.changeRequest.id){
      this.carriers = await this.carrierService.getRecords();
    }        
    await super.activate(params, routeData);
    this.enableView();
  }

  async attached() {    
    await this._setupFields();
    await this._setupForms();
    await super.attached();
  }

  async _setupFields() {
    await Promise.all([
      $(this.$carrierDropdown).dropdown({
        onChange: async (value, text, $selectedItem) => {
          if (value) {
            this.changeRequest.newCarrier = this.carriers.find((carrier) => carrier.id == value);
          } else {
            this.changeRequest.newCarrier = null;
          }
          await $(this.$shipMethodDropdown).dropdown('clear');
        }
      }),

      $(this.$shipMethodDropdown).dropdown({
        onChange: async (value, text, $selectedItem) => {
          if (value) {
            this.changeRequest.newShipMethod = this.changeRequest.newCarrier ? this.changeRequest.newCarrier.shippingMethods.find((shippingMethod) => shippingMethod.id == value) : null;
          } else {
            this.changeRequest.newShipMethod = null;
          }
        }
      })
    ]);
  }

  async _setupForms() {

  }

  @computedFrom('changeRequest.newCarrier')
  get shippingMethods(): ShippingMethodReference[] {
    if (this.changeRequest && this.changeRequest.newCarrier) {
      return this.changeRequest.newCarrier.shippingMethods;
    }
    return [];
  }

}