import { StandardView } from "shared-ui/views/standard-view";
import { autoinject } from "aurelia-framework";
import { ScreenViewUtility } from "shared-ui/utility/screen-view-utility";

@autoinject
export class UserSessionAlertView extends StandardView {
    static readonly MINUTES_REMAINING_TO_CHECK = 10;
    static readonly SECONDS_INTERVAL_TO_CHECK = 60;
    $alertmodal: any;
    minutesRemaining: number;

    constructor(private screenViewUtil: ScreenViewUtility) {
        super(UserSessionAlertView.name);
        setInterval(this.checkValidSession.bind(this), UserSessionAlertView.SECONDS_INTERVAL_TO_CHECK * 1000);
    }

    async attached() {
        $(this.$alertmodal).modal({
            closable: false,
            onDeny: () => {
                this.userSession.logout();
            },
            onApprove: () => {
                this.refreshToken();
            }
        });
        super.attached();
    }

    async activate(params, routeData) {
        super.activate(params, routeData);
    }    

    async checkValidSession() {        
        this.minutesRemaining = await this.userSession.getTimeLeftInMinutes();
        debugger;
        if(this.minutesRemaining <= 0){
            await this.userSession.logout();
        }
        else if (this.minutesRemaining <= UserSessionAlertView.MINUTES_REMAINING_TO_CHECK) {
            $(this.$alertmodal).modal('show');
        }
    }

    async refreshToken() {
        this.screenViewUtil.disableView = true;
        await this.userSession.refreshToken();
        this.screenViewUtil.disableView = false;
    }    
}