import { StandardView } from "shared-ui/views/standard-view";
import { autoinject } from "aurelia-framework";
import { ROUTES } from "routes";
import {VIEW_TYPES} from "orders/views/order-list-view"

@autoinject
export class OrderListDashboard extends StandardView {
    alertNotificationsLoading: boolean;
    kpisLoading: boolean;
    constructor() {
        super(OrderListDashboard.name); 
        this.alertNotificationsLoading = true;
        this.kpisLoading = true;
        this.screenViewUtility.viewName = "";
    }

    async attached() {
        await super.attached();        
    }

    async activate(params, routeData) {
        await super.activate(params, routeData);
        this.screenViewUtility.viewName = "";               
    }

    async onClickAllOrders() {
        await this.routeToView(ROUTES.LIST_ORDERS.name, {listType: VIEW_TYPES.ALL});     
    }

    async onClickOpenOrders() {
        await this.routeToView(ROUTES.LIST_ORDERS.name, {listType: VIEW_TYPES.OPEN});        
    }

    async onClickWeatherHolds() {
        await this.routeToView(ROUTES.LIST_ORDERS.name, {listType: VIEW_TYPES.WEATHER_HOLDS});  
    }

    async onClickBackorders() {
        await this.routeToView(ROUTES.LIST_ORDERS.name, {listType: VIEW_TYPES.BACKORDERS});  
    }
}