import { LoginPassport } from "login/models/login-passport";
import { computedFrom } from "aurelia-binding";
import { LoginService } from "login/services/login-service";
import { CognitoUser } from "@aws-amplify/auth";
import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, bindable } from "aurelia-framework";
import { LOGIN_FORM_TYPE } from "./login-view";
import { ScreenViewUtility } from "shared-ui/utility/screen-view-utility";

@autoinject
export class LoginFormView extends StandardView {
    @bindable
    formType: LOGIN_FORM_TYPE;
    @bindable
    email: string;
    @bindable
    user: CognitoUser | any;    
    $form: any;
    password: string;

    newPassword: string;

    constructor(private loginService: LoginService, private screenViewUtil: ScreenViewUtility) {
        super(LoginFormView.name);
        this.user = null;
        this.enableView();
    }

    async attached() {
        await super.attached();
        $(this.$form).form({
            on: 'blur',
            onSuccess: (event, fields) => {
                event.preventDefault(); //Keep from submitting the page
                this.login();
            },
            fields: {
                email: {
                    identifier: 'email',
                    rules: [
                        {
                            type: 'email',
                            prompt: 'Please enter a valid e-mail'
                        }
                    ]
                },
                password: {
                    identifier: 'password',
                    rules: [
                        {
                            type: 'empty',
                            prompt: 'Please enter your password'
                        }
                    ]
                }
            }
        });
    }

    @computedFrom('email', 'password')
    get loginPassport(): LoginPassport {
        this.password = (this.password || "").trim();
        return new LoginPassport({ email: this.email, password: this.password });
    }
    
    async clickForgotPassword() {
        this.formType = LOGIN_FORM_TYPE.FORGOT_PASSWORD_SEND_CODE;
    }

    async login() {
        let loggedIn = false;
        this.disableView();
        try {
            let loginResponse = await this.loginService.login(this.loginPassport);
            if (loginResponse.isSuccessful) {
                loggedIn = true;
                location.reload();
            } else if (loginResponse.needNewPassword) {
                this.user = loginResponse.user;
                this.formType = LOGIN_FORM_TYPE.NEW_PASSWORD;
            } else {
                let message = loginResponse.message;
                if(message === "User does not exist." || message === "Incorrect username or password."){
                    message = "Incorrect Email or Password.";
                }                
                $(this.$form).form('add errors', [message]);
            }

        } catch (err) {
            $(this.$form).form('add errors', ["Error Logging In. Please Try again."]);
        }
        if(!loggedIn){
            this.enableView();
        }        
    }
}