import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, computedFrom } from "aurelia-framework";
import { ScreenViewUtility } from "shared-ui/utility/screen-view-utility";
import { ROUTES } from "routes";

@autoinject
export class Dashboard extends StandardView {
    alertNotificationsLoading: boolean;
    kpisLoading: boolean;
    constructor() {
        super(Dashboard.name); 
        this.alertNotificationsLoading = true;
        this.kpisLoading = true;
        this.screenViewUtility.viewName = "";
    }

    async attached() {
        await super.attached();        
    }

    async activate(params, routeData) {
        await super.activate(params, routeData);
        this.screenViewUtility.viewName = "";               
    }
  
    @computedFrom()
    get newOrderRoute(){
        return ROUTES.CREATE_ORDER.name;
    }

    @computedFrom()
    get importOrdersDashRoute(){
        return ROUTES.VIEW_IMPORT_ORDERS.name;
    }
    

    @computedFrom()
    get viewOrdersDashRoute(){
        return ROUTES.ORDER_LIST_DASHBOARD.name;
    }
   

    @computedFrom()
    get viewOrderBatchesRoute(){
        return ROUTES.LIST_ORDER_BATCHES.name;
    }

    @computedFrom()
    get viewChangeOrdersRoute(){
        return ROUTES.LIST_CHANGE_ORDER.name;
    }

    async onClickShippingStatus() {
        this.errorMessageUtil.showErrorMessage("Not Yet Implemented.");    
    }

    @computedFrom()
    get shippingDashRoute(){
        return ROUTES.SHIPPING_DASHBOARD.name;
    }


    @computedFrom()
    get billingDashRoute(){
        return ROUTES.BILLING_DASHBOARD.name;
    }
   
    @computedFrom()
    get itemsDashRoute(){
        return ROUTES.ITEMS_DASHBOARD.name;
    }

    @computedFrom()
    get coldChainDashRoute(){
        return ROUTES.COLD_CHAIN_DASHBOARD.name;
    }

    @computedFrom()
    get returnsDashRoute(){
        return ROUTES.RETURNS_DASHBOARD.name;
    }
    
    @computedFrom()
    get viewAddressesRoute(){
        return ROUTES.LIST_SHIPPING_ADDRESSES.name;
    }

}