import { autoinject } from "aurelia-framework";
import { DefaultService } from "shared-service/default-service";
import { Order, OrderItem, OrderReference, convertResponseToOrderReference, convertResponseToOrder, convertResponseToOrderLine } from "orders/models/order";
import moment from 'moment';
import { CreateRecordService } from "shared-service/create-record-service";
import { CreateRecordRequest } from "shared-models/create-record-request";
import { SearchService } from "shared-service/search-service";
import { GetRecordService } from "shared-service/get-record-service";
import { GetRecordsService } from "shared-service/get-records-service";
import { SearchResult } from "shared-models/search-result";
import { SearchRequest } from "shared-models/search-request";
import { GetRecordsRequest } from "shared-models/get-records-request";
import { GetRecordRequest } from "shared-models/get-record-request";

@autoinject
export class OrderService extends DefaultService {   
    private static readonly RECORD_TYPE = "sale.order";
    private static readonly ITEM_RECORD_TYPE = "sale.order.line";

    constructor(
        private searchService: SearchService, 
        private getRecordService: GetRecordService, 
        private getRecordsService: GetRecordsService, 
        private createRecordService: CreateRecordService) {
        super(OrderService.name);
    }

    async createRecord(order: Order): Promise<number> {
        let createOrderRequest = new CreateRecordRequest({
            recordType: OrderService.RECORD_TYPE,
            recordData: {
                "winery_order_number": order.wineryOrderNumber,
                "shipping_carrier": order.carrier ? order.carrier.id : null,
                "shipping_method": order.shippingMethod ? order.shippingMethod.id : null,
                "future_ship_date": order.futureShipDate,
                "special_shipping_instructions": order.shippingInstructions,
                "gift_message": order.giftMessage,
                "include_ice_packs": order.includeIce,
                "shipping_attention": order.shippingAddress.attention,
                "shipping_addressee": order.shippingAddress.addressee,
                "shipping_street": order.shippingAddress.address1,
                "shipping_street2": order.shippingAddress.address2,
                "shipping_zip": order.shippingAddress.zip,
                "shipping_city": order.shippingAddress.city,
                "shipping_state": order.shippingAddress.state ? order.shippingAddress.state.id : null,
                "shipping_country": order.shippingAddress.country ? order.shippingAddress.country.id : null,
                "shipping_phone": order.shippingAddress.phone,
                "recipient_emails": order.email,
                "items": order.items
            }
        });
        return await this.createRecordService.createRecord(createOrderRequest);        
    }

    async searchRecords(searchRequestData: {page?: number, pageSize?: number, filter?: string, fieldFilters?: any[]}): Promise<SearchResult>{
        let searchRequest = new SearchRequest({
            recordType: OrderService.RECORD_TYPE,
            pageSize: searchRequestData.pageSize || 50,
            page: searchRequestData.page || 1,
            filter: searchRequestData.filter || "",
            fieldFilters: searchRequestData.fieldFilters || []
        });
        return await this.searchService.searchRecords(searchRequest, convertResponseToOrderReference);
    }

    async searchByLineRecords(searchRequestData: {page?: number, pageSize?: number, filter?: string, fieldFilters?: any[]}): Promise<SearchResult>{
        let searchRequest = new SearchRequest({
            recordType: OrderService.ITEM_RECORD_TYPE,
            pageSize: searchRequestData.pageSize || 50,
            page: searchRequestData.page || 1,
            filter: "",
            fieldFilters: searchRequestData.fieldFilters || []
        });
        return await this.searchService.searchRecords(searchRequest, convertResponseToOrderLine);
    }

    async getAllLineRecords(fieldFilters: any[]=[]): Promise<OrderItem[]>{
        let allOrderLines = [];
        let page = 1;
        let pageSize = 5000;
        let initialSearch = await this.searchByLineRecords({page: page, pageSize: pageSize, fieldFilters: fieldFilters});
        allOrderLines = allOrderLines.concat(initialSearch.records as OrderItem[]);
        var totalPageCount = initialSearch.pageCount;
        if(totalPageCount > 1){
            while(page < totalPageCount){                
                page++;
                let nextSearch = await this.searchByLineRecords({page: page, pageSize: pageSize, fieldFilters: fieldFilters});
                allOrderLines = allOrderLines.concat(nextSearch.records as OrderItem[]);             
            }           
        }   
        return allOrderLines;
    }

    async getAllRecords(fieldFilters: any[]=[]): Promise<OrderReference[]>{
        let orderReferences = [] as OrderReference[];
        let page = 1;
        let pageSize = 5000;
        let initialSearch = await this.searchRecords({page: page, pageSize: pageSize, fieldFilters: fieldFilters});
        orderReferences = orderReferences.concat(initialSearch.records as OrderReference[]);
        var totalPageCount = initialSearch.pageCount;
        if(totalPageCount > 1){
            while(page < totalPageCount){
                page++;
                let nextSearch = await this.searchRecords({page: page, pageSize: pageSize, fieldFilters: fieldFilters});
                orderReferences = orderReferences.concat(nextSearch.records as OrderReference[]);
            }           
        }        
        return orderReferences;
    }

    async getRecord(orderId: number): Promise<Order>{
        let getRecordRequest = new GetRecordRequest({
            recordType: OrderService.RECORD_TYPE,
            recordId: orderId
        });
        let getRecordResponse = await this.getRecordService.getRecord(getRecordRequest, convertResponseToOrder);
        return getRecordResponse.record;
    }

    async getRecordsByOrderNumber(wineryOrderNumber: string): Promise<OrderReference[]>{
        let getRecordsRequest = new GetRecordsRequest({
            recordType: OrderService.RECORD_TYPE,
            fieldReference: "winery_order_number",
            fieldReferenceValue: wineryOrderNumber
        });
        let getRecordsResponse = await this.getRecordsService.getRecords(getRecordsRequest, convertResponseToOrderReference);
        return getRecordsResponse.records;
    }
}