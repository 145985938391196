import { StandardView } from "shared-ui/views/standard-view";
import { bindable, autoinject, computedFrom } from "aurelia-framework";
import { ROUTES } from "routes";
import { ShippingAddress } from "shipping-addresses/models/shipping-address-record";
import { ShippingAddressService } from "shipping-addresses/services/shipping_address_service";
import { CountryService } from "orders/services/country_service";
import { CountryReference, StateReference } from "orders/models/country-record";
import { observable } from "aurelia-binding";

@autoinject
export class ShippingAddressView extends StandardView{
    @bindable
    shippingAddress: ShippingAddress = null;

    $viewAddressForm: HTMLElement;

    $editAddressForm: HTMLElement;

    $countryDropdown: HTMLElement;

    $stateDropdown: HTMLElement;

    addressId: number;

    editParam: string = "";
    
    @observable
    isEditing: boolean = false;

    @observable
    isCreating: boolean = false;

    @observable
    isViewing: boolean = false;

    countries: CountryReference[] = [];

    constructor(private shippingAddressService: ShippingAddressService, private countryService: CountryService) {
        super(ShippingAddressView.name);
    }

    async activate(params, routeData) {
        try{
            this.addressId = params["id"] ? parseInt(params["id"], 10): null;
            this.editParam = params["e"];

            this.countries = await this.countryService.getAllCountryRecords();
            if(this.addressId){
              this.shippingAddress = await this.shippingAddressService.getRecord(this.addressId);
            } else{
              this.shippingAddress = new ShippingAddress({});
            }
            this.setupViewFields();           
          } catch(err){
            this.errorMessageUtil.showError(err);
            this.routeToView(ROUTES.DASHBOARD.name);
          }
        await super.activate(params, routeData);
    }

    async attached() {        
        this.setupViewFields();
        await this._setupFields();
        await super.attached();
    }

    setupViewFields(){
      if(!this.addressId){
        this._setAsCreate();
      } else{
        if(this.editParam === "T"){
          this._setAsEdit();
        } else{
          this._setAsView();
        }             
      }
    }

    async _setupFields() {     
      await Promise.all([        
        $('.ui.checkbox').checkbox(),  
  
        $(this.$countryDropdown).dropdown(
          {
            onChange: async (value, text, $selectedItem) => {
              if(value){
                this.shippingAddress.country = this.countries.find((country)=> country.id == value);
              } else{
                this.shippingAddress.country = null;
              } 
              await $(this.$stateDropdown).dropdown('clear');         
            }
          }),

        $(this.$stateDropdown).dropdown(
          {
            onChange: async (value, text, $selectedItem) => {
              if(value){
                this.shippingAddress.state = this.currentCountry ? this.currentCountry.states.find((state) => state.id == value) : null;
              } else{
                this.shippingAddress.state = null;
              }     
            }
          })
      ]);
      if (this.shippingAddress) {
        if(this.shippingAddress.country){
          await $(this.$countryDropdown).dropdown('set selected', this.shippingAddress.country.id);
        } else if(this.isCreating){
          await $(this.$countryDropdown).dropdown('set selected', this.unitedStates.id);
        }
        if(this.shippingAddress.state){
          await $(this.$stateDropdown).dropdown('set selected', this.shippingAddress.state.id);
        }        
      }
    }

    _setAsView(){
      this.isViewing = true;
      this.isEditing = false;      
      this.isCreating = false;
      $(this.$editAddressForm).hide();
      $(this.$viewAddressForm).show();
    }

    _setAsEdit(){
      this.isViewing = false;
      this.isEditing = true;      
      this.isCreating = false;
      $(this.$viewAddressForm).hide();
      $(this.$editAddressForm).show();
    }

    _setAsCreate(){
      this.isViewing = false;
      this.isEditing = false;      
      this.isCreating = true;
      $(this.$viewAddressForm).hide();
      $(this.$editAddressForm).show();
    }


    @computedFrom('currentCountry')
    get states(): StateReference[] {
      if (this.currentCountry) {
        return this.currentCountry.states;
      }
      return [];
    }

    @computedFrom('countries.length')
    get unitedStates(): CountryReference {
      if (this.countries && this.countries.length > 0) {
        return this.countries.find((country) => country.name === "United States");
      }
      return null;
    }
    
    @computedFrom('shippingAddress.country', 'countries.length')
    get currentCountry(): CountryReference {
      if(!this.shippingAddress || !this.shippingAddress.country){
        return null;
      }
      return this.countries.find((country)=> country.id === this.shippingAddress.country.id)
    }

    async editAddress() {
      this.disableView();
      this._setAsEdit();
      this.enableView();
    }

    async updateAddress() {
      this.disableView();
      try{
        await this.shippingAddressService.updateRecord(this.shippingAddress.id, this.shippingAddress);      
        this.routeToView(ROUTES.VIEW_SHIPPING_ADDRESS.name, { id: this.shippingAddress.id }); 
      } catch(err){
        this.errorMessageUtil.showError(err);
      }      
      this.enableView();
    }

    async cancelUpdate() {
      this.disableView();
      this.routeToView(ROUTES.VIEW_SHIPPING_ADDRESS.name, { id: this.shippingAddress.id });
      this.enableView();
    }

    async cancelCreate() {
      this.disableView();
      this.routeToView(ROUTES.LIST_SHIPPING_ADDRESSES.name);
      this.enableView();
    }

    async createAddress() {
      this.disableView();      
      try{
        let addressId = await this.shippingAddressService.createRecord(this.shippingAddress);
        if(!addressId){
          throw "Did not create Address";
        }       
        this.routeToView(ROUTES.VIEW_SHIPPING_ADDRESS.name, { id: addressId });
      } catch(err){
        this.errorMessageUtil.showError(err);
      }    
      this.enableView();
    }

    createOrder() {
      this.disableView();
      this.routeToView(ROUTES.CREATE_ORDER.name, { addressid: this.shippingAddress.id });
      this.enableView();
    }
    
}