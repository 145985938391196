import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, computedFrom } from "aurelia-framework";
import { ROUTES } from "routes";

@autoinject
export class BillingDashboard extends StandardView {
    alertNotificationsLoading: boolean;
    kpisLoading: boolean;
    constructor() {
        super(BillingDashboard.name); 
        this.alertNotificationsLoading = true;
        this.kpisLoading = true;
        this.screenViewUtility.viewName = "";
    }

    async attached() {
        await super.attached();        
    }

    async activate(params, routeData) {
        await super.activate(params, routeData);
        this.screenViewUtility.viewName = "";               
    }

    async onClickRateCalculator() {
        this.errorMessageUtil.showErrorMessage("Not Yet Implemented.");
    }

    @computedFrom()
    get rateCalculatorRoute(){
        return ROUTES.RATE_CALCULATOR.name;
    }

    @computedFrom()
    get listInvoicesRoute() {
        return ROUTES.LIST_INVOICES.name;       
    }

    @computedFrom()
    get listCreditMemosRoute() {
        return ROUTES.LIST_CREDIT_MEMOS.name;       
    }
}