import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, observable, computedFrom } from "aurelia-framework";
import { ROUTES } from "routes";
import { SearchResult } from "shared-models/search-result";
import parse from "papaparse";
import { SimpleDateFormatValueConverter } from "shared-ui/converters/simple-date-format";
import { Invoice } from "billing/models/invoice";
import { BillingService } from "billing/services/billing-service";

export enum VIEW_TYPES {
    OPEN = "open",
    PAID = "paid",
}

@autoinject
export class InvoiceListView extends StandardView {

    invoices: Invoice[];

    currentSearchResult: SearchResult;

    currentPage: number = 1;

    listDisabled: boolean = false;    

    $downloadCSVLink: HTMLElement;

    $exportLink: HTMLElement;

    @observable
    searchInput: string = "";

    listType: string;

    constructor(private billingService: BillingService, private simpleDateFormat: SimpleDateFormatValueConverter) {
        super(InvoiceListView.name);
    }

    async activate(params, routeData) {
        try {
            this.listType = params["listType"];
            await this._getResults();           
        } catch (err) {
            this.errorMessageUtil.showError(err);
            this.routeToView(ROUTES.DASHBOARD.name);
        }
        await super.activate(params, routeData);
    }

    async attached() {
        await super.attached();
    }

    async _getResults() {
        let fieldFilters = [];
        if(this.isOpen){
            fieldFilters = this._getOpenFilters();
        } else if(this.isPaid){
            fieldFilters = this._getPaidFilters();
        }
        this.logger.info(`fieldFilters :: ${JSON.stringify(fieldFilters)}`);
        this.currentSearchResult = await this.billingService.searchInvoices({page: this.currentPage, filter: this.searchInput, fieldFilters: fieldFilters});
        this.invoices = this.currentSearchResult.records as Invoice[];
    }    

    @computedFrom('listType')
    get isPaid(): boolean{
        return this.listType === VIEW_TYPES.PAID;
    }

    @computedFrom('listType')
    get isOpen(): boolean{
        return this.listType === VIEW_TYPES.OPEN;
    }

    _getOpenFilters(){
        return [['invoice_payment_state', '=', ['not_paid', 'in_payment']]];
    }

    _getPaidFilters(){
        return [['invoice_payment_state', '=', ['paid', '']]];
    }

    async searchInputChanged(newValue: string, oldValue: string) {
        this.disableList();
        this.currentPage = 1;
        await this._getResults();
        this.enableList();
    }

    async clickPrevious() {
        this.disableList();
        if (this.currentPage > 1) {
            this.currentPage--;
        }
        await this._getResults();
        this.enableList();
    }

    async clickNext() {
        this.disableList();
        if (this.currentPage < this.currentSearchResult.pageCount) {
            this.currentPage++;
        }
        await this._getResults();
        this.enableList();
    }

    async exportInvoices() {
        this.disableView();
        let invoices = [];
        if (this.searchInput) {
            invoices = this.invoices;
        } else {
            invoices = await this.billingService.getAllInvoices();
        }
        
        let csvData = this.convertToCsv(invoices);
        $(this.$exportLink).attr('href', `data:text/csv;charset=utf-8, ${escape(csvData)}`);       
        this.$exportLink.click();
        this.enableView();
    }

    convertToCsv(invoices: Invoice[]) {
        if(invoices.length === 0){
            return "Name\n";
        }
        return parse.unparse(invoices.map((invoice) => {
            return {
                "Name": invoice.name
            };
        }));
    }    

    @computedFrom()
    get viewInvoiceRoute(){
        return ROUTES.VIEW_INVOICE.name;
    }

    disableList() {
        this.listDisabled = true;
    }

    enableList() {
        this.listDisabled = false;
    }
}