import { CarrierReference, ICarrierReference } from "orders/models/carrier-record";
import { CountryReference, ICountryReference, IStateReference, StateReference } from "orders/models/country-record";
import { IRecordReference, RecordReference } from "shared-models/record-reference";
import { Logger } from "aurelia-logging";
import { LogManager } from "aurelia-framework";

let logger = LogManager.getLogger("ZONE");

export interface IZoneRange extends IRecordReference{    
    billingZone?: IRecordReference,
    carriers?: number[],
    country?: ICountryReference,
    state?: IStateReference,
    zipStart?: number,
    zipEnd?: number
};

export class ZoneRange extends RecordReference{    
    billingZone: RecordReference;
    carriers: number[];
    country: CountryReference;
    state: StateReference;
    zipStart: number;
    zipEnd: number;

    constructor(data: IZoneRange){
        data = data || {}
        super(data);
        this.billingZone = data.billingZone ? new RecordReference(data.billingZone): null;
        this.carriers = (data.carriers || []).map((carrier) => typeof carrier === "string" ? parseInt(carrier, 10): carrier);
        this.country = data.country ? new CountryReference(data.country): null;
        this.state = data.state ? new StateReference(data.state): null;
        this.zipStart = typeof data.zipStart === "string" ? parseInt(data.zipStart, 10) : data.zipStart;
        this.zipEnd = typeof data.zipEnd === "string" ? parseInt(data.zipEnd, 10) : data.zipEnd;
    }    
}

export function getMatchingZone(zoneRanges: ZoneRange[], carrier: CarrierReference, zipcode: string, country: CountryReference, state: StateReference): ZoneRange{    
    if(!carrier || !country || zoneRanges.length === 0){
        return null;
    }
    if(!zipcode && !state){
        return null;
    }    
    logger.info(`zoneRanges :: ${JSON.stringify(zoneRanges)}`)
    let filteredCountryZoneRanges = zoneRanges.filter((zoneRange)=> zoneRange.country.id == country.id);
    logger.info(`filteredCountryZoneRanges :: ${JSON.stringify(filteredCountryZoneRanges)}`)
    if(filteredCountryZoneRanges.length === 1){
        return filteredCountryZoneRanges[0];
    }
    
    let filteredCarrierZoneRanges = filteredCountryZoneRanges.filter((zoneRange)=> zoneRange.carriers.indexOf(carrier.id) > -1);
    logger.info(`filteredCarrierZoneRanges :: ${JSON.stringify(filteredCarrierZoneRanges)}`)
    if(filteredCarrierZoneRanges.length === 1){
        return filteredCarrierZoneRanges[0];
    }

    
    let filteredStateZoneRanges = [];
    if(state){
        filteredStateZoneRanges = filteredCarrierZoneRanges.filter((zoneRange)=> zoneRange.state.id == state.id);
        logger.info(`filteredStateZoneRanges :: ${JSON.stringify(filteredStateZoneRanges)}`)
        if(filteredStateZoneRanges.length === 1){
            return filteredStateZoneRanges[0];
        }
    }
    if(zipcode){
        let zipcodeInt = parseInt(zipcode, 10);
        let filteredZoneRanges = (filteredStateZoneRanges.length > 0 ? filteredStateZoneRanges : filteredCarrierZoneRanges).filter((zoneRange)=> zoneRange.zipStart <= zipcodeInt && zoneRange.zipEnd >= zipcodeInt);
        logger.info(`filteredZoneRanges :: ${JSON.stringify(filteredZoneRanges)}`)
        if(filteredZoneRanges.length === 1){
            return filteredZoneRanges[0];
        }
    }    
    return null;
}