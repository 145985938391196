import { IRecordReference, RecordReference } from "shared-models/record-reference";

export interface IUser extends IRecordReference{ 
    name?: string, 
    email?: string, 
    wineryName?: string, 
    wineryImage?: string, 
    defaultWinery?: Winery, 
    availableWineries?: Winery[]};

export class User extends RecordReference{
    email: string;
    defaultWinery: Winery;
    availableWineries: Winery[];
    wineryName: string;
    wineryImage: string;
    
    constructor(data?: IUser) {
        data = data || {};
        super(data);
        this.email = data.email;
        this.wineryName = data.wineryName;
        this.wineryImage = data.wineryImage;
        this.defaultWinery = data.defaultWinery;
        this.availableWineries = (data.availableWineries || []).map((winery)=> new Winery(winery));
    }
}

export interface IWinery extends IRecordReference{
    wineryImage?: string;
}

export class Winery extends RecordReference{    
    wineryImage: string;    
    constructor(data: IWinery){
        data = data || {}
        super(data);
        this.wineryImage = data.wineryImage || "";        
    }
}