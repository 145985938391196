import { SCREEN_SIZE, ScreenSizeUtility } from "./screen-size-utility";
import { autoinject } from "aurelia-framework";

@autoinject
export class ScreenSizeView {
    
    constructor(private screenSizeUtil: ScreenSizeUtility){
    }

    async attached() {
        this.resize();
        $(window).resize(() => {
            this.resize();
        });
    }

    resize() {
        if (window.innerWidth >= 2000) {
            this.screenSizeUtil.currentSize = SCREEN_SIZE.LARGE;
            return;
        }
        if (window.innerWidth >= 1000) {
            this.screenSizeUtil.currentSize = SCREEN_SIZE.DESKTOP;
            return;
        }
        if (window.innerWidth >= 768) {
            this.screenSizeUtil.currentSize =  SCREEN_SIZE.IPAD;
            return;
        }
        this.screenSizeUtil.currentSize = SCREEN_SIZE.MOBILE;
    }
}