import { autoinject } from "aurelia-dependency-injection";
import { StandardView } from "shared-ui/views/standard-view";
import { UserSession } from "user/models/user-session";

@autoinject
export class BottomNavBar extends StandardView{    

    constructor() {
        super(BottomNavBar.name);
    }

    async activate(params, routeData) {
        await super.activate(params, routeData);
    }

    async attached() {
        await super.attached();
    }

    async onClickUserIcon() {
        await this.userSession.logout();
    }

    async onClickHomeIcon() {
        this.router.navigateToRoute(
            'dashboard',
            {},
            { replace: true, trigger: true }
        );
    }
}