import { IItemReference, ItemReference } from "items/models/item-record";
import { IRecordReference, RecordReference } from "shared-models/record-reference";

export interface IActivityDetailRequest {
    allTimeInventory?: boolean,
    dateFrom?: Date,
    dateTo?: Date,
    itemIds?: number[]
}

export class ActivityDetailRequest{    
    allTimeInventory: boolean;
    dateFrom: Date;
    dateTo: Date;
    itemIds: number[];
    constructor(data: IActivityDetailRequest){
        data = data || {}
        this.allTimeInventory = data.allTimeInventory || false;
        this.dateFrom = data.dateFrom ? new Date(data.dateFrom) : null;
        this.dateTo = data.dateTo ? new Date(data.dateTo) : null;
        this.itemIds = data.itemIds || [];
    }
}


export interface IActivityDetailResponse {fileDataBase64?: string};
export class ActivityDetailResponse{    
    fileDataBase64: string;
    constructor(data: IActivityDetailResponse){
        data = data || {}       
        this.fileDataBase64 = data.fileDataBase64;
    }
}

interface IItemLine { item?: IItemReference, quantity?: number, lineNumber: number, addedLine?: boolean };
export class ItemLine {
  item: ItemReference;
  quantity: number;
  lineNumber: number;
  addedLine: boolean;

  constructor(data: IItemLine) {
    this.item = data.item ? new ItemReference(data.item): null;
    this.lineNumber = data.lineNumber;
    this.quantity = data.quantity;
    this.addedLine = false;
  }
}