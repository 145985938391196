import { computedFrom } from "aurelia-binding";

export enum SCREEN_SIZE {
    LARGE,
    DESKTOP,
    IPAD,
    MOBILE
}

export class ScreenSizeUtility{
    currentSize: SCREEN_SIZE;

    constructor(){
        this.currentSize = SCREEN_SIZE.DESKTOP;
    }
   
    @computedFrom('currentSize')
    get isDesktop(): boolean{
        return this.isMedium || this.isLarge;
    }

    @computedFrom('currentSize')
    get isMobile(): boolean{
        return this.isSmall || this.isTiny;
    }

    @computedFrom('currentSize')
    get isLarge(): boolean{
        return this.currentSize === SCREEN_SIZE.LARGE;
    }

    @computedFrom('currentSize')
    get isMedium(): boolean{
        return this.currentSize === SCREEN_SIZE.DESKTOP;
    }

    @computedFrom('currentSize')
    get isSmall(): boolean{
        return this.currentSize === SCREEN_SIZE.IPAD;
    }

    @computedFrom('currentSize')
    get isTiny(): boolean{
        return this.currentSize === SCREEN_SIZE.MOBILE;
    }
}