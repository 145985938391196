import { DefaultService } from "shared-service/default-service";
import { autoinject } from "aurelia-framework";
import { User } from "user/models/user";

@autoinject
export class UserService extends DefaultService {
    constructor() {
        super(UserService.name);
    }

    async getCurrentUser(): Promise<User> {
        try{
            let userResponse = await this.get("user");
            this.logger.info("userResponse :: {}", userResponse);
            return new User({
                id: userResponse["id"],
                name: userResponse["name"],
                email: userResponse["email"],
                defaultWinery: userResponse["defaultWinery"],
                availableWineries: userResponse["wineries"],
                wineryName: userResponse["wineryName"]
            });
        } catch(err){
            this.logger.error("error :: {}", err);
            return null;
        }
        
    }
}