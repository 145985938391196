import { StandardView } from "shared-ui/views/standard-view";
import { AlertNotificationSelection } from "notifications/models/alert-notification";
import { bindable } from "aurelia-framework";

export class AlertNotificationsCurrentView extends StandardView{
    @bindable
    currentAlertNotifications: AlertNotificationSelection[];

    constructor() {
        super(AlertNotificationsCurrentView.name);
        this.currentAlertNotifications = [];
    }

    async attached() {
        await super.attached();
    }

    async activate(params, routeData) {
        await super.activate(params, routeData);
    }
}