import { IRecordReference, RecordReference } from "shared-models/record-reference";
import { IShippingMethodReference, ShippingMethodReference } from "./carrier-record";

export interface IIntegratedShipMethod extends IRecordReference{
    isDefault?: boolean, 
    winery?: IRecordReference, 
    shippingMethod?: IShippingMethodReference,
    integrationType?: RecordReference};
export class IntegratedShipMethod extends RecordReference{   
    isDefault: boolean;
    winery: RecordReference;
    shippingMethod: ShippingMethodReference;
    integrationType: RecordReference;
    constructor(data: IIntegratedShipMethod){
        super(data)  
        this.isDefault = data.isDefault || false;
        this.winery = data.winery ? new RecordReference(data.winery) : null;      
        this.shippingMethod = data.shippingMethod ? new ShippingMethodReference(data.shippingMethod) : null;      
        this.integrationType = data.integrationType ? new RecordReference(data.integrationType) : null;
    }    
}