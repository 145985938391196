import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, observable } from "aurelia-framework";
import { ROUTES } from "routes";
import { SearchResult } from "shared-models/search-result";
import { ChangeOrder } from "change-orders/models/change-order";
import { ChangeOrderService } from "change-orders/services/change_order_service";
import parse from "papaparse";
import { SimpleDateTimeFormatValueConverter } from "shared-ui/converters/simple-datetime-format";
import { computedFrom } from "aurelia-binding";

@autoinject
export class ChangeOrderListView extends StandardView {

    filteredRecords: ChangeOrder[];

    currentSearchResult: SearchResult;

    currentPage: number = 1;

    listDisabled: boolean = false;

    $downloadCSVLink: HTMLElement;

    $exportLink: HTMLElement;

    @observable
    searchInput: string = "";

    constructor(private changeOrderService: ChangeOrderService, private dateTimeConverter: SimpleDateTimeFormatValueConverter) {
        super(ChangeOrderListView.name);
    }

    async activate(params, routeData) {
        try {
            await this._getResults();           
        } catch (err) {
            this.errorMessageUtil.showError(err);
            this.routeToView(ROUTES.DASHBOARD.name);
        }
        await super.activate(params, routeData);
    }

    async attached() {
        await super.attached();
    }

    async _getResults() {
        this.currentSearchResult = await this.changeOrderService.searchRecords(this.currentPage, this.searchInput);
        this.filteredRecords = this.currentSearchResult.records as ChangeOrder[];
    }

    async searchInputChanged(newValue: string, oldValue: string) {
        this.disableList();
        this.currentPage = 1;
        await this._getResults();
        this.enableList();
    }

    async clickPrevious() {
        this.disableList();
        if (this.currentPage > 1) {
            this.currentPage--;
        }
        await this._getResults();
        this.enableList();
    }

    async clickNext() {
        this.disableList();
        if (this.currentPage < this.currentSearchResult.pageCount) {
            this.currentPage++;
        }
        await this._getResults();
        this.enableList();
    }

    disableList() {
        this.listDisabled = true;
    }

    enableList() {
        this.listDisabled = false;
    }

    async export() {
        this.disableView();
        let records = [];
        if (this.searchInput) {
            records = this.filteredRecords
        } else {
            records = await this.changeOrderService.getAllRecords();
        }
        
        let recordsCSV = this.convertToCsv(records);
        $(this.$exportLink).attr('href', `data:text/csv;charset=utf-8, ${escape(recordsCSV)}`);       
        this.$exportLink.click();
        this.enableView();
    }
    
    @computedFrom()
    get createChangeOrderRoute(){
        return ROUTES.CREATE_CHANGE_ORDER.name;
    }
    
    async onClickOpenOrder(changeOrder: ChangeOrder){
        this.disableView();
        if(changeOrder && changeOrder.order && changeOrder.order.id){
            this.routeToView(ROUTES.VIEW_ORDER.name, {id: changeOrder.order.id});
        }
        this.enableView();
    }

    convertToCsv(records: ChangeOrder[]) {
        return parse.unparse(records.map((record) => {
            return {
                "Date": this.dateTimeConverter.toView(record.createDate),
                "Status": record.status,
                "Winery Order Number": record.wineryOrderNumber,
                "Change Type(s)": record.changeRequestsTypes
            };
        }));
    }
    
    viewRecord(record: ChangeOrder){
        this.routeToView(ROUTES.VIEW_CHANGE_ORDER.name, {id: record.id});
    }
}