import { autoinject } from "aurelia-dependency-injection";
import { Logger } from "aurelia-logging";
import { LoginPassport } from "login/models/login-passport";
import { LoginResponse, LOGIN_STATUS } from "login/models/login-response";
import { Auth} from 'aws-amplify';
import { CognitoUser } from "@aws-amplify/auth";
import { LogManager } from "aurelia-framework";

@autoinject
export class LoginService {
    private logger: Logger;

    constructor() {
        this.logger = LogManager.getLogger(LoginService.name);
    }   

    async setNewPassword(loginPassport: LoginPassport, newPassword: string, user: CognitoUser | any) {
        try {
            await Auth.completeNewPassword(
                user,              
                newPassword,
                {}
            );
            return new LoginResponse({
                email: loginPassport.email,
                user: user,
                status: LOGIN_STATUS.SUCCESS
            });
        } catch (err) {
            return new LoginResponse({
                email: loginPassport.email,
                status: LOGIN_STATUS.FAILED,
                message: err.message
            });
        }
    }

    async sendForgotPasswordCode(email: string): Promise<void> {
        await Auth.forgotPassword(email);
    }

    async sendNewPasswordWithCode(email: string, code: string, newPassword: string): Promise<void>{
        await Auth.forgotPasswordSubmit(email, code, newPassword);
    }

    async login(loginPassport: LoginPassport): Promise<LoginResponse> {
        try {
            const user = await Auth.signIn(loginPassport.email, loginPassport.password);
            let status = LOGIN_STATUS.SUCCESS;
            if (user.challengeName) {
                status = LOGIN_STATUS.NONE;
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    status = LOGIN_STATUS.NEWPASSWORD;
                }
            }
            return new LoginResponse({
                email: loginPassport.email,
                user: user,
                status: status
            });

        } catch (err) {
            return new LoginResponse({
                email: loginPassport.email,
                status: LOGIN_STATUS.FAILED,
                message: err.message
            });
        }

    }

    async logout(): Promise<void> {
        return new Promise((resolve, reject) => {
            Auth.signOut({ global: true })
            .then(data => {this.logger.debug(`Logout Success :: ${data}`); resolve()})
            .catch(err => {this.logger.error(`Logout Error :: ${err}`); reject();});
          });
    }
}