import { IRecordReference, RecordReference } from "shared-models/record-reference";

export interface IWineryTransferReference extends IRecordReference{
    transferLocation?: IRecordReference, 
    reference?: string, 
    receiptDate?: Date;
    totalCharges?: number};

export class WineryTransferReference extends RecordReference{   
    transferLocation: RecordReference;
    reference: string;  
    receiptDate: Date;  
    totalCharges: number;   
    constructor(data: IWineryTransferReference){
        super(data);
        this.transferLocation = new RecordReference(data.transferLocation);        
        this.reference = data.reference || "";        
        this.receiptDate = data.receiptDate || null;       
        this.totalCharges = data.totalCharges || 0;        
    }
}

export interface IWineryTransfer extends IWineryTransferReference{items?: IWineryTransferItem[]}
export class WineryTransfer extends WineryTransferReference{
    items?: WineryTransferItem[];

    constructor(data: IWineryTransfer){
        super(data);        
        this.items = (data.items || []).map((item) => new WineryTransferItem(item));
    }
}

export interface IWineryTransferItem extends IRecordReference{itemName?: string, itemId?: string, sku?: string, quantity?: number, totalCharges?: number;};
export class WineryTransferItem extends RecordReference{
    itemName: string;
    itemId: string;
    sku: string;
    quantity: number; 
    totalCharges: number;   

    constructor(data: IWineryTransferItem){
        data = data || {};
        super(data);
        this.itemName = data.itemName;
        this.itemId = data.itemId;
        this.sku = data.sku;
        this.quantity = data.quantity || 0;
        this.totalCharges = data.totalCharges || 0;
    }
}