import { StandardView } from "shared-ui/views/standard-view";
import { bindable, autoinject, computedFrom } from "aurelia-framework";
import { ROUTES } from "routes";
import { SimpleDateFormatValueConverter } from "shared-ui/converters/simple-date-format";
import { Invoice } from "billing/models/invoice";
import { BillingService } from "billing/services/billing-service";

@autoinject
export class InvoiceView extends StandardView {
  @bindable
  invoice: Invoice;

  $viewForm: HTMLElement;

  $inboundFileLink: HTMLElement;

  $outboundFileLink: HTMLElement;

  $storageFileLink: HTMLElement;

  $returnsFileLink: HTMLElement;

  $addrCorrFileLink: HTMLElement;

  constructor(private billingService: BillingService, private simpleDateFormat: SimpleDateFormatValueConverter) {
    super(InvoiceView.name);
    this.invoice = null;
  }

  async activate(params, routeData) {
    try {
      let invoiceId = params["id"];
      if (!invoiceId) {
        throw "No Invoice Id Provided.";
      }
      invoiceId = parseInt(invoiceId, 10);
      let requestResponses = await Promise.all([
        this.billingService.getInvoice(invoiceId)
      ]);
      this.invoice = requestResponses[0];
    } catch (err) {
      this.errorMessageUtil.showError(err);
      this.router.navigateToRoute(ROUTES.DASHBOARD.name, {}, { trigger: true, replace: true });
    }
    await super.activate(params, routeData);
  }

  async attached() {
    this._setupFields();
    await super.attached();
  }

  @computedFrom('invoice')
  get hasInbound(): boolean{
    return !!this.invoice.items.find((item) => item.itemName === "Inbound Fee");
  }

  @computedFrom('invoice')
  get hasOutbound(): boolean{
    return !!this.invoice.items.find((item) => item.itemName === "Outbound Fee");
  }

  @computedFrom('invoice')
  get hasStorage(): boolean{
    return !!this.invoice.items.find((item) => item.itemName === "Storage Fee");
  }

  @computedFrom('invoice')
  get hasReturn(): boolean{
    return !!this.invoice.items.find((item) => item.itemName === "Returns Fee");
  }

  @computedFrom('invoice')
  get hasAddrCorr(): boolean{
    return !!this.invoice.items.find((item) => item.itemName === "Address Corrections Fee");
  }

  _setupFields() {
    $(this.$inboundFileLink).attr('href', `data:text/csv;charset=utf-8, ${escape(this.invoice.inboundFile)}`);
    $(this.$outboundFileLink).attr('href', `data:text/csv;charset=utf-8, ${escape(this.invoice.outboundFile)}`);
    $(this.$storageFileLink).attr('href', `data:text/csv;charset=utf-8, ${escape(this.invoice.storageFile)}`);
    $(this.$returnsFileLink).attr('href', `data:text/csv;charset=utf-8, ${escape(this.invoice.returnsFile)}`);
    $(this.$addrCorrFileLink).attr('href', `data:text/csv;charset=utf-8, ${escape(this.invoice.addressCorrFile)}`);
  }
 
}