import { autoinject } from "aurelia-framework";
import { DefaultService } from "shared-service/default-service";
import { SearchService } from "shared-service/search-service";
import { GetRecordService } from "shared-service/get-record-service";
import { SearchResult } from "shared-models/search-result";
import { SearchRequest } from "shared-models/search-request";
import { GetRecordRequest } from "shared-models/get-record-request";
import { ReturnItemRecord, ReturnRecord, ReturnReference } from "returns/models/return-record";

@autoinject
export class ReturnService extends DefaultService{
    private static readonly RECORD_TYPE = "vf_winery_order_return";

    constructor(
        private searchService: SearchService, 
        private getRecordService: GetRecordService ){
        super(ReturnService.name);
    }

    async searchRecords(searchRequestData: {page?: number, pageSize?: number, filter?: string, fieldFilters?: any[]}): Promise<SearchResult>{
        let searchRequest = new SearchRequest({
            recordType: ReturnService.RECORD_TYPE,
            pageSize: searchRequestData.pageSize || 50,
            page: searchRequestData.page || 1,
            filter: searchRequestData.filter || "",
            fieldFilters: searchRequestData.fieldFilters || []
        });
        return await this.searchService.searchRecords(searchRequest, this._convertResponseToReturnReference, this)
    }

    async getAllRecords(fieldFilters: any[]=[]): Promise<ReturnReference[]>{
        let allItems = [] as ReturnReference[];
        let page = 1;
        let pageSize = 5000;
        let initialSearch = await this.searchRecords({page: page, pageSize: pageSize, fieldFilters: fieldFilters});
        allItems = allItems.concat(initialSearch.records as ReturnReference[]);
        var totalPageCount = initialSearch.pageCount;
        if(totalPageCount > 1){
            while(page < totalPageCount){
                page++;
                let nextSearch = await this.searchRecords({page: page, pageSize: pageSize, fieldFilters: fieldFilters});
                allItems = allItems.concat(nextSearch.records as ReturnReference[]);
            }           
        }        
        return allItems;
    }

    async getRecord(itemId: number): Promise<ReturnRecord>{
        let getRecordRequest = new GetRecordRequest({
            recordType: ReturnService.RECORD_TYPE,
            recordId: itemId
        });
        let getRecordResponse = await this.getRecordService.getRecord(getRecordRequest, this._convertResponseToReturn, this);
        return getRecordResponse.record;
    }

    _convertResponseToReturn(returnResponse): ReturnRecord{
        let reference = this._convertResponseToReturnReference(returnResponse);
        let returnRecord = new ReturnRecord(reference);
        returnRecord.items = (returnResponse["return_lines"] || []).map((itemLine) => this._convertResponseToReturnItemReference(itemLine));
        return returnRecord;
    }

    _convertResponseToReturnReference(returnRefResponse): ReturnReference{
        return new ReturnReference({
            id: returnRefResponse["id"],
            recipientName: returnRefResponse["recipient_name"],
            status: returnRefResponse["status"],
            orderNumber: returnRefResponse["winery_order_number"],
            order: returnRefResponse["winery_order"],
            returnReason: returnRefResponse["return_reason"],
            shipDate: returnRefResponse["shipped_date"],
            returnDate: returnRefResponse["return_date"],
            shippingCarrier: returnRefResponse["shipping_carrier"],
            shippingMethod: returnRefResponse["shipping_method"],
            isWillCall: returnRefResponse["is_will_call"],
            returnShippingCarrier: returnRefResponse["return_shipping_carrier"],
            returnShippingMethod: returnRefResponse["return_shipping_method"],
            returnCharge: returnRefResponse["total_return_charges"],
            notes: returnRefResponse["notes"],
        });
    }

    _convertResponseToReturnItemReference(returnResponse): ReturnItemRecord{
        return new ReturnItemRecord({
            id: returnResponse["id"],
            item: returnResponse["item"],
            shippedQuantity: returnResponse["shipped_quantity"],
            returnedQuantity: returnResponse["returned_quantity"],
            toInventoryQuantity: returnResponse["to_inv_quantity"],
            reshipQuantity: returnResponse["reship_quantity"],
            willCallQuantity: returnResponse["will_call_quantity"],
            remainingQuantity: returnResponse["qty_remaining"]

        });
    }
}