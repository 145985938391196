import { IRecordReference, RecordReference } from "shared-models/record-reference";

export interface ICreditMemo extends IRecordReference{
    createdDate?: Date;
    approvedDate?: Date;
    deniedDate?: Date;
    status?: string;
    refundType?: string;
    wineryOrder?: IRecordReference;
    recipientName?: string;
    wineryOrderNumber?: string;
    vfOrderNumber?: string;
    trackingNumbers?: string;
    checkOrInvoiceNumber?: string;
    vfInvoiceNumber?: string;
    notes?: string;
    creditAmount?: number;    
};
export class CreditMemo extends RecordReference{   
    createdDate: Date;
    approvedDate: Date;
    deniedDate: Date;
    status: string;
    refundType: string;
    wineryOrder: IRecordReference;
    recipientName: string;
    wineryOrderNumber: string;
    vfOrderNumber: string;
    trackingNumbers: string;
    checkOrInvoiceNumber: string;
    vfInvoiceNumber: string;
    notes: string;
    creditAmount: number; 

    constructor(data: ICreditMemo){
        data = data || {}
        super(data);     
        this.createdDate = data.createdDate ? new Date(data.createdDate) : null;
        this.approvedDate = data.approvedDate ? new Date(data.approvedDate) : null;
        this.deniedDate = data.deniedDate ? new Date(data.deniedDate) : null;
        this.status = data.status || "";
        this.refundType = data.refundType || "";
        this.wineryOrder = data.wineryOrder ? new RecordReference(data.wineryOrder): null;
        this.recipientName = data.recipientName || "";
        this.wineryOrderNumber = data.wineryOrderNumber || "";
        this.vfOrderNumber = data.vfOrderNumber || "";
        this.trackingNumbers = data.trackingNumbers || "";
        this.checkOrInvoiceNumber = data.checkOrInvoiceNumber || "";
        this.vfInvoiceNumber = data.vfInvoiceNumber || "";
        this.notes = data.notes || "";
        this.creditAmount = data.creditAmount || 0;
    }
}