import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, bindable, observable, Disposable, BindingEngine } from "aurelia-framework";
import { computedFrom } from "aurelia-binding";
import { CountryReference, StateReference } from "orders/models/country-record";
import { CarrierReference, ShippingMethodReference } from "orders/models/carrier-record";
import { ItemReference } from "items/models/item-record";
import { OrderService } from "orders/services/order_service";
import { ROUTES } from "routes";
import { ShippingAddress } from "shipping-addresses/models/shipping-address-record";
import { ShippingAddressService } from "shipping-addresses/services/shipping_address_service";
import { CountryService } from "orders/services/country_service";
import { ItemService } from "items/services/item_service";
import { Order, OrderItem, OrderLine, OrderReference } from "orders/models/order";
import { CarrierService } from "orders/services/carrier_service";
import { ColdChainZip } from "cold-chain/models/cold-chain-zip";
import { ColdChainService } from "cold-chain/services/cold-chain-service";

@autoinject
export class OrderCreateView extends StandardView {

  orderFormDisabled: boolean = false;

  $stateDropdown: HTMLElement;

  $countryDropdown: HTMLElement;

  $carrierDropdown: HTMLElement;

  $shipMethodDropdown: HTMLElement;

  $shippingAddressDropdown: HTMLElement;

  $useBusinessAddress: HTMLElement;

  $showColdChainInfo: HTMLElement;

  $shelterInPlace: HTMLElement;

  $itemDropdown: HTMLElement;

  $createOrderForm: HTMLElement;

  $itemForm: HTMLElement;

  $futureShipDateCalendar: HTMLElement;

  // $addItemModal: HTMLElement;

  $addAddressModal: HTMLElement;

  $addressForm: HTMLElement;

  $coldChainView: HTMLElement;

  $coldChainLoading: HTMLElement;

  order: Order;

  @observable
  orderNumber: string;

  countries: CountryReference[] = [];

  carriers: CarrierReference[] = [];

  selectedCountry: CountryReference;

  selectedState: StateReference;

  newAddresslabelName: string = "";

  isDuplicateOrder: boolean = false;

  isColdChainCompatible: boolean = false;

  coldChainZip: ColdChainZip = null;

  duplicateOrder: OrderReference = null;  

  @observable
  selectedShippingAddress: ShippingAddress;

  currentItemReference: ItemReference;

  currentItemQuantity: number;

  currentOrderLineNumber: number = 0;

  orderLines: OrderLine[] = [];

  allItems: ItemReference[] = [];

  @bindable
  allAddresses: ShippingAddress[] = [];

  private subscription: Disposable;

  constructor(
    private orderService: OrderService,
    private countryService: CountryService,
    private itemService: ItemService,
    private carrierService: CarrierService,
    private bindingEngine: BindingEngine,
    private coldChainService: ColdChainService,
    private shippingAddressService: ShippingAddressService) {
    super(OrderCreateView.name);
  }

  async activate(params, routeData) {
    try {
      let promiseResponse = await Promise.all([
        this.countryService.getAllCountryRecords(),
        this.carrierService.getRecords(),
        this.shippingAddressService.getAllRecords(),
        this.itemService.getAllRecords()
      ]);
      this.countries = promiseResponse[0];
      this.carriers = promiseResponse[1];
      this.allAddresses = promiseResponse[2];
      this.allItems = promiseResponse[3];
      let addressId = params["addressid"];
      if (addressId) {
        this.selectedShippingAddress = await this.shippingAddressService.getRecord(addressId);
      }
      this.order = new Order({});
      this.addNewOrderItem();
    } catch (err) {
      this.errorMessageUtil.showError(err);
      this.routeToView(ROUTES.DASHBOARD.name);
    }
    await super.activate(params, routeData);
  }

  async attached() {
    await this._setupFields();
    await this._setupForms();
    await super.attached();
    this.subscription = this.bindingEngine
      .propertyObserver(this.order.shippingAddress, 'zip')
      .subscribe(() => this.changedZipcode())
  }

  public detached(): void {
    this.subscription.dispose();
}

  disableOrderForm() {
    this.orderFormDisabled = true;
  }

  enableOrderForm() {
    this.orderFormDisabled = false;
  }

  async _setupForms() {
    await Promise.all([
      $(this.$createOrderForm).form({
        on: 'blur',
        inline: true,
        dateHandling: 'date',
        onSuccess: (event: JQuery.TriggeredEvent<HTMLElement>, fields: any) => {
          var errors = [];
          if (this.orderLines.length === 0) {
            errors.push("Need to provide one or more items.");           
          }
          if(!this.coldChainZip && this.order.isShippingColdChain()){
            errors.push(`Cannot Ship Cold Chain to zipcode: ${this.order.shippingAddress.zip}`);
          }          
          if(errors.length === 0){
            this.createOrder();
          } else{
            $(this.$createOrderForm).form('add errors', errors);
            this.scrollToBottom();
            this.enableView();
          }
        },
        onFailure: (formErrors: string[], fields: any) => {
          if (this.orderLines.length === 0) {
            formErrors.push("Need to provide one or more items.")
          }
          $(this.$createOrderForm).form('add errors', formErrors);
          this.scrollToBottom();
          this.enableView();
        },
        fields: {
          "orderNumber": {
            identifier: 'order-number',
            rules: [
              {
                type: 'empty',
                prompt: 'Need To Provide an Order Number'
              }
            ]
          },
          "email": {
            identifier: 'email',
            rules: [
              {
                type: 'empty',
                prompt: 'Need To Provide an Email'
              },
              {
                type: 'email',
                prompt: 'Email Provided is not valid'
              }
            ]
          },
          "futureShipDate": {
            identifier: 'future-ship-date',
            rules: [
              {
                type: 'empty',
                prompt: 'Need to provide a Date'
              }
            ]
          },
          "selectedCarrier": {
            identifier: 'selected-carrier',
            rules: [
              {
                type: 'empty',
                prompt: 'Need to provide a Carrier'
              }
            ]
          },
          "selectedShippingMethod": {
            identifier: 'selected-ship-method',
            rules: [
              {
                type: 'empty',
                prompt: 'Need to provide a Shipping Method'
              }
            ]
          },
        }
      }),
      $(this.$itemForm).form({
        on: 'blur',
        inline: true,
        dateHandling: 'date',
        onSuccess: (event: JQuery.TriggeredEvent<HTMLElement>, fields: any) => {
          this.addNewOrderItem();
        },
        onFailure: (formErrors: string[], fields: any) => {
          $(this.$itemForm).form('add errors', formErrors);          
          this.enableView();
        },
        fields: {
          "item": {
            identifier: 'item',
            rules: [
              {
                type: 'empty',
                prompt: 'Need To Provide an Item'
              }
            ]
          },
          "quantity": {
            identifier: 'quantity',
            rules: [
              {
                type: 'integer[1..1000000]',
                prompt: 'Need To Provide a positive quantity'
              }
            ]
          }
        }
      }),
      $(this.$addressForm).form({
        on: 'blur',
        inline: true,
        dateHandling: 'date',
        onSuccess: (event: JQuery.TriggeredEvent<HTMLElement>, fields: any) => {
          this.saveNewAddress();          
        },
        onFailure: (formErrors: string[], fields: any) => {
          $(this.$addressForm).form('add errors', formErrors);
          this.enableView();
        },
        fields: {
          "label-name": {
            identifier: 'label-name',
            rules: [
              {
                type: 'empty',
                prompt: 'Need To Provide an Label Name'
              }
            ]
          }
        }
      })
    ]);
  }

  async _setupFields() {
    $(this.$useBusinessAddress).hide();
    $(this.$shelterInPlace).hide();
    await Promise.all([
      $(this.$futureShipDateCalendar).calendar({
        type: 'date',
        disabledDaysOfWeek: [0, 6],
        minDate: new Date(),
        onChange: (date, text, mode) => {
          this.order.futureShipDate = date;
        }
      }),
      $('.ui.checkbox').checkbox(),

      $(this.$stateDropdown).dropdown({
        fullTextSearch: true,
        onChange: async (value, text, $selectedItem) => {
          if (value) {
            this.order.shippingAddress.state = this.currentCountry ? this.currentCountry.states.find((state) => state.id == value) : null;
          } else {
            this.order.shippingAddress.state = null;
          }
        }
      }),

      $(this.$countryDropdown).dropdown({
        fullTextSearch: true,
        onChange: async (value, text, $selectedItem) => {
          if (value) {
            this.order.shippingAddress.country = this.countries.find((country) => country.id == value);
          } else {
            this.order.shippingAddress.country = null;
          }
          await $(this.$stateDropdown).dropdown('clear');
        }
      }),

      $(this.$carrierDropdown).dropdown({
        fullTextSearch: true,
        onChange: async (value, text, $selectedItem) => {
          if (value) {
            this.order.carrier = this.carriers.find((carrier) => carrier.id == value);
            $(this.$useBusinessAddress).show();
            $(this.$shelterInPlace).show();
          } else {
            this.order.carrier = null;
            $(this.$useBusinessAddress).hide();
            $(this.$shelterInPlace).hide();
          }
          await $(this.$shipMethodDropdown).dropdown('clear');
        }
      }),

      $(this.$shipMethodDropdown).dropdown({
        fullTextSearch: true,
        onChange: async (value, text, $selectedItem) => {
          if (value) {
            this.order.shippingMethod = this.currentCarrier ? this.currentCarrier.shippingMethods.find((shippingMethod) => shippingMethod.id == value) : null;
          } else {
            this.order.shippingMethod = null;
          }
        }
      }),

      $(this.$shippingAddressDropdown).dropdown({
        fullTextSearch: true,
        onChange: async (value, text, $selectedItem) => {
            if (value) {
                this.selectedShippingAddress = this.allAddresses ? this.allAddresses.find((address) => address.id == value) : null;
            } else {
                this.selectedShippingAddress = null;
            }
        }
      })
      
    ]);
    if (this.selectedShippingAddress) {
      await $(this.$shippingAddressDropdown).dropdown('set selected', this.selectedShippingAddress.id);
    }
    await $(this.$countryDropdown).dropdown('set selected', this.unitedStates.id);
  }

  @computedFrom('selectedShippingAddress', 'order.email', 'order.shippingAddress.addressee')
  get showNewAddress(): boolean{
    if(this.selectedShippingAddress){
      return false;
    }
    return !!this.order.email && !!this.order.shippingAddress.addressee;
  }

  
  @computedFrom(
    'selectedShippingAddress',
    'order.shippingAddress.addressee',
    'order.shippingAddress.attention',
    'order.shippingAddress.address1',
    'order.shippingAddress.address2',
    'order.shippingAddress.city',
    'order.shippingAddress.state',
    'order.shippingAddress.zip',
    'order.shippingAddress.country',
    'order.email',
    'order.shippingAddress.phone')
  get showUpdateAddress(): boolean {
    if (!this.selectedShippingAddress || !this.order.shippingAddress) {
      return false;
    }
    let stateMatch = true;
    if (this.selectedShippingAddress.state) {
      if (!this.order.shippingAddress.state) {
        stateMatch = false;
      } else {
        stateMatch = this.selectedShippingAddress.state.id == this.order.shippingAddress.state.id;
      }
    } else {
      if (this.order.shippingAddress.state) {
        stateMatch = false;
      }
    }
    let countryMatch = true;
    if (this.selectedShippingAddress.country) {
      if (!this.order.shippingAddress.country) {
        countryMatch = false;
      } else {
        countryMatch = this.selectedShippingAddress.country.id == this.order.shippingAddress.country.id;
      }
    } else {
      if (this.order.shippingAddress.country) {
        countryMatch = false;
      }
    }
    return this.selectedShippingAddress.addressee !== this.order.shippingAddress.addressee ||
      this.selectedShippingAddress.attention !== this.order.shippingAddress.attention ||
      this.selectedShippingAddress.street !== this.order.shippingAddress.address1 ||
      this.selectedShippingAddress.street2 !== this.order.shippingAddress.address2 ||
      this.selectedShippingAddress.city !== this.order.shippingAddress.city ||
      !stateMatch ||
      this.selectedShippingAddress.zipcode !== this.order.shippingAddress.zip ||
      !countryMatch ||
      this.selectedShippingAddress.emails !== this.order.email ||
      this.selectedShippingAddress.phone !== this.order.shippingAddress.phone;
  }

  @computedFrom('countries.length')
  get unitedStates(): CountryReference {
    if (this.countries && this.countries.length > 0) {
      return this.countries.find((country) => country.name === "United States");
    }
    return null;
  }

  @computedFrom('order.shippingAddress.country', 'countries.length')
  get currentCountry(): CountryReference {
    if (!this.order.shippingAddress || !this.order.shippingAddress.country) {
      return null;
    }
    return this.countries.find((country) => country.id === this.order.shippingAddress.country.id)
  }

  @computedFrom('order.carrier', 'carriers.length')
  get currentCarrier(): CarrierReference {
    if (!this.order.carrier) {
      return null;
    }
    return this.carriers.find((carrier) => carrier.id === this.order.carrier.id)
  }

  @computedFrom('currentCountry')
  get states(): StateReference[] {
    if (this.currentCountry) {
      return this.currentCountry.states;
    }
    return [];
  }

  @computedFrom('currentCarrier')
  get shippingMethods(): ShippingMethodReference[] {
    if (this.currentCarrier) {
      return this.currentCarrier.shippingMethods;
    }
    return [];
  }

  async orderNumberChanged(newValue: string, oldValue: string) {

    if (newValue) {
      let foundRecords = await this.orderService.getRecordsByOrderNumber(newValue);
      if (foundRecords.length > 0) {
        this.isDuplicateOrder = true;
        this.duplicateOrder = foundRecords[0];
        this.order.wineryOrderNumber = "";
      } else {
        this.order.wineryOrderNumber = newValue;
        this.duplicateOrder = null;
        this.isDuplicateOrder = false;
      }
    } else {
      this.order.wineryOrderNumber = "";
      this.duplicateOrder = null;
      this.isDuplicateOrder = false;
    }
  }  

  async changedZipcode() {
    this.logger.info(`changedZipcode`);
    this.isColdChainCompatible = false;
    if(this.order.shippingAddress.zip && this.order.shippingAddress.zip.length > 3){
      $(this.$showColdChainInfo).hide(); 
      await this._setColdChainCompaitible();  
    } else{
      $(this.$showColdChainInfo).show();
    }  
  }

  async _setColdChainCompaitible() {
    this.coldChainZip = null;
    if(!this.order.shippingAddress.zip || this.order.shippingAddress.zip.length < 4 || this.order.shippingAddress.isInternational()){
      this.isColdChainCompatible = false;
      return;
    }
    $(this.$coldChainView).hide();
    $(this.$coldChainLoading).show();
    let zip = parseInt(this.order.shippingAddress.zip.split("-")[0],10).toString();
    let searchResults = await this.coldChainService.searchColdChainZips(1, zip, 100);
    this.coldChainZip = searchResults.records.find((coldChainZipRecord) => {
      return coldChainZipRecord.zipcode == zip;
    }) as ColdChainZip;   
    $(this.$coldChainView).show();
    $(this.$coldChainLoading).hide();
    if(this.coldChainZip){
      this.isColdChainCompatible = true;
      return;
    }
    this.isColdChainCompatible = false;
  }

  async selectedShippingAddressChanged(newValue: ShippingAddress, oldValue: ShippingAddress) {
    await $(this.$countryDropdown).dropdown('set selected', this.unitedStates.id);
    await $(this.$stateDropdown).dropdown('clear');
    this.order.shippingAddress.addressee = "";
    this.order.shippingAddress.attention = "";
    this.order.shippingAddress.address1 = "";
    this.order.shippingAddress.address2 = "";
    this.order.shippingAddress.city = "";
    this.order.shippingAddress.zip = "";
    this.order.email = "";
    this.order.shippingAddress.phone = "";
    if (newValue) {
      await $(this.$countryDropdown).dropdown('set selected', newValue.country.id);
      await $(this.$stateDropdown).dropdown('set selected', newValue.state.id);
      this.order.shippingAddress.addressee = newValue.addressee;
      this.order.shippingAddress.attention = newValue.attention;
      this.order.shippingAddress.address1 = newValue.street;
      this.order.shippingAddress.address2 = newValue.street2;
      this.order.shippingAddress.city = newValue.city;
      this.order.shippingAddress.zip = newValue.zipcode;
      this.order.email = newValue.emails;
      this.order.shippingAddress.phone = newValue.phone;
    }
    this.enableOrderForm();
  }

  async showNewAddressModal() {
    this.newAddresslabelName = this.order.shippingAddress.addressee;
    $(this.$addAddressModal).modal('show');
  }

  async updateAddress() {
    this.disableOrderForm();
    try {
      if (!this.selectedShippingAddress) {
        this.errorMessageUtil.showErrorMessage("No Address Selected To Update");
      } else {
        this.selectedShippingAddress.addressee = this.order.shippingAddress.addressee;
        this.selectedShippingAddress.attention = this.order.shippingAddress.attention;
        this.selectedShippingAddress.street = this.order.shippingAddress.address1;
        this.selectedShippingAddress.street2 = this.order.shippingAddress.address2;
        this.selectedShippingAddress.city = this.order.shippingAddress.city;
        this.selectedShippingAddress.state = this.order.shippingAddress.state;
        this.selectedShippingAddress.zipcode = this.order.shippingAddress.zip;
        this.selectedShippingAddress.country = this.order.shippingAddress.country;
        this.selectedShippingAddress.phone = this.order.shippingAddress.phone;
        this.selectedShippingAddress.emails = this.order.email;
        await this.shippingAddressService.updateRecord(this.selectedShippingAddress.id, this.selectedShippingAddress);
      }

    } catch (err) {
      this.errorMessageUtil.showError(err);
    }

    this.enableOrderForm();
  }

  async saveNewAddress() {
    this.disableOrderForm();
    try {
      let newShippingAddress = new ShippingAddress({
        name: this.newAddresslabelName,
        addressee: this.order.shippingAddress.addressee,
        attention: this.order.shippingAddress.attention,
        street: this.order.shippingAddress.address1,
        street2: this.order.shippingAddress.address2,
        city: this.order.shippingAddress.city,
        state: this.order.shippingAddress.state,
        zipcode: this.order.shippingAddress.zip,
        country: this.order.shippingAddress.country,
        phone: this.order.shippingAddress.phone,
        emails: this.order.email,
        isResidential: true
      });
      let addressId = await this.shippingAddressService.createRecord(newShippingAddress);
      newShippingAddress.id = addressId;      
      this.selectedShippingAddress = newShippingAddress;
      await $(this.$shippingAddressDropdown).dropdown('set value', addressId);
      await $(this.$shippingAddressDropdown).dropdown('set text', this.newAddresslabelName);
      $(this.$addAddressModal).modal('hide');
    } catch (err) {
      this.errorMessageUtil.showError(err);
    }
    this.enableOrderForm();
  }

  async clickSaveNewAddress(){
    $(this.$addressForm).form('validate form');
  }

  async addNewOrderItem(orderItem?: OrderLine) {
    if(orderItem){      
      if(!orderItem.item){
        this.errorMessageUtil.showErrorMessage("Need To Provide an Item.");
        return;
      }
      if(!orderItem.quantity || orderItem.quantity < 1){
        this.errorMessageUtil.showErrorMessage("Need To Provide a Quantity of 1 or more.");
        return;
      }
      orderItem.addedLine = true;
    }
    this.orderLines.push(new OrderLine({lineNumber: this.currentOrderLineNumber}));
    this.currentOrderLineNumber++;
  }

  async removeOrderItem(orderLineToRemove) {
    this.orderLines = this.orderLines.filter((orderLine) => orderLine.lineNumber != orderLineToRemove.lineNumber);
  }

  async createOrder() {
    this.scrollToTop();
    this.disableView();
    try {
      this.order.items = (this.orderLines || []).filter((orderLine)=>orderLine.addedLine).map((orderLine) => {
        return new OrderItem({
          id: orderLine.item.productId,
          quantity: orderLine.quantity
        })
      });
      let orderId = await this.orderService.createRecord(this.order);
      this.logger.info(`saved order :: ${orderId}`);
      this.routeToView(ROUTES.VIEW_ORDER.name, { id: orderId });
    } catch (err) {
      this.errorMessageUtil.showError(err);
      this.scrollToBottom();
    }
    this.enableView();
  }

  async clickSaveOrder() {
    this.scrollToTop();
    this.disableView();
    $(this.$createOrderForm).form('validate form');
  }
}