import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, observable, computedFrom } from "aurelia-framework";
import { ROUTES } from "routes";
import { ItemService } from "items/services/item_service";
import { RecordReference } from "shared-models/record-reference";
import { CarrierReference, ShippingMethodReference } from "orders/models/carrier-record";
import { CountryReference, StateReference } from "orders/models/country-record";
import { CountryService } from "orders/services/country_service";
import { CarrierService } from "orders/services/carrier_service";
import { ShippingAddressService } from "shipping-addresses/services/shipping_address_service";
import { ShippingAddress } from "shipping-addresses/models/shipping-address-record";
import { RateCalculatorRequest, RateCalculatorRequestItem, RateCalculatorResponse } from "billing/models/rate-calculator";
import { ZoneRange, getMatchingZone } from "billing/models/zones";
import { BillingService } from "billing/services/billing-service";
import { BillingRate, getMatchingRates } from "billing/models/billing-rate";
import { ItemReference } from "items/models/item-record";
import { ColdChainZip } from "cold-chain/models/cold-chain-zip";
import { ColdChainService } from "cold-chain/services/cold-chain-service";

@autoinject
export class RateCalculator extends StandardView {

    formDisabled: boolean = false;

    $itemDropdown: HTMLElement;

    $countryDropdown: HTMLElement;

    $stateDropdown: HTMLElement;

    $carrierDropdown: HTMLElement;

    $shipMethodDropdown: HTMLElement;

    $zoneDropdown: HTMLElement;

    $shippingAddressDropdown: HTMLElement;

    $showColdChainInfo: HTMLElement;

    $coldChainView: HTMLElement;

    $coldChainLoading: HTMLElement;

    rateCalculatorRequest: RateCalculatorRequest= new RateCalculatorRequest({});

    @observable
    zip: string;

    @observable
    carrier: CarrierReference;

    @observable
    country: CountryReference;

    @observable
    state: StateReference;

    @observable
    shippingMethod: ShippingMethodReference;

    @observable
    zone: RecordReference;

    isColdChainCompatible: boolean = false;

    coldChainZip: ColdChainZip = null;

    zoneRanges: ZoneRange[] = [];

    countries: CountryReference[] = [];

    carriers: CarrierReference[] = [];

    rateCalculatorResponses: RateCalculatorResponse[] = [];

    coldChainResponse: RateCalculatorResponse = null;

    allItems: ItemReference[] = [];

    items: RateCalculatorRequestItem[] = [];

    currentItemLineId: number = 1;

    @observable
    selectedShippingAddress: ShippingAddress;


    constructor(
        private billingService: BillingService,
        private countryService: CountryService,
        private itemService: ItemService,
        private carrierService: CarrierService,
        private coldChainService: ColdChainService,
        private shippingAddressService: ShippingAddressService) {
        super(RateCalculator.name);
    }

    async activate(params, routeData) {
        try {
            // this.listType = params["listType"];
            let promiseResponse = await Promise.all([
                this.countryService.getAllCountryRecords(),
                this.carrierService.getRecords(),
                this.billingService.getAllZoneRanges(),
                this.itemService.getAllRecords()
            ]);
            this.countries = promiseResponse[0];
            this.carriers = promiseResponse[1];
            this.zoneRanges = promiseResponse[2];
            this.allItems = promiseResponse[3];
            this.addItem();
        } catch (err) {
            this.errorMessageUtil.showError(err);
            this.routeToView(ROUTES.DASHBOARD.name);
        }
        await super.activate(params, routeData);
    }

    async attached() {
        await this._setupFields();
        await super.attached();
    } 
    
    getColdChainShipMethod() {
        return this.carriers.find((carrier) => carrier.hasColdChainShipMethod()).getColdChainShipMethod();
    }

    async checkRates() {
        this.disableView();
        this.logger.info(`checkRates`);
        this.rateCalculatorResponses = [];
        this.coldChainResponse = null;
        let responses = await Promise.all([
            this.billingService.getRateCalculatorResponse(new RateCalculatorRequest({
                items: (this.items || []).filter((item) => item.addedLine), 
                zoneid: this.zone ? this.zone.id : null, 
                shipmethodid: this.shippingMethod ? this.shippingMethod.id : null})),
            this.isColdChainCompatible && this.coldChainZip ? this.billingService.getRateCalculatorResponse(new RateCalculatorRequest({
                items: (this.items || []).filter((item) => item.addedLine), 
                zoneid: this.coldChainZip.zone.id, 
                shipmethodid: this.getColdChainShipMethod().id})) : []
        ])
        this.rateCalculatorResponses = responses[0].filter((response)=> !response.isColdChain());
        this.coldChainResponse = responses[1].length > 0 ? responses[1][0] : null;
        this.enableView();
    }

    async _setupFields() {
        await Promise.all([
            $(this.$countryDropdown).dropdown({
                onChange: async (value, text, $selectedItem) => {
                    if (value) {
                        this.country = this.countries.find((country) => country.id == value);
                    } else {
                        this.country = null;
                    }
                }
            }),

            $(this.$stateDropdown).dropdown({
                onChange: async (value, text, $selectedItem) => {
                    if (value) {
                        this.state = this.country ? this.country.states.find((state) => state.id == value) : null;
                    } else {
                        this.state = null;
                    }
                }
            }),

            $(this.$zoneDropdown).dropdown({
                onChange: async (value, text, $selectedItem) => {
                    if (value) {
                        this.zone = this.billingZones.find((billingZone) => billingZone.id == value);
                    } else {
                        this.zone = null;
                    }
                }
            }),

            $(this.$carrierDropdown).dropdown({
                onChange: async (value, text, $selectedItem) => {
                    if (value) {
                        this.carrier = this.carriers.find((carrier) => carrier.id == value);
                    } else {
                        this.carrier = null;
                    }
                    await $(this.$shipMethodDropdown).dropdown('clear');
                }
            }),

            $(this.$shipMethodDropdown).dropdown({
                onChange: async (value, text, $selectedItem) => {
                    if (value) {
                        this.shippingMethod = this.carrier ? this.carrier.shippingMethods.find((shippingMethod) => shippingMethod.id == value) : null;
                    } else {
                        this.shippingMethod = null;
                    }
                }
            }),

            $(this.$shippingAddressDropdown).dropdown(
                {
                    apiSettings: {
                        responseAsync: async (settings, callback) => {
                            let searchResponse = await this.shippingAddressService.searchRecords({page:1, filter:settings["urlData"]["query"]});
                            let addresses = searchResponse.records.map((addressRecord) => {
                                return {
                                    "name": addressRecord.name,
                                    "value": addressRecord.id,
                                    "text": addressRecord.name
                                };
                            });
                            callback({
                                "success": true,
                                "results": addresses
                            });
                        },
                    },
                    onChange: async (value, text, $selectedItem) => {
                        this.disableView();
                        if (value) {
                            this.selectedShippingAddress = await this.shippingAddressService.getRecord(value);
                        } else {
                            this.selectedShippingAddress = null;
                        }
                    }
                })
        ]);
        await $(this.$countryDropdown).dropdown('set selected', this.unitedStates.id);
        this.country = this.unitedStates;
    }

    @computedFrom('country')
    get states(): StateReference[] {
        if (this.country) {
            return this.country.states;
        }
        return [];
    }

    @computedFrom('zoneRanges.length')
    get billingZones(): RecordReference[] {
        let zones = [];
        let zoneIds = [];
        this.zoneRanges.forEach((zoneRange) => {
            if (zoneIds.indexOf(zoneRange.billingZone.id) === -1) {
                zoneIds.push(zoneRange.billingZone.id);
                zones.push(zoneRange.billingZone);
            }
        });
        return zones.sort((a: RecordReference, b:RecordReference)=>{return a.name.localeCompare(b.name)});
    }

    @computedFrom('countries.length')
    get unitedStates(): CountryReference {
        if (this.countries && this.countries.length > 0) {
            return this.countries.find((country) => country.name === "United States");
        }
        return null;
    }

    @computedFrom('carrier')
    get shippingMethods(): ShippingMethodReference[] {
        if (this.carrier) {
            return this.carrier.shippingMethods;
        }
        return [];
    }    

    @computedFrom('items.length', 'matchedRates')
    get totalCharge(): number{
        return 0;
    }

    async selectedShippingAddressChanged(newValue: ShippingAddress, oldValue: ShippingAddress) {
        this.disableView();
        await $(this.$countryDropdown).dropdown('set selected', this.unitedStates.id);
        this.zip = "";
        this.country = null;
        this.state = null;
        if (newValue) {
            await $(this.$countryDropdown).dropdown('set selected', newValue.country.id);
            this.zip = newValue.zipcode;
            this.country = newValue.country;
            this.state = newValue.state;
        }
        this.enableView();
    }

    async zipChanged(newValue: string, oldValue: string) {
        await this._setZone();
        this.isColdChainCompatible = false;
        if(this.zip && this.zip.length > 3){
            $(this.$showColdChainInfo).hide(); 
            await this._setColdChainCompaitible();  
        } else{
            $(this.$showColdChainInfo).show();
        }
    }

    async _setColdChainCompaitible() {
        this.coldChainZip = null;
        if(!this.zip || this.zip.length < 4 || this.isInternational()){
          this.isColdChainCompatible = false;
          return;
        }
        $(this.$coldChainView).hide();
        $(this.$coldChainLoading).show();
        let zip = parseInt(this.zip.split("-")[0],10).toString();
        let searchResults = await this.coldChainService.searchColdChainZips(1, zip, 100);
        this.coldChainZip = searchResults.records.find((coldChainZipRecord) => {
          return coldChainZipRecord.zipcode == zip;
        }) as ColdChainZip;   
        $(this.$coldChainView).show();
        $(this.$coldChainLoading).hide();
        if(this.coldChainZip){
          this.isColdChainCompatible = true;
          return;
        }
        this.isColdChainCompatible = false;
    }

    isInternational(): boolean{
        if(!this.country){
            return false;
        }
        if(this.country.name.toUpperCase() === "UNITED STATES"){
            return false;
        }
        return true;
    }

    async carrierChanged(newValue: CarrierReference, oldValue: CarrierReference) {
        await this._setZone();
    }

    async countryChanged(newValue: CountryReference, oldValue: CountryReference) {
        await this._setZone();
    }

    async stateChanged(newValue: StateReference, oldValue: StateReference) {
        await this._setZone();
    }

    async zoneChanged(newValue: RecordReference, oldValue: RecordReference) {
        if(this.zone){
            $(this.$zoneDropdown).dropdown('set selected', this.zone.id);                       
        } else{
            $(this.$zoneDropdown).dropdown('clear');
        }
    }

    async _setZone() {
        this.disableView();
        this.zone = null;
        let zoneRange = getMatchingZone(this.zoneRanges, this.carrier, this.zip, this.country, this.state);
        if (zoneRange) {
            this.zone = zoneRange.billingZone;
        }
        this.enableView();
    }    

    async addItem(item?: RateCalculatorRequestItem) {
        if(item){      
          if(!item.itemReference){
            this.errorMessageUtil.showErrorMessage("Need To Provide an Item.");
            return;
          }
          if(!item.quantity || item.quantity < 1){
            this.errorMessageUtil.showErrorMessage("Need To Provide a Quantity of 1 or more.");
            return;
          }
          item.addedLine = true;
        }
        this.items.push(new RateCalculatorRequestItem({id: this.currentItemLineId}));
        this.currentItemLineId++;
    }   

    removeItem(lineId){
        this.items = this.items.filter((item) => item.id != lineId);
    }
}