import { IRecordReference, RecordReference } from "shared-models/record-reference";
import { computedFrom } from "aurelia-framework";
import { CarrierReference, ShippingMethodReference, IShippingMethodReference, ICarrierReference } from "./carrier-record";
import moment from 'moment';
import { ItemReference } from "items/models/item-record";

export function convertResponseToOrderReference(orderResponse: any): OrderReference{
    return new OrderReference({
        id: orderResponse["id"],
        status: orderResponse["winery_order_status"],
        vinfillmentOrderNumber: orderResponse["name"],
        wineryOrderNumber: orderResponse["winery_order_number"],
        carrier: orderResponse["shipping_carrier"],
        shippingMethod: orderResponse["shipping_method"],
        futureShipDate: orderResponse["future_ship_date"] ? moment(orderResponse["future_ship_date"], "YYYY-MM-DD").toDate(): null,
        email: orderResponse["recipient_emails"] || "",
        declaredValue: orderResponse["declared_value"],
        giftMessage: orderResponse["gift_message"] || "",
        includeIce: orderResponse["include_ice_packs"],
        weatherHold: orderResponse["weather_hold"],
        shippingInstructions: orderResponse["special_shipping_instructions"] || "",
        trackingLink: orderResponse["tracking_link"] || "",
        trackingNumberList: orderResponse["tracking_number_list"] || "",
        isColdChainCompatible: orderResponse["is_cold_chain_compatible"],
        coldChainDeliveryDay: orderResponse["cold_chain_delivery_day"],
        coldChainDeliveryTime: orderResponse["cold_chain_delivery_time"],
        coldChainZone: orderResponse["cold_chain_zone"],
        createdByUser: orderResponse["created_by_user"],
        shippingAddress: {
            addressee: orderResponse["shipping_addressee"] || "",
            attention: orderResponse["shipping_attention"] || "",
            address1: orderResponse["shipping_street"] || "",
            address2: orderResponse["shipping_street2"] || "",
            city: orderResponse["shipping_city"] || "",
            state: orderResponse["shipping_state"],
            zip: orderResponse["shipping_zip"] || "",
            country: orderResponse["shipping_country"],
            phone: orderResponse["shipping_phone"] || ""
        }
    });
}

export function convertResponseToOrderLine(orderLineResponse: any): OrderItem{
    let order = new OrderItem({
        id: orderLineResponse["id"],
        orderId: orderLineResponse["order_id"] ? orderLineResponse["order_id"].id : "",
        orderNumber: orderLineResponse["order_id"] ? orderLineResponse["order_id"].name : "",
        itemName: orderLineResponse["product_template_id"] ? orderLineResponse["product_template_id"].name : "",
        itemId: orderLineResponse["product_template_id"] ? orderLineResponse["product_template_id"].id : "",
        sku: orderLineResponse["winery_sku"],
        quantity: orderLineResponse["product_uom_qty"],
        committed: orderLineResponse["qty_committed"],
        backordered: orderLineResponse["qty_backordered"],
        available: orderLineResponse["qty_available"],
        shipped: orderLineResponse["qty_delivered"]
    });
    return order;
}

export function convertResponseToOrder(orderResponse: any): Order{
    let orderReference = convertResponseToOrderReference(orderResponse);
    let order = new Order(orderReference);    
    order.items = (orderResponse["order_line"] || []).map(convertResponseToOrderLine);
    order.trackingNumbers = (orderResponse["tracking_numbers"] || []).map((trackingNumber)=> {
        return {
            id: trackingNumber["id"],
            name: trackingNumber["name"],      
            active: trackingNumber["active"],
            carrierTrackingLink: trackingNumber["carrier_tracking_link"],
            currentCarrierStatus: trackingNumber["current_carrier_status"],
            numberOfAttempts: trackingNumber["number_of_attempts"],
            estimatedDelivery: trackingNumber["estimated_delivery"] ? moment(trackingNumber["estimated_delivery"], "YYYY-MM-DD HH:mm:ss").toDate(): null,
            actualDelivery: trackingNumber["actual_delivery"] ? moment(trackingNumber["actual_delivery"], "YYYY-MM-DD HH:mm:ss").toDate(): null,
            isReturnTracking: trackingNumber["is_return_tracking"],
            returnTrackingNumber: trackingNumber["return_tracking_number"]
        }
    });
    return order;
}

export interface IOrderReference {
    id?: number, 
    wineryOrderNumber?: string, 
    status?: string, 
    vinfillmentOrderNumber?: string, 
    carrier?: ICarrierReference, 
    shippingMethod?: IShippingMethodReference, 
    futureShipDate?: Date, 
    shipDate?: Date,
    shippingInstructions?: string,
    giftMessage?: string,
    includeIce?: boolean,
    weatherHold?: boolean,
    shippingAddress?: IAddress,
    trackingLink?: string,
    trackingNumberList?: string,
    isColdChainCompatible?: boolean,
    coldChainDeliveryDay?: IRecordReference,
    coldChainDeliveryTime?: string,
    coldChainZone?: IRecordReference,
    createdByUser?: IRecordReference,
    email?: string,
    declaredValue?: number
};
export class OrderReference {   
    id?: number;
    wineryOrderNumber?: string;
    status?: string;
    vinfillmentOrderNumber?: string;
    carrier?: CarrierReference;
    shippingMethod?: ShippingMethodReference;
    futureShipDate?: Date;
    shipDate?: Date;
    shippingInstructions?: string;
    giftMessage?: string;
    includeIce?: boolean;
    weatherHold?: boolean;
    shippingAddress?: Address;
    trackingLink?: string;
    trackingNumberList?: string;
    isColdChainCompatible?: boolean;
    coldChainDeliveryDay?: RecordReference;
    coldChainDeliveryTime?: string;
    coldChainZone?: RecordReference;
    createdByUser?: RecordReference;
    email?: string;
    declaredValue?: number;
    constructor(data: IOrder){
        this.id = data.id;      
        this.wineryOrderNumber = data.wineryOrderNumber;      
        this.status = data.status;      
        this.vinfillmentOrderNumber = data.vinfillmentOrderNumber;      
        this.carrier = data.carrier ? new CarrierReference(data.carrier) : null;
        this.shippingMethod = data.shippingMethod ? new ShippingMethodReference(data.shippingMethod) : null;
        this.futureShipDate = data.futureShipDate;
        this.shipDate = data.shipDate;
        this.shippingInstructions = data.shippingInstructions;
        this.giftMessage = data.giftMessage || "";
        this.includeIce = data.includeIce || false;
        this.weatherHold = data.weatherHold || false;
        this.shippingAddress = new Address(data.shippingAddress); 
        this.trackingLink = data.trackingLink || "";
        this.trackingNumberList = data.trackingNumberList || "";
        this.isColdChainCompatible = data.isColdChainCompatible || false;
        this.coldChainDeliveryDay = data.coldChainDeliveryDay ? new RecordReference(data.coldChainDeliveryDay) : null;
        this.coldChainDeliveryTime = data.coldChainDeliveryTime || "";
        this.coldChainZone = data.coldChainZone ? new RecordReference(data.coldChainZone) : null;
        this.createdByUser = data.createdByUser ? new RecordReference(data.createdByUser) : null;
        this.email = data.email || "";
        this.declaredValue = data.declaredValue || 0;
    }

    @computedFrom('carrier')
    get carrierName(): string {
        return this.carrier ? this.carrier.name : "";
    }

    @computedFrom('shippingMethod')
    get shippingMethodName(): string {
        return this.shippingMethod ? this.shippingMethod.name : "";
    }    

    isPendingApproval(): boolean{
        if(this.status === "Pending Approval"){
            return true;
        }
        return false;
    }

    isPendingFulfillment(): boolean{
        if(this.status === "Pending Fulfillment"){
            return true;
        }
        return false;
    }

    isVoided(): boolean{
        if(this.status === "Voided"){
            return true;
        }
        return false;
    }

    isShippingColdChain(): boolean{
        if(this.shippingMethod && this.shippingMethod.name.toUpperCase() === "COLD CHAIN"){
            return true;
        }
        return false;
    }
}

export interface IOrder extends IOrderReference{items?: IOrderItem[],trackingNumbers?: ITrackingNumber[]};
export class Order extends OrderReference {   
    items?: OrderItem[];
    trackingNumbers?: TrackingNumber[];
    constructor(data: IOrder){
        super(data);
        this.items = (data.items || []).map((item) => new OrderItem(item));        
        this.trackingNumbers = (data.trackingNumbers || []).map((trackingNumber) => new TrackingNumber(trackingNumber));        
    }    
}

export interface IOrderItem {id?: string, orderId?: number, orderNumber?: string, itemName?: string, itemId?: string, sku?: string, quantity?: number, committed?: number, backordered?: number, available?: number, shipped?: number};
export class OrderItem {
    id: string;
    orderId: number;
    orderNumber: string;
    itemName: string;
    itemId: string;
    sku: string;
    quantity: number;
    committed: number;
    backordered: number;
    available: number;
    shipped: number;

    constructor(data: IOrderItem){
        data = data || {};
        this.id = data.id;
        this.orderId = data.orderId;
        this.orderNumber = data.orderNumber;
        this.itemName = data.itemName;
        this.itemId = data.itemId;
        this.sku = data.sku;
        this.quantity = data.quantity || 0;
        this.committed = data.committed || 0;
        this.backordered = data.backordered || 0;
        this.available = data.available || 0;
        this.shipped = data.shipped || 0;
    }
}

export interface ITrackingNumber {id?: string, name?: string, active?: boolean, carrierTrackingLink?: string, currentCarrierStatus?: string,numberOfAttempts?: number, estimatedDelivery?: Date, actualDelivery?: Date, isReturnTracking?: boolean, returnTrackingNumber?: IRecordReference};
export class TrackingNumber {
    id: string;
    name: string;
    active: boolean;
    carrierTrackingLink: string;
    currentCarrierStatus: string;
    numberOfAttempts: number;
    estimatedDelivery: Date;
    actualDelivery: Date;
    isReturnTracking: boolean;
    returnTrackingNumber: RecordReference;

    constructor(data: ITrackingNumber){
        data = data || {};
        this.id = data.id;
        this.name = data.name;
        this.active = data.active || false;
        this.carrierTrackingLink = data.carrierTrackingLink || "";
        this.currentCarrierStatus = data.currentCarrierStatus || "";
        this.numberOfAttempts = data.numberOfAttempts || 0;
        this.estimatedDelivery = data.estimatedDelivery;
        this.actualDelivery = data.actualDelivery;
        this.isReturnTracking = data.isReturnTracking || false;
        this.returnTrackingNumber = data.returnTrackingNumber ? new RecordReference(data.returnTrackingNumber) : null;
    }
}

export interface IAddress {addressee?: string, attention?: string, address1?: string, address2?: string, city?: string, state?: IRecordReference, zip?: string, country?: IRecordReference,phone?: string};
export class Address {
    addressee: string;
    attention: string;
    address1: string;
    address2: string;
    city: string;
    state: RecordReference;
    zip: string;
    country: RecordReference;
    phone: string;

    constructor(data: IAddress){
        data = data || {};
        this.addressee = data.addressee || "";      
        this.attention = data.attention || "";      
        this.address1 = data.address1 || "";      
        this.address2 = data.address2 || "";      
        this.city = data.city || "";      
        this.state = data.state ? new RecordReference(data.state) : null;      
        this.zip = data.zip || "";      
        this.country = data.country ? new RecordReference(data.country) : null;      
        this.phone = data.phone || "";      
    }

    isInternational(): boolean{
        if(!this.country){
            return false;
        }
        if(this.country.name.toUpperCase() === "UNITED STATES"){
            return false;
        }
        return true;
    }

    _addNewLine(data) {
        return data ? data + "\r\n" : "";
    }

    _addBankSpaceAfter(data) {
        return data ? data + " " : ""
    }

    @computedFrom('country')
    get countryName(): string {
        return this.country ? this.country.name : "";
    }

    @computedFrom('state')
    get stateName(): string {
        return this.state ? this.state.name : "";
    }

    @computedFrom('attention', 'addressee', 'address1', 'address2', 'city', 'stateName', 'zip', 'countryName', 'phone')
    get addressForm(): string {        
        return this._addNewLine(this.attention) +
            this._addNewLine(this.addressee) +
            this._addNewLine(this.address1) +
            this._addNewLine(this.address2) +
            this._addBankSpaceAfter(this.city) + this._addBankSpaceAfter(this.stateName) + this._addBankSpaceAfter(this.zip) + "\r\n" +
            this._addNewLine(this.countryName) +
            this._addNewLine(this.phone);
    }
}

interface IOrderLine { item?: ItemReference, quantity?: number, lineNumber: number, addedLine?: boolean };
export class OrderLine {
  item: ItemReference;
  quantity: number;
  lineNumber: number;
  addedLine: boolean;

  constructor(data: IOrderLine) {
    this.item = data.item ? new ItemReference(data.item): null;
    this.lineNumber = data.lineNumber;
    this.quantity = data.quantity;
    this.addedLine = false;
  }
}
