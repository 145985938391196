export interface ISearchResult {recordType: string, totalRecords: number, pageSize: number, pageCount: number, filter?: string, records?: any[]};
export class SearchResult{
    recordType: string;
    totalRecords: number;
    pageSize: number;
    pageCount: number;
    filter?: string;
    records?: any[];
    constructor(data: ISearchResult){
        this.recordType = data.recordType;
        this.totalRecords = data.totalRecords;
        this.pageSize = data.pageSize;
        this.pageCount = data.pageCount;
        this.filter = data.filter || "";
        this.records = data.records || [];
    }
}