import { IRecordReference, RecordReference } from "shared-models/record-reference";
import {IStateReference, StateReference} from "orders/models/country-record"

export interface IColdChainZip extends IRecordReference{zipcode?: string, city?: string, state?: IStateReference, deliveryDay?: IRecordReference, deliveryTime?: string, hub?: string, route?: string, zone?: RecordReference};
export class ColdChainZip extends RecordReference{
    zipcode?: string;
    city?: string;
    state?: StateReference;
    deliveryDay?: RecordReference;
    deliveryTime?: string;
    hub?: string;
    route?: string;
    zone?: RecordReference;
    constructor(data: IColdChainZip){
        data = data || {}
        super(data)
        this.zipcode = data.zipcode || "";        
        this.city = data.city || "";        
        this.state = data.state ? new StateReference(data.state): null;
        this.deliveryDay = data.deliveryDay ? new RecordReference(data.deliveryDay): null;
        this.deliveryTime = data.deliveryTime || "";
        this.hub = data.hub || "";
        this.route = data.route || "";
        this.zone = data.zone ? new RecordReference(data.zone): null;
    }

    zoneNumber(){
        if(!this.zone){
            return "";
        }
        let zoneSplit = this.zone.name.split("Zone ");
        if(zoneSplit.length === 2){
            return zoneSplit[1].trim();
        }
        return this.zone.name;
    }
}