import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, bindable, computedFrom } from "aurelia-framework";
import { ItemReference } from "items/models/item-record";
import { OrderLine } from "orders/models/order";

@autoinject
export class OrderCreateItemView extends StandardView {

    formDisabled: boolean = false;

    @bindable
    orderLine: OrderLine;

    @bindable
    allItems: ItemReference[] = [];

    $itemDropdown: HTMLElement;

    constructor() {
        super(OrderCreateItemView.name);
    }

    async activate(params, routeData) {
        await super.activate(params, routeData);
    }

    async attached() {
        await this._setupFields();
        await super.attached();
    }

    disableForm() {
        this.formDisabled = true;
    }

    enableForm() {
        this.formDisabled = false;
    }
    
    async _setupFields() {
        await Promise.all([
            $(this.$itemDropdown).dropdown({
                fullTextSearch: true,
                onChange: async (value, text, $selectedItem) => {
                    if (value) {
                        this.orderLine.item = this.allItems ? this.allItems.find((item) => item.id == value) : null;
                    } else {
                        this.orderLine.item = null;
                    }
                }
            })]);
    }

}