import { StandardView } from "shared-ui/views/standard-view";
import { bindable, autoinject, computedFrom } from "aurelia-framework";
import { ROUTES } from "routes";
import { ImportOrderBatch } from "orders/models/import-order-batch";
import { ImportOrderService } from "orders/services/import-orders-service";

@autoinject
export class OrderBatchView extends StandardView {
  @bindable
  orderBatch: ImportOrderBatch = null;

  $viewForm: HTMLElement;

  $csvFileLink: HTMLElement;

  orderBatchId: number;

  constructor(private importOrderService: ImportOrderService) {
    super(OrderBatchView.name);
  }

  async activate(params, routeData) {
    try {
      this.orderBatchId = params["id"] ? parseInt(params["id"]) : null;
      if (!this.orderBatchId) {
        throw "Need to provide the Order Batch Id."
      }
      this.orderBatch = await this.importOrderService.getBatchRecord(this.orderBatchId);
    } catch (err) {
      this.errorMessageUtil.showError(err);
      this.routeToView(ROUTES.DASHBOARD.name);
    }
    await super.activate(params, routeData);
  }

  async attached() {
    this._setupFields();
    await super.attached();
  }

  _setupFields() {
    $(this.$csvFileLink).attr('href', `data:text/csv;charset=utf-8, ${escape(this.orderBatch.csvFileData)}`);
  }

}