import { IRecordReference, RecordReference } from "shared-models/record-reference";


export interface IImportOrderBatch extends IRecordReference{
    createdByUser?: IRecordReference,
    createDate?: Date,
    status?: string,
    integrationType?: IRecordReference,
    integrationPartner?: IRecordReference,
    importOrdersSetup?: IRecordReference,
    shipmentDetailNumber?: string,
    csvFileData?: string, 
    csvFileName?: string, 
    numberOfOrders?: number,
    numberOfOrdersPending?: number,
    numberOfOrdersUploaded?: number,
    numberOfOrdersCancelled?: number,
    errorMessage?: string};

export class ImportOrderBatch extends RecordReference{
    createdByUser?: RecordReference;
    createDate?: Date;
    status?: string;
    integrationType?: RecordReference;
    integrationPartner?: RecordReference;
    importOrdersSetup?: RecordReference;
    shipmentDetailNumber?: string;
    csvFileData?: string;
    csvFileName?: string;
    numberOfOrders?: number;
    numberOfOrdersPending?: number;
    numberOfOrdersUploaded?: number;
    numberOfOrdersCancelled?: number;
    errorMessage?: string;    
    
    constructor(data: IImportOrderBatch){
        super(data)
        this.createdByUser = data.createdByUser ? new RecordReference(data.createdByUser) : null;
        this.createDate = data.createDate;
        this.status = data.status;
        this.integrationType = data.integrationType ? new RecordReference(data.integrationType) : null;
        this.integrationPartner = data.integrationPartner ? new RecordReference(data.integrationPartner) : null;
        this.importOrdersSetup = data.importOrdersSetup ? new RecordReference(data.importOrdersSetup) : null;
        this.shipmentDetailNumber = data.shipmentDetailNumber;
        this.csvFileData = data.csvFileData;
        this.csvFileName = data.csvFileName;
        this.numberOfOrders = data.numberOfOrders || 0;
        this.numberOfOrdersPending = data.numberOfOrdersPending || 0;
        this.numberOfOrdersUploaded = data.numberOfOrdersUploaded || 0;
        this.numberOfOrdersCancelled = data.numberOfOrdersCancelled || 0;
        this.errorMessage = data.errorMessage || "";
        
    }
}
