import { IRecordReference, RecordReference } from "shared-models/record-reference";
import { StateReference, CountryReference, IStateReference, ICountryReference } from "../../orders/models/country-record";
import { computedFrom } from "aurelia-framework";

export interface IShippingAddress extends IRecordReference { 
    createdByUser?: IRecordReference,
    lastUpdatedByUser?: IRecordReference,
    isResidential?: boolean, 
    attention?: string, 
    addressee?: string, 
    street?: string, 
    street2?: string, 
    zipcode?: string, 
    city?: string, 
    state?: IStateReference, 
    country?: ICountryReference,
    phone?: string, 
    emails?: string };
export class ShippingAddress extends RecordReference {
    createdByUser?: RecordReference;
    lastUpdatedByUser?: RecordReference;
    isResidential?: boolean;
    attention?: string;
    addressee?: string;
    street?: string;
    street2?: string;
    zipcode?: string;
    city?: string;
    state?: StateReference;
    country?: CountryReference;
    phone?: string;
    emails?: string;

    constructor(data: IShippingAddress) {
        super(data)
        this.createdByUser = data.createdByUser ? new RecordReference(data.createdByUser) : null;
        this.lastUpdatedByUser = data.lastUpdatedByUser ? new RecordReference(data.lastUpdatedByUser) : null;
        this.isResidential = data.isResidential || false;
        this.attention = data.attention || "";
        this.addressee = data.addressee || "";
        this.street = data.street || "";
        this.street2 = data.street2 || "";
        this.zipcode = data.zipcode || "";
        this.city = data.city || "";
        this.state = data.state ? new StateReference(data.state) : null;
        this.country = data.country ? new CountryReference(data.country) : null;
        this.phone = data.phone || "";
        this.emails = data.emails || "";
    }

    _addNewLine(data) {
        return data ? data + "\r\n" : "";
    }

    _addBankSpaceAfter(data) {
        return data ? data + " " : ""
    }

    @computedFrom('country')
    get countryName(): string {
        return this.country ? this.country.name : "";
    }

    @computedFrom('state')
    get stateName(): string {
        return this.state ? this.state.name : "";
    }

    @computedFrom('attention', 'addressee', 'street', 'street2', 'city', 'state', 'zipcode', 'country', 'phone', 'emails')
    get addressForm(): string {        
        return this._addNewLine(this.attention) +
            this._addNewLine(this.addressee) +
            this._addNewLine(this.street) +
            this._addNewLine(this.street2) +
            this._addBankSpaceAfter(this.city) + this._addBankSpaceAfter(this.stateName) + this._addBankSpaceAfter(this.zipcode) + "\r\n" +
            this._addNewLine(this.countryName) +
            this._addNewLine(this.phone) +
            this._addNewLine(this.emails);
    }
}