import { autoinject } from "aurelia-framework";
import { DefaultService } from "shared-service/default-service";
import { SearchService } from "shared-service/search-service";
import { GetRecordService } from "shared-service/get-record-service";
import { SearchResult } from "shared-models/search-result";
import { SearchRequest } from "shared-models/search-request";
import { GetRecordRequest } from "shared-models/get-record-request";
import { WineryTransfer, WineryTransferItem, WineryTransferReference } from "items/models/winery-transfer";

@autoinject
export class WineryTransferService extends DefaultService{
    private static readonly RECORD_TYPE = "winery.transfer";

    constructor(
        private searchService: SearchService, 
        private getRecordService: GetRecordService ){
        super(WineryTransferService.name);
    }

    async searchRecords(searchRequestData: {page?: number, pageSize?: number, filter?: string, fieldFilters?: any[]}): Promise<SearchResult>{
        let searchRequest = new SearchRequest({
            recordType: WineryTransferService.RECORD_TYPE,
            pageSize: searchRequestData.pageSize || 50,
            page: searchRequestData.page || 1,
            filter: searchRequestData.filter || "",
            fieldFilters: searchRequestData.fieldFilters || []
        });
        return await this.searchService.searchRecords(searchRequest, this._convertResponseToWineryTransferReference)
    }

    async getAllRecords(fieldFilters: any[]=[]): Promise<WineryTransferReference[]>{
        let allRecords = [] as WineryTransferReference[];
        let page = 1;
        let pageSize = 5000;
        let initialSearch = await this.searchRecords({page: page, pageSize: pageSize, fieldFilters: fieldFilters});
        allRecords = allRecords.concat(initialSearch.records as WineryTransferReference[]);
        var totalPageCount = initialSearch.pageCount;
        if(totalPageCount > 1){
            while(page < totalPageCount){
                page++;
                let nextSearch = await this.searchRecords({page: page, pageSize: pageSize, fieldFilters: fieldFilters});
                allRecords = allRecords.concat(nextSearch.records as WineryTransferReference[]);
            }           
        }        
        return allRecords;
    }

    async getRecord(id: number): Promise<WineryTransfer>{
        let getRecordRequest = new GetRecordRequest({
            recordType: WineryTransferService.RECORD_TYPE,
            recordId: id
        });
        let getRecordResponse = await this.getRecordService.getRecord(getRecordRequest, this._convertResponseToWineryTransfer, this);
        return getRecordResponse.record;
    }

    _convertResponseToWineryTransfer(response): WineryTransfer{
        let reference = this._convertResponseToWineryTransferReference(response);
        let wineryTransfer = new WineryTransfer(reference);
        wineryTransfer.items = (response["items"] || []).map((item) => new WineryTransferItem({
            id: item["id"],
            name: item["id"],
            itemId: item["product_tmpl_id"]["id"],
            itemName: item["product_tmpl_id"]["name"],
            quantity: item["quantity_related"],
            sku: item["winery_sku"],
            totalCharges: item["billing_charges"]
        }));
        return wineryTransfer;
    }

    _convertResponseToWineryTransferReference(response): WineryTransferReference{
        return new WineryTransferReference({
            id: response["id"],
            name: response["name"],
            transferLocation: response["transfer_location"],
            receiptDate: response["date_done"],
            reference: response["origin"],
            totalCharges: response["total_charges"],
        });
    }
}