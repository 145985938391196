import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, observable, computedFrom } from "aurelia-framework";
import { ROUTES } from "routes";
import { SearchResult } from "shared-models/search-result";
import parse from "papaparse";
import { SimpleDateFormatValueConverter } from "shared-ui/converters/simple-date-format";
import { BillingService } from "billing/services/billing-service";
import { CreditMemo } from "billing/models/credit-memo";


@autoinject
export class CreditMemoListView extends StandardView {

    creditMemos: CreditMemo[];

    currentSearchResult: SearchResult;

    currentPage: number = 1;

    listDisabled: boolean = false;    

    $downloadCSVLink: HTMLElement;

    $exportLink: HTMLElement;

    @observable
    searchInput: string = "";

    listType: string;

    constructor(private billingService: BillingService, private simpleDateFormat: SimpleDateFormatValueConverter) {
        super(CreditMemoListView.name);
    }

    async activate(params, routeData) {
        try {
            this.listType = params["listType"];
            await this._getResults();           
        } catch (err) {
            this.errorMessageUtil.showError(err);
            this.routeToView(ROUTES.DASHBOARD.name);
        }
        await super.activate(params, routeData);
    }

    async attached() {
        await super.attached();
    }

    async _getResults() {
        let fieldFilters = [];        
        this.logger.info(`fieldFilters :: ${JSON.stringify(fieldFilters)}`);
        this.currentSearchResult = await this.billingService.searchCreditMemos({page: this.currentPage, filter: this.searchInput, fieldFilters: fieldFilters});
        this.creditMemos = this.currentSearchResult.records as CreditMemo[];
    }

    async searchInputChanged(newValue: string, oldValue: string) {
        this.disableList();
        this.currentPage = 1;
        await this._getResults();
        this.enableList();
    }

    async clickPrevious() {
        this.disableList();
        if (this.currentPage > 1) {
            this.currentPage--;
        }
        await this._getResults();
        this.enableList();
    }

    async clickNext() {
        this.disableList();
        if (this.currentPage < this.currentSearchResult.pageCount) {
            this.currentPage++;
        }
        await this._getResults();
        this.enableList();
    }

    async exportCreditMemos() {
        this.disableView();
        let creditMemos = [];
        if (this.searchInput) {
            creditMemos = this.creditMemos;
        } else {
            creditMemos = await this.billingService.getAllCreditMemos();
        }
        
        let csvData = this.convertToCsv(creditMemos);
        $(this.$exportLink).attr('href', `data:text/csv;charset=utf-8, ${escape(csvData)}`);       
        this.$exportLink.click();
        this.enableView();
    }

    convertToCsv(creditMemos: CreditMemo[]) {
        if(creditMemos.length === 0){
            return "Name\n";
        }
        return parse.unparse(creditMemos.map((creditMemo) => {
            return {
                "Name": creditMemo.name
            };
        }));
    }    

    @computedFrom()
    get viewCreditMemoRoute(){
        return ROUTES.VIEW_CREDIT_MEMO.name;
    }

    disableList() {
        this.listDisabled = true;
    }

    enableList() {
        this.listDisabled = false;
    }
}