import { StandardView } from "shared-ui/views/standard-view";
import { bindable, autoinject, computedFrom } from "aurelia-framework";
import { ROUTES } from "routes";
import { SimpleDateFormatValueConverter } from "shared-ui/converters/simple-date-format";
import { BillingService } from "billing/services/billing-service";
import { CreditMemo } from "billing/models/credit-memo";

@autoinject
export class CreditMemoView extends StandardView {
  @bindable
  creditMemo: CreditMemo;

  $viewForm: HTMLElement;

  $inboundFileLink: HTMLElement;

  $outboundFileLink: HTMLElement;

  $storageFileLink: HTMLElement;

  $returnsFileLink: HTMLElement;

  $addrCorrFileLink: HTMLElement;

  constructor(private billingService: BillingService, private simpleDateFormat: SimpleDateFormatValueConverter) {
    super(CreditMemoView.name);
    this.creditMemo = null;
  }

  async activate(params, routeData) {
    try {
      let creditMemoId = params["id"];
      if (!creditMemoId) {
        throw "No Credit Memo Id Provided.";
      }
      creditMemoId = parseInt(creditMemoId, 10);
      let requestResponses = await Promise.all([
        this.billingService.getCreditMemo(creditMemoId)
      ]);
      this.creditMemo = requestResponses[0];
    } catch (err) {
      this.errorMessageUtil.showError(err);
      this.router.navigateToRoute(ROUTES.DASHBOARD.name, {}, { trigger: true, replace: true });
    }
    await super.activate(params, routeData);
  }

  async attached() {
    await super.attached();
  } 
}