import { StandardView } from "shared-ui/views/standard-view";
import { AlertNotification, AlertNotificationSelection } from "notifications/models/alert-notification";
import { bindable } from "aurelia-framework";

export class AlertNotificationsReadView extends StandardView{
    @bindable
    readAlertNotifications: AlertNotificationSelection[];

    constructor() {
        super(AlertNotificationsReadView.name);
        this.readAlertNotifications = [];
    }

    async attached() {
        await super.attached();
    }

    async activate(params, routeData) {
        await super.activate(params, routeData);
    }
}