import { IRecordReference, RecordReference } from "shared-models/record-reference";

export interface IInvoice extends IRecordReference{
    date?: Date;
    dueDate?: Date;
    amountTotal?: number;
    amountDue?: number;
    reference?: string;
    paymentState?: string;
    term?: IRecordReference;
    inboundFileName?: string;
    inboundFile?: string;
    outboundFileName?: string;
    outboundFile?: string;
    storageFileName?: string;
    storageFile?: string;
    addressCorrFileName?: string;
    addressCorrFile?: string;
    returnsFileName?: string;
    returnsFile?: string;
    items?: IInvoiceItem[];
};
export class Invoice extends RecordReference{   
    date: Date;
    dueDate: Date;
    amountTotal: number;
    amountDue: number;
    reference: string;
    paymentState: string;
    term: RecordReference;
    inboundFileName: string;
    inboundFile: string;
    outboundFileName: string;
    outboundFile: string;
    storageFileName: string;
    storageFile: string;
    addressCorrFileName: string;
    addressCorrFile: string;
    returnsFileName: string;
    returnsFile: string;
    items: InvoiceItem[];

    constructor(data: IInvoice){
        data = data || {}
        super(data);     
        this.date = data.date ? new Date(data.date) : null;
        this.dueDate = data.dueDate ? new Date(data.dueDate) : null;
        this.amountTotal = data.amountTotal || 0;
        this.amountDue = data.amountDue || 0;
        this.reference = data.reference || "";
        this.paymentState = data.paymentState || "";
        this.term = data.term ? new RecordReference(data.term): null;
        this.inboundFileName = data.inboundFileName || "";
        this.inboundFile = data.inboundFile || "";
        this.outboundFileName = data.outboundFileName || "";
        this.outboundFile = data.outboundFile || "";
        this.storageFileName = data.storageFileName || "";
        this.storageFile = data.storageFile || "";
        this.addressCorrFileName = data.addressCorrFileName || "";
        this.addressCorrFile = data.addressCorrFile || "";
        this.returnsFileName = data.returnsFileName || "";
        this.returnsFile = data.returnsFile || "";
        this.items = (data.items || []).map((item) => new InvoiceItem(item));
    }
}

export interface IInvoiceItem extends IRecordReference{itemName?: string, itemId?: string, quantity?: number, totalCharges?: number;};
export class InvoiceItem extends RecordReference{
    itemName: string;
    itemId: string;
    quantity: number; 
    totalCharges: number;   

    constructor(data: IInvoiceItem){
        data = data || {};
        super(data);
        this.itemName = data.itemName;
        this.itemId = data.itemId;
        this.quantity = data.quantity || 0;
        this.totalCharges = data.totalCharges || 0;
    }
}