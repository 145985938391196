import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, observable } from "aurelia-framework";
import { ROUTES } from "routes";
import { SearchResult } from "shared-models/search-result";
import parse from "papaparse";
import { ImportOrderBatch } from "orders/models/import-order-batch";
import { ImportOrderService } from "orders/services/import-orders-service";

@autoinject
export class OrderBatchListView extends StandardView {

    filteredRecords: ImportOrderBatch[];

    currentSearchResult: SearchResult;

    currentPage: number = 1;

    listDisabled: boolean = false;

    $downloadCSVLink: HTMLElement;

    $exportLink: HTMLElement;

    @observable
    searchInput: string = "";

    constructor(private importOrderService: ImportOrderService) {
        super(OrderBatchListView.name);
    }

    async activate(params, routeData) {
        try {
            await this._getResults();           
        } catch (err) {
            this.errorMessageUtil.showError(err);
            this.routeToView(ROUTES.DASHBOARD.name);
        }
        await super.activate(params, routeData);
    }

    async attached() {
        await super.attached();
    }

    async _getResults() {
        this.currentSearchResult = await this.importOrderService.searchBatchRecords(this.currentPage, this.searchInput);
        this.filteredRecords = this.currentSearchResult.records as ImportOrderBatch[];
    }

    async searchInputChanged(newValue: string, oldValue: string) {
        this.disableList();
        this.currentPage = 1;
        await this._getResults();
        this.enableList();
    }

    async clickPrevious() {
        this.disableList();
        if (this.currentPage > 1) {
            this.currentPage--;
        }
        await this._getResults();
        this.enableList();
    }

    async clickNext() {
        this.disableList();
        if (this.currentPage < this.currentSearchResult.pageCount) {
            this.currentPage++;
        }
        await this._getResults();
        this.enableList();
    }

    disableList() {
        this.listDisabled = true;
    }

    enableList() {
        this.listDisabled = false;
    }

    async export() {
        this.disableView();
        let records = [];
        if (this.searchInput) {
            records = this.filteredRecords
        } else {
            records = await this.importOrderService.getBatchRecords();
        }
        
        let recordsCSV = this.convertToCsv(records);
        $(this.$exportLink).attr('href', `data:text/csv;charset=utf-8, ${escape(recordsCSV)}`);       
        this.$exportLink.click();
        this.enableView();
    }

    convertToCsv(records: ImportOrderBatch[]) {
        return parse.unparse(records.map((record) => {
            return {
                "Date": record.createDate,
                "Status": record.status,
                "Integration Type": record.integrationType,
                "Integration Partner": record.integrationPartner,
                "File Name": record.csvFileName,
                "Number Of Orders": record.numberOfOrders,
                "Orders Pending": record.numberOfOrdersPending,
                "Orders Cancelled": record.numberOfOrdersCancelled,
                "Orders Uploaded": record.numberOfOrdersUploaded,
                "Errors": record.errorMessage
            };
        }));
    }
    
    viewRecord(record: ImportOrderBatch){
        this.routeToView(ROUTES.VIEW_ORDER_BATCH.name, {id: record.id});
    }
}