import moment from 'moment';

export class SimpleDateTimeFormatValueConverter {
  toView(value: Date, format?: string): string{
    if (!format) format ='M/D/YYYY, h:mm a';
    if(!value){
      return '';
    }    
    return moment(value).format(format);
  }

  fromView(value: string, format): Date {
    if (!format) format ='M/D/YYYY, h:mm a';
    if(!value){
      return null;
    }
    return moment(value, format).toDate();
  }
  
}
