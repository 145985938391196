import { StandardView } from "shared-ui/views/standard-view";
import { bindable, autoinject, computedFrom } from "aurelia-framework";
import { ROUTES } from "routes";
import { SimpleDateFormatValueConverter } from "shared-ui/converters/simple-date-format";
import { WineryTransfer } from "items/models/winery-transfer";
import { WineryTransferService } from "items/services/winery-transfer-service";

@autoinject
export class WineryTransferView extends StandardView {
  @bindable
  wineryTransfer: WineryTransfer;

  $viewForm: HTMLElement;

  constructor(private wineryTransferService: WineryTransferService, private simpleDateFormat: SimpleDateFormatValueConverter) {
    super(WineryTransferView.name);
    this.wineryTransfer = null;
  }

  async activate(params, routeData) {
    try {
      let wineryTransferId = params["id"];
      if (!wineryTransferId) {
        throw "No Winery Transfer Id Provided.";
      }
      wineryTransferId = parseInt(wineryTransferId, 10)
      let requestResponses = await Promise.all([
        this.wineryTransferService.getRecord(wineryTransferId)
      ]);
      this.wineryTransfer = requestResponses[0];
    } catch (err) {
      this.errorMessageUtil.showError(err);
      this.router.navigateToRoute(ROUTES.DASHBOARD.name, {}, { trigger: true, replace: true });
    }
    await super.activate(params, routeData);
  }

  async attached() {
    await super.attached();
  }

  @computedFrom()
  get viewItemRoute(){
      return ROUTES.VIEW_ITEM.name;
  }

}