import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, bindable, computedFrom } from "aurelia-framework";
import { ItemReference } from "items/models/item-record";
import { ItemService } from "items/services/item_service";
import { RateCalculatorRequestItem } from "billing/models/rate-calculator";

@autoinject
export class RateCalculatorItemView extends StandardView {

    formDisabled: boolean = false;

    @bindable
    requestItem: RateCalculatorRequestItem;

    @bindable
    allItems: ItemReference[] = [];

    $itemDropdown: HTMLElement;

    constructor(
        private itemService: ItemService) {
        super(RateCalculatorItemView.name);
    }

    async activate(params, routeData) {
        alert(`Activate :: ${JSON.stringify(params)}`);
        await super.activate(params, routeData);
    }

    async attached() {
        await this._setupFields();
        await super.attached();
    }

    disableForm() {
        this.formDisabled = true;
    }

    enableForm() {
        this.formDisabled = false;
    }
    
    async _setupFields() {
        await Promise.all([
            $(this.$itemDropdown).dropdown({
                onChange: async (value, text, $selectedItem) => {
                    if (value) {
                        this.requestItem.itemReference = this.allItems ? this.allItems.find((item) => item.id == value) : null;
                    } else {
                        this.requestItem.itemReference = null;
                    }
                }
            })]);
    }

}