import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, observable } from "aurelia-framework";
import { ROUTES } from "routes";
import parse from "papaparse";
import { targetContext, computedFrom } from "aurelia-binding";
import { ImportOrderService } from "orders/services/import-orders-service";
import { ImportOrderSetup, ImportOrderColumn } from "orders/models/import-order-setup";
import { ImportOrderBatch } from "orders/models/import-order-batch";
import { IntegratedShipMethod } from "orders/models/integrated-ship-method";
import { ItemService } from "items/services/item_service";
import { ItemReference } from "items/models/item-record";

class OrderError{
    dataRow: number;
    columnName: string;
    message: string;
    constructor(dataRow: number, columnName: string, message: string){
        this.dataRow = dataRow;
        this.columnName = columnName;
        this.message = message;
    }
}

class MatchedColumn {
    columnName: string;
    matchedColumn: ImportOrderColumn;
    integratedShipMethods: IntegratedShipMethod[];
    items: ItemReference[];
    data: any[];
    constructor(columnName:string, matchedColumn: ImportOrderColumn, data: any[], integratedShipMethods: IntegratedShipMethod[], items: ItemReference[]){
        this.columnName = columnName;
        this.matchedColumn = matchedColumn ? new ImportOrderColumn(matchedColumn) : null;
        this.integratedShipMethods = (integratedShipMethods || []).map((shipMethod)=> new IntegratedShipMethod(shipMethod));
        this.items = (items || []).map((item)=> new ItemReference(item));
        this.data = data || [];
    }

    dataAtRow(orderRow: number): string{
        if(!this.data || orderRow < 1 || this.data.length < orderRow){
            return "";
        }
        return this.data[orderRow - 1];
    }

    @computedFrom('data', 'matchedColumn')
    get allErrors(): OrderError[]{
        let errors = [];       
        for(var i = 1; i <= this.data.length; i++){
            if(!this.matchedColumn){            
                errors.push(new OrderError(i, this.columnName, `Could not find matching Column`));
            } else {
                let dataRow = this.data[i - 1];
                if(!this.matchedColumn.isDataMatched(dataRow)){
                    errors.push(new OrderError(i, this.columnName, `Data is not valid`));
                }
                if(this.matchedColumn.isMandatory && !dataRow){
                    errors.push(new OrderError(i, this.columnName, `Column is Mandatory`));
                }
                if(this.matchedColumn.isItemSku && dataRow){
                    let foundItems = this.items.filter((item)=>item.name.trim() == dataRow.trim() || item.winerySku.trim() == dataRow.trim());
                    if(foundItems.length === 0){
                        errors.push(new OrderError(i, this.columnName, `Item Sku was not found`));
                    }
                }
                if(this.matchedColumn.isShipMethod && dataRow){
                    let foundShipMethod = this.integratedShipMethods.filter((intShipMethod)=>intShipMethod.name.trim() == dataRow.trim() || intShipMethod.shippingMethod.name.trim() == dataRow.trim());
                    if(foundShipMethod.length === 0){
                        errors.push(new OrderError(i, this.columnName, `Shipping Method was not found`));
                    }
                }
            }
        }
        return errors;
    }

    errorsAtRow(orderRow: number): OrderError[]{
        return this.allErrors.filter((orderError) => orderError.dataRow === orderRow)
    }
}

@autoinject
export class ImportOrdersView extends StandardView {

    $hiddenFileInput: HTMLElement;

    $setupDropdown: HTMLElement;

    $sampleTemplateLink: HTMLElement;

    $shippingMethodsLink: HTMLElement;

    $itemsLink: HTMLElement;

    @observable
    csvFile: File;

    @observable
    csvFileData: string;

    csvFileObject: any[];

    creatingOrders: boolean = false;

    setupRecords: ImportOrderSetup[];

    integratedShipMethods: IntegratedShipMethod[];

    items: ItemReference[];

    setupRecord: ImportOrderSetup;

    currentOrderRow: number = 1;

    currentOrderErrorRow: number = 0;

    constructor(private importOrdersService: ImportOrderService, private itemService: ItemService) {
        super(ImportOrdersView.name);
    }

    async activate(params, routeData) {
        try { 
            let promiseRecords = await Promise.all([
                this.importOrdersService.getSetupRecords(),
                this.itemService.getAllRecords()
            ]);        
            this.setupRecords = promiseRecords[0];
            this.items = promiseRecords[1];

            if(this.setupRecords.length === 0 || !this.setupRecords[0].integrationType){
                this.errorMessageUtil.showErrorMessage("There was an Issue getting the Setup Records. If the issue persists, please contact VinFillment.");
                this.routeToView(ROUTES.DASHBOARD.name, {});
            }
            this.integratedShipMethods = await this.importOrdersService.getAllIntegratedShipMethods([['integration_type', '=', this.setupRecords[0].integrationType.id]]);
            this.items.sort((a: ItemReference, b:ItemReference)=>{return b.onHand - a.onHand});        
          } catch (err) {
            this.errorMessageUtil.showError(err);
            this.routeToView(ROUTES.DASHBOARD.name, {});
          }
        await super.activate(params, routeData);
    }

    async attached() {
        await this._setupFields();
        await super.attached();
    }

    async _setupFields() {
        this._setShipMethodCSV();
        this._setItemCSV();
        $(this.$setupDropdown).dropdown(
            {
              onChange: async (value, text, $selectedItem) => {
                if(value){
                    this.setupRecord = this.setupRecords.find((setupRecord) => setupRecord.id == value);
                    $(this.$sampleTemplateLink).attr('href', `data:text/csv;charset=utf-8,${escape(this.setupRecord.csvFileData)}`);    
                } else{
                    this.setupRecord = null;
                }     
              }
            });
        let defaultSetupRecord = this.setupRecords.length === 1 ? this.setupRecords[0] : this.setupRecords.find((setup)=>!setup.isDefault);
        await $(this.$setupDropdown).dropdown('set selected', defaultSetupRecord.id);
    }

    _setShipMethodCSV(){
        let csvObj = []
        this.integratedShipMethods.forEach((shipMethod)=>csvObj.push({
            "Ship Method" : shipMethod.shippingMethod.name,
            "Code" : shipMethod.name,
        }));
        if(csvObj.length === 0){
            csvObj.push({
                "Ship Method" : "",
                "Code" : "",
            });
        }
        $(this.$shippingMethodsLink).attr('href', `data:text/csv;charset=utf-8,${escape(parse.unparse(csvObj))}`);
    }

    _setItemCSV(){
        let csvObj = []
        this.items.forEach((item)=>csvObj.push({
            "Name" : item.name,
            "Sku" : item.winerySku,
            "On Hand": item.onHand,
            "Available": item.available
        }));
        if(csvObj.length === 0){
            csvObj.push({
                "Name" : "",
                "Sku" : "",
                "On Hand": "",
                "Available": ""
            });
        }
        $(this.$itemsLink).attr('href', `data:text/csv;charset=utf-8,${escape(parse.unparse(csvObj))}`);
    }

    @computedFrom('csvFile')
    get fileName(): string {
        if(this.csvFile){
            return this.csvFile.name;
        }
        return "";
    }

    @computedFrom('csvFile')
    get fileSize(): string {
        if(this.csvFile){
            return Math.ceil(this.csvFile.size/1000) + " KB";
        }
        return "0 KB";
    }

    @computedFrom('csvFileObject')
    get numberOfOrders(): number {
        if(this.csvFileObject){            
            return this.csvFileObject.length;
        }
        return 0;
    }

    @computedFrom('errors')
    get numberOfErrors(): number {       
        return this.errors.length;
    }

    @computedFrom('orderErrors.length')
    get numberOfOrderErrors(): number { 
        let uniqueOrderLines = [];
        this.orderErrors.forEach((orderError) => {
            if(uniqueOrderLines.indexOf(orderError.dataRow) === -1){
                uniqueOrderLines.push(orderError.dataRow);
            }
        })
        return uniqueOrderLines.length;
    }

    @computedFrom('columns.allErrors')
    get orderErrors(): OrderError[] {
        let orderErrors = [];
        for(var i = 0; i < this.columns.length; i++){            
            this.columns[i].allErrors.forEach((orderError)=>{
                orderErrors.push(orderError);
            });
        }
        return orderErrors;
    }

    @computedFrom('orderErrors.length', 'numberOfOrders', 'csvFileObject')
    get errors(): string[] {        
        if(this.numberOfOrders === 0 && this.csvFileObject){
            return ["There are no orders on the spreadsheet."];
        }   
        let uniqueMessages = [];    
        this.orderErrors.forEach((orderError)=> {
            let errorMessage = `${orderError.message} for Column ${orderError.columnName}`;
            if(uniqueMessages.indexOf(errorMessage) === -1){
                uniqueMessages.push(errorMessage);
            }
        });
        return uniqueMessages;
    }

    @computedFrom('numberOfErrors', 'numberOfOrders')
    get showCreateButton(){
        return this.numberOfErrors === 0 && this.numberOfOrders > 0;
    }

    @computedFrom('csvFileObject')
    get columns(): MatchedColumn[] {
        if(!this.csvFileObject || this.csvFileObject.length === 0){
            return [];
        }
        return Object.keys(this.csvFileObject[0]).map((key) => {
            let matchedColumn = this.setupRecord.columns.find((column)=> column.isColumnMatched(key));
            return new MatchedColumn(key, matchedColumn, this.csvFileObject.map((orderLine) => orderLine[key]), this.integratedShipMethods, this.items);
        });        
    }

    async createOrders(){
        this.disableView();
        let batch = new ImportOrderBatch({
            csvFileData: this.csvFileData,
            csvFileName: this.fileName,
            importOrdersSetup: this.setupRecord,
            integrationType: this.setupRecord.integrationType
        });
        let batchId = await this.importOrdersService.submitImportOrderBatch(batch);
        this.router.navigateToRoute(ROUTES.VIEW_ORDER_BATCH.name, {id: batchId}, { trigger: true, replace: true });
        this.enableView();
    }

    async csvFileChanged(newValue: File, oldValue: File){
        this.csvFileData = await this.getFileCSVData(newValue);
    }

    async csvFileDataChanged(newValue: string, oldValue: string){
        if(this.csvFileData){
            let parseResponse = parse.parse(this.csvFileData, {
                header: true,
                trimHeaders: true,
                skipEmptyLines: true
            });
            if(parseResponse.errors.length > 0){
                this.errorMessageUtil.showErrorMessage(parseResponse.errors.map((parseError) => parseError.message).join("\r\n"));
                this.csvFile = null;
            } else{
                this.csvFileObject = parseResponse.data;
            }            
        } else{
            this.csvFileObject = null;
        }
    }

    async getFileCSVData(newValue): Promise<string> {
        if(!newValue){
            return "";
        }
        return await new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsText(newValue);

            reader.onload = function () {
                let result = reader.result as string;                
                resolve(result);
            };

            reader.onerror = function () {
                reject(reader.error);
            };
        });
    }

    async chooseFile() {
        this.disableView();
        $(this.$hiddenFileInput).click();
        this.enableView();
    }

    async fileChosen(self) {
        let fileList = event.target["files"] as FileList;
        if (fileList && fileList.length > 0) {
            this.csvFile = fileList[0] as File;
            this.currentOrderRow = 1;
        }

    }

    async clickPrevious() {
        if (this.currentOrderRow > 1) {
            this.currentOrderRow--;
            this.currentOrderErrorRow = 0;
        }
    }

    async clickNext() {
        if (this.currentOrderRow < this.numberOfOrders) {
            this.currentOrderRow++;
            this.currentOrderErrorRow = 0;
        }
    }

    async clickPreviousError() {
        if (this.currentOrderErrorRow > 0) {            
            this.currentOrderErrorRow--;
            if(this.currentOrderErrorRow > 0){
                let orderError = this.orderErrors[this.currentOrderErrorRow-1];
                this.currentOrderRow = orderError.dataRow;
            } else{
                this.currentOrderRow = 1;
            }            
        }
    }

    async clickNextError() {
        if (this.currentOrderErrorRow < this.numberOfOrderErrors) {
            this.currentOrderErrorRow++;
            let orderError = this.orderErrors[this.currentOrderErrorRow-1];
            this.currentOrderRow = orderError.dataRow;
        }
    }
}