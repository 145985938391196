import { autoinject, computedFrom } from "aurelia-framework";
import { ScreenViewUtility } from "shared-ui/utility/screen-view-utility";

@autoinject
export class DisableView{
    constructor(private screenViewUtil: ScreenViewUtility){

    }

    @computedFrom('screenViewUtil.disableView')
    get isDisabled(): boolean{
        return this.screenViewUtil.disableView;
    }
}