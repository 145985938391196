export class CurrencyFormatValueConverter {
  toView(value: number): string {
    if (!value) {
      return "$0.00";
    }
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    return "$" + value.toFixed(2);
  }
}