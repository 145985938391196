import { autoinject } from "aurelia-framework";
import { DefaultService } from "shared-service/default-service";
import { GetRecordsRequest } from "shared-models/get-records-request";
import { GetRecordsService } from "shared-service/get-records-service";
import { ImportOrderSetup, ImportOrderColumn } from "orders/models/import-order-setup";
import { ImportOrderBatch } from "orders/models/import-order-batch";
import { CreateRecordRequest } from "shared-models/create-record-request";
import { CreateRecordService } from "shared-service/create-record-service";
import { SearchRequest } from "shared-models/search-request";
import { SearchResult } from "shared-models/search-result";
import { SearchService } from "shared-service/search-service";
import { GetRecordService } from "shared-service/get-record-service";
import { GetRecordRequest } from "shared-models/get-record-request";
import { IntegratedShipMethod } from "orders/models/integrated-ship-method";

@autoinject
export class ImportOrderService extends DefaultService{
    private static readonly SETUP_RECORD_TYPE = "vf_import_order_setup";
    private static readonly BATCH_RECORD_TYPE = "vf_winery_orders_batch";
    private static readonly INT_SHIP_METHOD_RECORD_TYPE = "vf_int_ship_method";

    constructor(
        private searchService: SearchService, 
        private getRecordService: GetRecordService, 
        private getRecordsService: GetRecordsService, 
        private createRecordService: CreateRecordService){
        super(ImportOrderService.name);
    }

    async getSetupRecords(): Promise<ImportOrderSetup[]>{
        let getRecordsRequest = new GetRecordsRequest({
            recordType: ImportOrderService.SETUP_RECORD_TYPE
        });
        let getRecordsResponse = await this.getRecordsService.getRecords(getRecordsRequest, this._convertResponseToImportOrderSetup, this);   
        return getRecordsResponse.records;
    }

    async searchIntegratedShipMethods(searchRequestData: {page?: number, pageSize?: number, filter?: string, fieldFilters?: any[]}): Promise<SearchResult>{
        let searchRequest = new SearchRequest({
            recordType: ImportOrderService.INT_SHIP_METHOD_RECORD_TYPE,
            pageSize: searchRequestData.pageSize || 50,
            page: searchRequestData.page || 1,
            filter: searchRequestData.filter || "",
            fieldFilters: searchRequestData.fieldFilters || []
        });
        return await this.searchService.searchRecords(searchRequest, this._convertResponseToIntegratedShipMethod)
    }

    async getAllIntegratedShipMethods(fieldFilters: any[]=[]): Promise<IntegratedShipMethod[]>{
        let allRecords = [] as IntegratedShipMethod[];
        let page = 1;
        let pageSize = 5000;
        let initialSearch = await this.searchIntegratedShipMethods({page: page, pageSize: pageSize, fieldFilters: fieldFilters});
        allRecords = allRecords.concat(initialSearch.records as IntegratedShipMethod[]);
        var totalPageCount = initialSearch.pageCount;
        if(totalPageCount > 1){
            while(page < totalPageCount){
                page++;
                let nextSearch = await this.searchIntegratedShipMethods({page: page, pageSize: pageSize, fieldFilters: fieldFilters});
                allRecords = allRecords.concat(nextSearch.records as IntegratedShipMethod[]);
            }
        }        
        return allRecords;
    }

    async getBatchRecords(): Promise<ImportOrderBatch[]>{
        let getRecordsRequest = new GetRecordsRequest({
            recordType: ImportOrderService.BATCH_RECORD_TYPE
        });
        let getRecordsResponse = await this.getRecordsService.getRecords(getRecordsRequest, this._convertResponseToBatchOrders, this);   
        return getRecordsResponse.records;
    }

    async getBatchRecord(orderBatchId: number): Promise<ImportOrderBatch>{
        let getRecordRequest = new GetRecordRequest({
            recordType: ImportOrderService.BATCH_RECORD_TYPE,
            recordId: orderBatchId
        });
        let getRecordResponse = await this.getRecordService.getRecord(getRecordRequest, this._convertResponseToBatchOrders, this);
        return getRecordResponse.record;
    }

    async searchBatchRecords(page: number, filter: string): Promise<SearchResult>{
        page = page || 1;
        filter = filter || "";
        let searchRequest = new SearchRequest({
            recordType: ImportOrderService.BATCH_RECORD_TYPE,
            pageSize: 50,
            page: page,
            filter: filter
        });
        let searchResponse =  await this.searchService.searchRecords(searchRequest, this._convertResponseToBatchOrders, this);
        // searchResponse.records = searchResponse.records.sort((address1, address2)=> (address1.name || "").toLowerCase().localeCompare((address2.name || "").toLowerCase()));
        return searchResponse;
    }

    async submitImportOrderBatch(importOrderBatch: ImportOrderBatch): Promise<number>{
        let createBatchRequest = new CreateRecordRequest({
            recordType: ImportOrderService.BATCH_RECORD_TYPE,
            recordData: {
                "original_file_data": importOrderBatch.csvFileData,
                "original_file_data_name": importOrderBatch.csvFileName,
                "import_orders_setup": importOrderBatch.importOrdersSetup.id,
                "integration_type": importOrderBatch.integrationType.id
            }
        });
        return await this.createRecordService.createRecord(createBatchRequest);
    }

    _convertResponseToImportOrderSetup(response: any): ImportOrderSetup{
        return new ImportOrderSetup({
            id: response["id"],
            name: response["name"],
            isDefault: response["is_default"],
            csvFileData: atob(response["csv_file_data"] || "").trim(),
            integrationType: response["integration_type"],
            columns: (response["columns"] || []).map((column) => new ImportOrderColumn({
                id: column["id"],
                name: column["name"],
                type: column["column_type"],
                isMandatory: column["is_mandatory"],
                columnNameRegExpString: column["column_name_reg_exp"],
                dataRegExpString: column["data_reg_exp"]
            }))
        });
    }

    _convertResponseToIntegratedShipMethod(response: any): IntegratedShipMethod{
        return new IntegratedShipMethod({
            id: response["id"],
            name: response["name"],
            isDefault: response["is_default"],
            winery: response["winery"],
            shippingMethod: response["ship_method"],
            integrationType: response["integration_type"],
            
        });
    }

    _convertResponseToBatchOrders(response: any): ImportOrderBatch{
        return new ImportOrderBatch({
            id: response["id"],
            name: response["shipment_detail_number"],
            createDate: this._convertValueToDateTime(response["create_date"]),
            status: response["batch_status"],
            integrationType: response["integration_type"],
            integrationPartner: response["integration_partner"],
            importOrdersSetup: response["import_orders_setup"],
            shipmentDetailNumber: response["shipment_detail_number"],
            csvFileData: atob(response["original_file_data"] || ""),
            csvFileName: response["original_file_data_name"],
            numberOfOrders: response["number_of_orders"],
            numberOfOrdersPending: response["number_of_orders_pending"],
            numberOfOrdersUploaded: response["number_of_orders_uploaded"],
            numberOfOrdersCancelled: response["number_of_orders_cancelled"],
            errorMessage: response["error_message"]
        });
    }

    
}