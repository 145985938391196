import { StandardView } from 'shared-ui/views/standard-view';
import { autoinject } from 'aurelia-framework';
import { ScreenViewUtility } from 'shared-ui/utility/screen-view-utility';
import { UserService } from 'user/service/user-service';
import { UserSession } from 'user/models/user-session';
import { ErrorMessageUtility } from 'shared-ui/error-message/error-message-utility';
import { Winery } from 'user/models/user';

@autoinject
export class App extends StandardView {
  showSideBar: boolean;

  constructor(private userService: UserService) {    
    super(App.name);
  }

  async activate(params, routeData) {
    super.activate(params, routeData);
  }

  async attached() {
    this.disableView()
    window.addEventListener("unhandledrejection", (e) => {
      this.logger.info("unhandledrejection");
      this.errorMessageUtil.showErrorMessage(e["detail"]["reason"]);
      this.enableView();
    });
    window.addEventListener("rejectionhandled", (e) => {
      this.logger.info("rejectionhandled");
      this.errorMessageUtil.showErrorMessage(e["detail"]["reason"]);
      this.enableView();
    });
    this.userSession.user = await this.userService.getCurrentUser();  
    if(this.userSession.user){
      this.logger.info(`this.userSession.currentWinery :: ${this.userSession.currentWinery}`);
      if(this.userSession.currentWinery) {
        let newWineryIds = this.userSession.user.availableWineries.map((winery)=>winery.id);
        this.logger.info(`newWineryIds :: ${newWineryIds}`);
        if((this.userSession.currentWinery.id != this.userSession.user.defaultWinery.id) || (newWineryIds.indexOf(this.userSession.currentWinery.id ) === 0)){
          this.userSession.currentWinery = this.userSession.user.defaultWinery;
        }
      } else {
        this.userSession.currentWinery = this.userSession.user.defaultWinery;
      }    
    } else{
      if(!this.userSession.user){
        this.errorMessageUtil.showErrorMessage("User not found. Please contact Vinfillment");
      }
    }
    this.showSideBar = false;
    super.attached();
    this.enableView();
  }
  
}
