import { StandardView } from "shared-ui/views/standard-view";
import { bindable, autoinject } from "aurelia-framework";
import { LOGIN_FORM_TYPE } from "../login-view";
import { LoginService } from "login/services/login-service";
import { computedFrom } from "aurelia-binding";
import { LoginPassport } from "login/models/login-passport";
import { CognitoUser } from "@aws-amplify/auth";
import { ScreenViewUtility } from "shared-ui/utility/screen-view-utility";

@autoinject
export class NewPasswordView extends StandardView {
    @bindable
    formType: LOGIN_FORM_TYPE;
    @bindable
    email: string;
    @bindable
    user: CognitoUser | any;    
    $form: any;
    code: string;
    oldPassword: string;
    newPassword: string;

    constructor(private loginService: LoginService, private screenViewUtil: ScreenViewUtility) {
        super(NewPasswordView.name);
        this.enableView();
    }

    async attached() {
        await super.attached();
        $(this.$form).form({
            on: 'blur',
            onSuccess: (event, fields) => {
                event.preventDefault(); //Keep from submitting the page
                this.resetPassword();
            },
            fields: {
                email: {
                    identifier: 'email',
                    rules: [
                        {
                            type: 'email',
                            prompt: 'Please enter a valid e-mail'
                        }
                    ]
                },
                oldPassword: {
                    identifier: 'oldPassword',
                    rules: [
                        {
                            type: 'empty',
                            prompt: 'Code cannot be blank'
                        }
                    ]
                },
                newPassword: {
                    identifier: 'newPassword',
                    rules: [
                        {
                            type: 'empty',
                            prompt: 'Password cannot be blank'
                        }
                    ]
                }
            }
        });
    }

    clickGoBack() {
        this.formType = LOGIN_FORM_TYPE.FORGOT_PASSWORD_SEND_CODE;
    }

    @computedFrom('email', 'password')
    get loginPassport(): LoginPassport {
        this.oldPassword = (this.oldPassword || "").trim();
        return new LoginPassport({ email: this.email, password: this.oldPassword });
    }

    async resetPassword() {
        let loggedIn = false;
        this.disableView();
        try {
            let loginResponse = await this.loginService.setNewPassword(this.loginPassport, this.newPassword, this.user);
            if (loginResponse.isSuccessful) {
                loggedIn = true;
                location.reload();
            } else {
                $(this.$form).form('add errors', [loginResponse.message]);
            }
        } catch (err) {
            $(this.$form).form('add errors', [err.message]);
        }
        if(!loggedIn){
            this.enableView();
        }       
    }
}