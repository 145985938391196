export interface IGetRecordsRequest {recordType: string, fieldReference?: string,fieldReferenceValue?: any, additionalFields?: any};
export class GetRecordsRequest{
    recordType: string;
    fieldReference?: string;  
    fieldReferenceValue?: any;
    additionalFields: any;  

    constructor(data: IGetRecordsRequest){
        this.recordType = data.recordType; 
        this.fieldReference = data.fieldReference;
        this.fieldReferenceValue = data.fieldReferenceValue;       
        this.additionalFields = data.additionalFields;       
    }
}