import { autoinject } from "aurelia-framework";
import { DefaultService } from "shared-service/default-service";
import { GetRecordRequest } from "shared-models/get-record-request";
import { GetRecordResponse } from "shared-models/get-record-response";

@autoinject
export class GetRecordService extends DefaultService{
    private static readonly URI = "get-record";

    constructor(){
        super(GetRecordService.name);
    }

    async getRecord(getRecordRequest: GetRecordRequest, recordConverter: (record: any) => any, self:any=this): Promise<GetRecordResponse>{
        let getRecordResult = await this.post(GetRecordService.URI, getRecordRequest);
        
        let record = recordConverter.call(self, getRecordResult["record"]);
        return new GetRecordResponse({
            recordType: getRecordRequest.recordType,
            recordId: getRecordRequest.recordId,
            record: record
        });
    }
}