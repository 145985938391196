import { StandardView } from "shared-ui/views/standard-view";
import { bindable, autoinject, observable, computedFrom } from "aurelia-framework";
import { ROUTES } from "routes";
import parse from 'papaparse';
import { SearchResult } from "shared-models/search-result";
import { ReturnReference } from "returns/models/return-record";
import { ReturnService } from "returns/services/return-service";

export enum VIEW_TYPES {
    ALL = "all",
    PENDING = "pending"
}

@autoinject
export class ListReturnsView extends StandardView {
    
    filteredRecords: ReturnReference[] = [];

    listDisabled: boolean = false;

    @observable
    searchInput: string;

    currentSearchResult: SearchResult;
    
    currentPage: number = 1;

    $exportLink: HTMLElement;

    listType: string;

    constructor(private returnService: ReturnService) {
        super(ListReturnsView.name);
    }

    async activate(params, routeData) {
        try {          
            this.listType = params["listType"];
            await this._getRecords();
        } catch (err) {
            this.errorMessageUtil.showError(err);
            this.router.navigateToRoute(ROUTES.DASHBOARD.name, {}, { trigger: true, replace: true });
        }
        await super.activate(params, routeData);
    }

    async attached() {
        await super.attached();
    }

    async _getRecords() {
        let fieldFilters = this._getFilters();        
        this.logger.info(`fieldFilters :: ${JSON.stringify(fieldFilters)}`);
        this.currentSearchResult = await this.returnService.searchRecords({page: this.currentPage, filter: this.searchInput, fieldFilters: fieldFilters});
        this.filteredRecords = this.currentSearchResult.records as ReturnReference[];
    }

    _getFilters() {
        if(this.listType === VIEW_TYPES.PENDING){
            return this._getPendingFilters();
        }
        return [];
    }

    _getPendingFilters(){
        return [['status', '=', 'pending']];
    }

    disableList() {
        this.listDisabled = true;
    }

    enableList() {
        this.listDisabled = false;
    }

    async searchInputChanged(newValue: string, oldValue: string) {
        this.disableList()
        this.currentPage = 1;
        await this._getRecords(); 
        this.enableList()       
    }

    async clickPrevious() {
        this.disableList();
        if (this.currentPage > 1) {
            this.currentPage--;
        }
        await this._getRecords();
        this.enableList();
    }

    async clickNext() {
        this.disableList();
        if (this.currentPage < this.currentSearchResult.pageCount) {
            this.currentPage++;
        }
        await this._getRecords();
        this.enableList();
    }
    
    @computedFrom()
    get viewReturnRoute(){
        return ROUTES.VIEW_RETURN.name;
    }    
    
    async exportRecords() {
        this.disableView();
        let fieldFilters = this._getFilters();
        let records = [];
        if (this.searchInput) {
            records = this.filteredRecords
        } else {
            records = await this.returnService.getAllRecords(fieldFilters);
        }
        let recordsCSV = this.convertRecordsToCsv(records);
        $(this.$exportLink).attr('href', `data:text/csv;charset=utf-8, ${escape(recordsCSV)}`);       
        this.$exportLink.click(); 
        this.enableView();
    }

    convertRecordsToCsv(records: ReturnReference[]) {
        if(records.length === 0){
            return parse.unparse([{
                "Order#" : "",
                "Recipient Name": "",
                "Ship Date": "",
                "Return Date": "",
                "Ship Method": "",
                "Return Reason": "",
                "Notes": "",
            }]);
        }
        return parse.unparse(records.map((record) => {
            return {
                "Order#" : record.orderNumber,
                "Recipient Name": record.recipientName,
                "Ship Date": record.shipDate,
                "Return Date": record.returnDate,
                "Ship Method": record.shippingMethod.name,
                "Return Reason": record.returnReason.name,
                "Notes": record.notes
            };
        }));
    }


}