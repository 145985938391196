import Amplify, { Auth } from "aws-amplify";
import { autoinject, Container } from "aurelia-framework";
import { AureliaConfiguration } from "aurelia-configuration";
import { RouterConfiguration, Router, activationStrategy } from "aurelia-router";
import { StandardView } from "shared-ui/views/standard-view";
import { ROUTES } from "routes";
import { UserSession } from "user/models/user-session";

@autoinject
export class Loader extends StandardView {
    isLoggedIn: boolean;

    constructor(private aureliaConfig: AureliaConfiguration) {
        super(Loader.name);
        this.isLoggedIn = false;
    }

    async activate(params, routeData) {
        try {
            await this.setupAWS();
            this.isLoggedIn = true
        } catch (err) {
            this.isLoggedIn = false
        }

        await super.activate(params, routeData);
    }

    async attached() {
        await super.attached();
    }

    async configureRouter(config: RouterConfiguration, router: Router) {
        config.title = 'VinFillment Winery Portal';
        config.options.pushState = true;
        for (let route in ROUTES) {
            let routeObj = ROUTES[route];
            config.mapRoute({
                route: routeObj.routes,
                name: routeObj.name,
                moduleId: routeObj.moduleId,
                nav: true,
                title: routeObj.title,
                activationStrategy: activationStrategy.invokeLifecycle
            });
        }
        function preActivateStep() {
            return async (navigationInstruction, next) => {
                let userSession = Container.instance.get(UserSession);
                if (userSession) {
                    await userSession.refreshToken();
                }
                return next();
            };
        }
        config.addPreActivateStep(preActivateStep)
        this.router = router;
    }

    async setupAWS() {
        Amplify.configure({
            Auth: {
                identityPoolId: this.aureliaConfig.get('aws.identityPoolId'),
                region: this.aureliaConfig.get('aws.region'),
                userPoolId: this.aureliaConfig.get('aws.userPoolId'),
                userPoolWebClientId: this.aureliaConfig.get('aws.userPoolWebClientId'),
            },
            API: {
                endpoints: [
                    {
                        name: "CustomerPortalBackend",
                        region: this.aureliaConfig.get('aws.region'),
                        endpoint: this.aureliaConfig.get('aws.endpoint')
                    }
                ]
            }
        });
        return new Promise((resolve, reject) => {
            Auth.currentAuthenticatedUser({
                bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
            }).then(user => resolve())
                .catch(err => reject());
        });
    }
}