import { StandardView } from "shared-ui/views/standard-view";
import { bindable, autoinject, computedFrom } from "aurelia-framework";
import { ROUTES } from "routes";
import { ItemRecord } from "items/models/item-record";
import { OrderReference, OrderItem, Order, Address } from "orders/models/order";
import { ItemService } from "items/services/item_service";
import { OrderService } from "orders/services/order_service";
import { SimpleDateFormatValueConverter } from "shared-ui/converters/simple-date-format";
import parse from "papaparse";

@autoinject
export class ItemView extends StandardView {
  @bindable
  item: ItemRecord;

  @bindable
  totalCommittedOrders: number;

  @bindable
  totalBackorderedOrders: number;

  $viewItemForm: HTMLElement;

  $exportCommittedLink: HTMLElement;
  $exportBackorderedLink: HTMLElement;

  constructor(private itemService: ItemService, private orderService: OrderService, private simpleDateFormat: SimpleDateFormatValueConverter) {
    super(ItemView.name);
    this.item = null;
  }

  async activate(params, routeData) {
    try {
      let itemId = params["id"];
      if (!itemId) {
        throw "No Item Id Provided.";
      }
      itemId = parseInt(itemId, 10)
      let requestResponses = await Promise.all([
        this.itemService.getRecord(itemId),
        this._getLineCount([['order_id.winery_order_status','in',['pending_fulfill','packed']],['product_template_id', '=', itemId], ['qty_committed', '>', 0]]),
        this._getLineCount([['order_id.winery_order_status','in',['pending_fulfill','packed']],['product_template_id', '=', itemId], ['qty_backordered', '>', 0]])
      ]);
      this.item = requestResponses[0];
      this.totalCommittedOrders = requestResponses[1];
      this.totalBackorderedOrders = requestResponses[2];
    } catch (err) {
      this.errorMessageUtil.showError(err);
      this.router.navigateToRoute(ROUTES.DASHBOARD.name, {}, { trigger: true, replace: true });
    }
    await super.activate(params, routeData);
  }

  async _getLineCount(filters = []) {
    let orderLines = await this.orderService.getAllLineRecords(filters);
    let orderIds = [];
    orderLines.forEach((orderLine) => {
      if (orderIds.indexOf(orderLine.orderId) === -1) {
        orderIds.push(orderLine.orderId);
      }
    });
    return orderIds.length;
  }

  async attached() {
    await super.attached();
  }

  @computedFrom()
  get viewOrderRoute() {
    return ROUTES.VIEW_ORDER.name;
  }

  async clickExportCommitted() {
    this.disableView();
    let fieldFilters = [['product_template_id', '=', this.item.id], ['qty_committed', '>', 0]];
    let orderLines = await this.orderService.getAllLineRecords(fieldFilters);
    let orders = await this._getOrdersByLines(orderLines);
    let orderLinesCSV = this.convertOrderLinesToCsv(orderLines, orders);
    $(this.$exportCommittedLink).attr('href', `data:text/csv;charset=utf-8, ${escape(orderLinesCSV)}`);
    this.$exportCommittedLink.click();
    this.enableView();
  }

  async clickExportBackordered() {
    this.disableView();
    let fieldFilters = [['product_template_id', '=', this.item.id], ['qty_backordered', '>', 0]];
    let orderLines = await this.orderService.getAllLineRecords(fieldFilters);
    let orders = await this._getOrdersByLines(orderLines);
    let orderLinesCSV = this.convertOrderLinesToCsv(orderLines, orders);
    $(this.$exportBackorderedLink).attr('href', `data:text/csv;charset=utf-8, ${escape(orderLinesCSV)}`);
    this.$exportBackorderedLink.click();
    this.enableView();
  }

  async _getOrdersByLines(orderLines: OrderItem[] = []): Promise<OrderReference[]> {
    let orderIds = orderLines.map((orderLine) => {
        return orderLine.orderId;
    });
    if(orderIds.length == 0){
      return [];
    }
    return await this.orderService.getAllRecords([["id", "in", orderIds]]);
  }

  convertOrderLinesToCsv(orderLines: OrderItem[], orderRefs: OrderReference[]) {
    return parse.unparse(orderLines.map((orderLine) => {
      let order = orderRefs.find((orderRef) => {
        return orderRef.id == orderLine.orderId;
      }) as OrderReference;
      let orderData = order || {} as OrderReference;
      let shippingAddress = (order.shippingAddress || {}) as Address;
      return {
        "Order #": orderData.wineryOrderNumber,
        "VinFillment #": orderLine.orderNumber,
        "Status": orderData.status,
        "Ship Via": orderData.shippingMethodName,
        "Future Ship Date": this.simpleDateFormat.toView(orderData.futureShipDate),
        "Addressee": shippingAddress.addressee,
        "Attention": shippingAddress.attention,
        "Address 1": shippingAddress.address1,
        "Address 2": shippingAddress.address2,
        "City": shippingAddress.city,
        "State": shippingAddress.stateName,
        "Zip": shippingAddress.zip,
        "Country": shippingAddress.countryName,
        "Item": orderLine.itemName,
        "Sku": orderLine.sku,
        "Quantity": orderLine.quantity,
        "Committed": orderLine.committed,
        "Backordered": orderLine.backordered
      };
    }));
  }

}