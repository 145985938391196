import { StandardView } from "shared-ui/views/standard-view";
import { autoinject, observable, computedFrom } from "aurelia-framework";
import { ROUTES } from "routes";
import { SearchResult } from "shared-models/search-result";
import parse from "papaparse";
import { WineryTransferReference } from "items/models/winery-transfer";
import { WineryTransferService } from "items/services/winery-transfer-service";
import { SimpleDateFormatValueConverter } from "shared-ui/converters/simple-date-format";

@autoinject
export class WineryTransferListView extends StandardView {

    wineryTransfers: WineryTransferReference[];

    currentSearchResult: SearchResult;

    currentPage: number = 1;

    listDisabled: boolean = false;    

    $downloadCSVLink: HTMLElement;

    $exportLink: HTMLElement;

    @observable
    searchInput: string = "";

    constructor(private wineryTransferService: WineryTransferService, private simpleDateFormat: SimpleDateFormatValueConverter) {
        super(WineryTransferListView.name);
    }

    async activate(params, routeData) {
        try {
            await this._getResults();           
        } catch (err) {
            this.errorMessageUtil.showError(err);
            this.routeToView(ROUTES.DASHBOARD.name);
        }
        await super.activate(params, routeData);
    }

    async attached() {
        await super.attached();
    }

    async _getResults() {
        this.currentSearchResult = await this.wineryTransferService.searchRecords({page: this.currentPage, filter: this.searchInput});
        this.wineryTransfers = this.currentSearchResult.records as WineryTransferReference[];
    }

    async searchInputChanged(newValue: string, oldValue: string) {
        this.disableList();
        this.currentPage = 1;
        await this._getResults();
        this.enableList();
    }

    async clickPrevious() {
        this.disableList();
        if (this.currentPage > 1) {
            this.currentPage--;
        }
        await this._getResults();
        this.enableList();
    }

    async clickNext() {
        this.disableList();
        if (this.currentPage < this.currentSearchResult.pageCount) {
            this.currentPage++;
        }
        await this._getResults();
        this.enableList();
    }

    async exportTransfers() {
        this.disableView();
        let wineryTransfers = [];
        if (this.searchInput) {
            wineryTransfers = this.wineryTransfers
        } else {
            wineryTransfers = await this.wineryTransferService.getAllRecords();
        }
        
        let csvData = this.convertToCsv(wineryTransfers);
        $(this.$exportLink).attr('href', `data:text/csv;charset=utf-8, ${escape(csvData)}`);       
        this.$exportLink.click();
        this.enableView();
    }

    convertToCsv(wineryTransfers: WineryTransferReference[]) {
        if(wineryTransfers.length === 0){
            return "Receipt Date, Reference, Transfer Location, Total Charges\n";
        }
        return parse.unparse(wineryTransfers.map((wineryTransfer) => {
            return {
                "Receipt Date": this.simpleDateFormat.toView(wineryTransfer.receiptDate),
                "Reference": wineryTransfer.reference,
                "Transfer Location": wineryTransfer.transferLocation.name,
                "Total Charges": wineryTransfer.totalCharges
            };
        }));
    }    

    @computedFrom()
    get viewTransferRoute(){
        return ROUTES.VIEW_WINERY_TRANSFER.name;
    }

    disableList() {
        this.listDisabled = true;
    }

    enableList() {
        this.listDisabled = false;
    }
}