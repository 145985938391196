import { computedFrom } from "aurelia-binding";

interface IAlertNotificationRead {dateRead: Date; contact: string};
export class AlertNotificationRead{
    dateRead: Date;
    contact: string;
    constructor(data: IAlertNotificationRead){
        this.dateRead = data.dateRead;
        this.contact = data.contact;
    }
}
interface IAlertNotfication {message?: string; title?: string, date?: Date, isRead?: boolean, contactsRead?: IAlertNotificationRead[]};
export class AlertNotification{
    title: string;
    message: string;
    date: Date;
    contactsRead: AlertNotificationRead[];
    constructor(data?: IAlertNotfication){
        data = data || {};
        this.message = data.message || "";
        this.title = data.title || "";
        this.date = data.date || new Date();
        this.contactsRead = (data.contactsRead || [])
                                .map(contactRead => new AlertNotificationRead(contactRead));
    }

    @computedFrom('contactsRead.length')
    get isRead(): boolean{
        return this.contactsRead.length > 0;
    }
}

interface IAlertNotificationSelection {alertNotification: IAlertNotfication; isChecked?: boolean};
export class AlertNotificationSelection{
    alertNotification: AlertNotification;
    isChecked: boolean;

    constructor(data: IAlertNotificationSelection){
        this.alertNotification = new AlertNotification(data.alertNotification);
        this.isChecked = data.isChecked || false;
    }

    static buildFromNotifications(alertNotification: IAlertNotfication[]): AlertNotificationSelection[]{
        return alertNotification.map(notification => new AlertNotificationSelection({
            alertNotification: notification
        }));
    }

    changeIsChecked(){
        this.isChecked = this.isChecked ? false: true;
    }

    @computedFrom("alertNotification.title")
    get title(): string{
        return this.alertNotification.title;
    }

    @computedFrom("alertNotification.message")
    get message(): string{
        return this.alertNotification.message;
    }

    @computedFrom("alertNotification.date")
    get date(): Date{
        return this.alertNotification.date;
    }

    @computedFrom("alertNotification.contactsRead.length")
    get isRead(): boolean{
        return this.alertNotification.isRead;
    }

}