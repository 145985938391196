export interface ISearchRequest {recordType: string, pageSize?: number, page?: number, filter?: string, fieldFilters?: any[]};
export class SearchRequest{
    recordType: string;
    pageSize?: number;
    page?: number;
    filter?: string;
    fieldFilters?: any[];
    constructor(data: ISearchRequest){
        this.recordType = data.recordType;
        this.pageSize = data.pageSize || 50;
        this.page = data.page || 1;        
        this.filter = data.filter || "";
        this.fieldFilters = data.fieldFilters || [];
    }
}