import moment from 'moment';

export class SimpleDateFormatValueConverter {
  toView(value: Date, format?: string): string{
    if (!format) format ='M/D/YYYY';
    if(!value){
      return '';
    }    
    return moment(value).format(format);
  }

  fromView(value: string, format?: string): Date {
    if (!format) format ='M/D/YYYY';
    if(!value){
      return null;
    }
    return moment(value, format).toDate();
  }
  
}
