import { StandardView } from "shared-ui/views/standard-view";
import { bindable, autoinject } from "aurelia-framework";
import { LOGIN_FORM_TYPE } from "../login-view";
import { LoginService } from "login/services/login-service";
import { ScreenViewUtility } from "shared-ui/utility/screen-view-utility";

@autoinject
export class EnterCodeView extends StandardView{
    @bindable
    formType: LOGIN_FORM_TYPE;
    @bindable
    email: string;   
    $form: any;
    code: string;
    password: string;

    constructor(private loginService: LoginService, private screenViewUtil: ScreenViewUtility){
        super(EnterCodeView.name);
        this.enableView();
    }

    async attached() {
        await super.attached();
        $(this.$form).form({
            on: 'blur',
            onSuccess: (event, fields) => {
                event.preventDefault(); //Keep from submitting the page
                this.resetPassword();
            },
            fields: {
                email: {
                    identifier: 'email',
                    rules: [
                        {
                            type: 'email',
                            prompt: 'Please enter a valid e-mail'
                        }
                    ]
                },
                code: {
                    identifier: 'code',
                    rules: [
                        {
                            type: 'empty',
                            prompt: 'Code cannot be blank'
                        }
                    ]
                },
                password: {
                    identifier: 'password',
                    rules: [
                        {
                            type: 'empty',
                            prompt: 'Password cannot be blank'
                        }
                    ]
                }
            }
        });
    }

    clickGoBack(){
        this.formType = LOGIN_FORM_TYPE.FORGOT_PASSWORD_SEND_CODE;
    }

    async resetPassword(){
        this.disableView();
        try{
            await this.loginService.sendNewPasswordWithCode(this.email, this.code, this.password);
            this.formType = LOGIN_FORM_TYPE.LOGIN;
        } catch(err){
            $(this.$form).form('add errors', [err.message]);
        }
        this.enableView();
    }
}