import { autoinject } from "aurelia-dependency-injection";
import { StandardView } from "shared-ui/views/standard-view";
import { UserSession } from "user/models/user-session";
import { computedFrom } from "aurelia-binding";
import { Winery } from "user/models/user";
import { ROUTES } from "routes";

@autoinject
export class TopNavBar extends StandardView {
    showInputBox: boolean;

    $wineryDropdown: HTMLElement;

    constructor() {
        super(TopNavBar.name);
    }

    async activate(params, routeData) {
        await super.activate(params, routeData);
    }

    async attached() {
        await Promise.all([
            $(this.$wineryDropdown).dropdown({
                fullTextSearch: true,
                onChange: async (value, text, $selectedItem) => {                    
                    if (text) {         
                        if(text === "Logout"){
                            await this.userSession.logout();
                        }  else{
                            this.userSession.currentWinery = this.userSession.user.availableWineries.find((winery)=>winery.name == text)
                            this.routeToView(ROUTES.DASHBOARD.name);
                        }
                    }
                }
            })]);
        await super.attached();        
        this.showInputBox = false;        
    }

    async onClickSearchIcon() {
        this.showInputBox = true;
    }

    @computedFrom('userSession.user.wineryImage')
    get wineryImage(): string{
        return this.userSession && this.userSession.user ? this.userSession.user.wineryImage : "";
    }

    @computedFrom('screenViewUtility.viewName', 'userSession.user.wineryImage')
    get showImage(): boolean{
        return !this.screenViewUtility.viewName && !!this.wineryImage;
    }

    @computedFrom('screenViewUtility.viewName', 'userSession.user.wineryImage')
    get showText(): boolean{
        return !!this.userSession.user && !this.wineryImage;
    }

    @computedFrom('screenViewUtility.viewName', 'userSession.user.wineryName')
    get navBarTitle(): string{
        if(this.screenViewUtility.viewName || !this.userSession.user){
            return this.screenViewUtility.viewName;
        }
        return `${this.userSession.user ? this.userSession.user.wineryName : ""} Winery Portal`;
    }

    @computedFrom('userSession.currentWinery')
    get currentWineryName(): string{
        if(this.userSession.currentWinery){
            return this.userSession.currentWinery.name;
        }
        return "";
    }

    @computedFrom('userSession.currentWinery', 'userSession.user')
    get availableWineries(): Winery[]{  
        if(!this.userSession.user){
            return [];
        }      
        if(!this.userSession.currentWinery){
            return this.userSession.user.availableWineries;
        }
        return this.userSession.user.availableWineries.filter((winery)=>winery.id != this.userSession.currentWinery.id);
    }
}